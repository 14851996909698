@import "../../src/Styles/variable.scss";
@import "../../src/Styles/toggle.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

$font-size-14: 0.875rem;
$font-size-13: 0.8125rem;
$font-size-12: 0.75rem;
$font-size-11: 0.6875rem;
// $font-family: 'Lato', sans-serif;
// $font-family: Helvetica Neue, sans-serif;
$font-size-18: 1.125rem;
$tbr_color: rgb(7, 64, 122);
$font-family: "Source Sans Pro", sans-serif;
$font-family-2: "Montserrat", sans-serif;
$font-size-h1: 1.424rem;
$font-size-h2: 1.266rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1rem;
$font-size-h5: 0.889rem;
$font-size-h6: 0.79rem;
/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
}

html {
  scroll-behavior: smooth;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 1px solid #ccc;
}

.gap1 {
  gap: 1rem;
}

.font-Calibri {
  overflow: hidden;
  //margin: 0 auto;
  //padding: 6px;
  * {
    color: #000000;
  }
  svg {
    //transform: translateY(-80px);
  }
}

/* .nav_sidebar ul li:hover label {
     color: $clr_lightblue;
}
 */
/*.nav_sidebar{
        width: 15%;
    }
    */
/*.nav_sidebar{
        width: 25%;
        left: -100%;
        z-index: 9;
         @include transition(all 0.4s ease-in-out);
    }
     .open_nav .nav_sidebar{
        left: 0;
    }
    */

/* Checkbox style */
/* Comapanies Css */
/* Placeholder color chane */
/* Placeholder color chane */
/*
 */
/* Dashboard Css */
/*
.quaters_list::-webkit-scrollbar {
     width: 8px;
     background:$clr_white;
     border-right:solid 1px #edeff5;
}
 .quaters_list::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px #f0f2f5;
  -moz-box-shadow: inset 0 0 6px #f0f2f5;
     -ms-box-shadow: inset 0 0 6px #f0f2f5;
     border-radius: 5px;
}
 .quaters_list::-webkit-scrollbar-thumb {
     border-radius: 15px;
     height: 50px;
     background:#f0f2f5;
}
 */
/* .dashboard_content:nth-child(4n+0):before {
     content: '';
     width: calc( 100% - 60px);
     height: 1px;
    background: red;
    position: absolute;
    top: 0;
    right: 0;
}
 */
/* Companies Details */
/*Graph*/
/*quarterly_final Box*/
/*.company_grph aside{
        flex: 0 0 100%;
         max-width:100%;
         margin-bottom: 30px;
    }
    */
/*TBR Position*/
/*Graph Animation*/
// @-webkit-keyframes "am-moving-dashes" {
// 	100% {
// 		stroke-dashoffset: -31px;
// 	}
// }
// @keyframes "am-moving-dashes" {
// 	100% {
// 		stroke-dashoffset: -31px;
// 	}
// }
// @-webkit-keyframes "am-draw" {
// 	0% {
// 		stroke-dashoffset: 500%;
// 	}
// 	100% {
// 		stroke-dashoffset: 0%;
// 	}
// }
// @keyframes "am-draw" {
// 	0% {
// 		stroke-dashoffset: 500%;
// 	}
// 	100% {
// 		stroke-dashoffset: 0%;
// 	}
// }
/*Login Page*/
/*.login_right form a img + img{
    max-width: 280px
}
*/

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  font-family: $font-family;
  background-color: $clr_bodycolor;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1600px) {
    font-size: 16px;
  }
}

.font-family-1 {
  font-family: $font-family;
}

h1 {
  font-size: $font-size-h1;
  color: $clr_grey;
  font-family: $font-family-2;
}

h2 {
  font-size: $font-size-h2;
  color: $clr_grey;
  font-family: $font-family-2;
}

h3 {
  font-size: $font-size-h3;
  color: $clr_grey;
}

h4 {
  font-size: $font-size-h4;
  color: $clr_grey;
}

h5 {
  font-size: $font-size-h5;
  color: $clr_grey;
}

.btn-primary {
  border-color: $clr_lightblue2;
  background: $clr_lightblue2;

  &:hover,
  &:active {
    background-color: $clr_darkblue5;
    border-color: $clr_darkblue5;
  }
}

.btn-outline-primary,
.btn-secondary.btn-outline-primary {
  border-color: $clr_lightblue2;
  background-color: $clr_white;
  color: $clr_lightblue2;

  &:hover,
  &:active {
    color: $clr_white;
    background-color: $clr_lightblue2;
    border-color: $clr_lightblue2;
  }
}

.dark-blue-btn {
  border-color: $clr_darkblue5;
  background: $clr_darkblue5;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  cursor: no-drop;
}

p {
  font-size: $font-size-12;
  color: $clr_grey;
  text-decoration: none;
}

a {
  font-size: $font-size-12;
  color: $clr_grey;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $clr_darkblue;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hover-button {
  opacity: 0;
}

.trigger-icon {
  &:hover {
    .hover-button {
      opacity: 1;
    }
  }
}

//Fix Arrow Icon hover for trigger
.board_dashboard_sidebar {
  .Collapsible__trigger {
    position: absolute;
    left: 24px;
    top: 8px;
    cursor: pointer;

    .material-icon {
      color: $clr_lightblue2;
      font-size: 21px;
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.35, 0.61);
    }

    &.is-open {
      .material-icon {
        transform: rotate(90deg);
      }
    }

    &:hover + .trigger-icon .hover-button {
      // display: block;
      opacity: 1;
    }
  }
}

span {
  th {
    font-size: $font-size-12;
    color: $clr_grey;
    text-decoration: none;
  }
}

td {
  font-size: $font-size-12;
  color: $clr_grey;
  text-decoration: none;
}

.red {
  color: red;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-ligntblue2 {
  color: $clr_lightblue2 !important;
}

.error-clr {
  color: $clr_error !important;
}

.text-black2 {
  color: $clr_black2 !important;
}

.text-green {
  color: $clr_green2 !important;
}

.text-grey-clr {
  color: $clr_lightgrey !important;
}

.text-grey {
  color: $clr_lightgrey2 !important;
}

.text-ligntgrey {
  color: $clr_lightgrey7 !important;
}

.text-red {
  color: $clr_red !important;
}

.text-orange {
  color: $clr_orange !important;
}

.text-blue {
  color: $clr_blue !important;
}

.text-blue-clr {
  color: $clr_lightblue4 !important;
}

.text-darkblue {
  color: $clr_darkblue4 !important;
}

.text-darkblue2 {
  color: $clr_darkblue6 !important;
}

.font-weight-semibold {
  font-weight: 600;
}

.mx-5px {
  margin-left: 5px;
  margin-right: 5px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-16px {
  margin-bottom: 16px;
}

.mb-18px {
  margin-bottom: 18px;
}

.mb-20px {
  margin-bottom: 20px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-2px {
  margin-left: 2px;
}

.fs-12 {
  font-size: 12px;
}

.icon-fs-14 {
  font-size: 14px;
}

.icon-fs-16 {
  font-size: 16px;
}

.icon-fs-17 {
  font-size: 17px;
}

.icon-fs-18 {
  font-size: 18px;
}

.icon-fs-20 {
  font-size: 20px;
}

.icon-fs-22 {
  font-size: 22px;
}

.border {
  border: 1px solid $clr_border !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.btn-md {
  font-size: $font-size-h6;
  padding: 6.5px 12px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-center {
  justify-content: center;
}

.align-item-center {
  align-items: center;
}

.gap-10 {
  gap: 10px;
}

.card {
  background: #ffffff 0 0 no-repeat padding-box;
  // box-shadow: 0 0 25px #0b254024;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 3.5px;

  &.custom-card {
    .card-body {
      padding: 12px;
    }
  }
}

.btn-items-card-wrapper {
  .btn-items-card {
    &.closed-card {
      max-height: 61px;
      overflow: hidden;

      .show-more-btn {
        .close-icon {
          display: none;
        }

        .btn-link {
          display: inline-flex;
        }
      }
    }

    &.open-card {
      .show-more-btn {
        .close-icon {
          display: inline-flex;
        }

        .btn-link {
          display: none;
        }
      }

      .segment-items {
        max-height: 350px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow: auto;
        z-index: 0;
      }
    }

    &.custom-card {
      .card-body {
        padding: 6px 16px;
      }
    }

    .show-more-btn {
      .btn-link {
        // margin-top: 4px;
        white-space: nowrap;
        text-decoration: underline;
        color: $clr_lightblue2;
        padding-right: 0;
        font-size: 14px;
        margin-top: 3px;
      }

      .close-icon {
        margin-top: 8px;
        margin-left: 20px;
        color: $clr_lightblue2;
        cursor: pointer;
      }
    }

    .title {
      padding: 12px 28px 16px 0;
      margin-right: 20px;
      border-right: 1px solid $clr_border;
      align-self: flex-start;
    }

    .btn-items {
      margin-left: -6.5px;
      margin-right: -6.5px;
      z-index: 0 !important;

      li {
        padding-left: 6.5px;
        padding-right: 6.5px;

        .content {
          padding: 7px 10px;
          border-radius: 3px;
          background-color: $clr_lightgrey3;
          color: $clr_lightgrey2;
          font-size: 14px;
          cursor: pointer;
          margin-top: 7px;
          margin-bottom: 7px;
          z-index: 0 !important;

          &:hover {
            color: $clr_green3;
            background-color: $clr_lightgreen;
          }

          .icon {
            font-size: 18px;
            margin-bottom: 1px;
          }
        }

        &.active {
          .content {
            color: $clr_green3;
            background-color: $clr_lightgreen;
            z-index: 0 !important;
          }
        }
      }
    }
  }
}

.segments-card-wrapper {
  // padding-top: 14px;
  // padding-bottom: 14px;
  // position: sticky;
  // top: 109px;
  margin-bottom: 8px;
  // z-index: 105;
  width: fit-content;
  background-color: $clr_bodycolor;

  .btn-items-card {
    .btn-items {
      li .content {
        white-space: nowrap;
      }
    }
  }
}

.custom-checkbox-new {
  -webkit-appearance: none;
  min-width: 14px;
  width: 14px;
  height: 14px;
  border: 1px solid $clr_border;
  vertical-align: sub;
  outline: none;
  border-radius: 3px;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: $clr_blue;
    border-color: $clr_blue;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
      background-size: 28px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &:disabled,
  &[disabled],
  &:disabled ~ .form-check-label,
  &[disabled] ~ .form-check-label {
    opacity: 0.5;
    cursor: default !important;
  }
}

.custom-radio-input {
  cursor: pointer;
  margin-right: 10px;
  color: $clr_lightgrey;

  input {
    display: none;

    + span:not(:empty) {
      padding-left: 18px;
      position: relative;
      font-size: $font-size-h6;
    }

    + span:before,
    + span:after {
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      border-radius: 50%;
      left: 0;
      top: 2px;
      position: absolute;
    }

    + span:before {
      background: $clr_border;
    }

    + span:after {
      background: #fff;
      transform: scale(0.5);
    }

    &:checked {
      + span:after {
        transform: scale(0.5);
      }

      + span:before {
        transform: scale(1.04);
        background: $clr_darkblue5;
      }
    }
  }
}

.custom-collapser {
  .collapse {
    border: 0;
    font-size: $font-size-h6;
  }

  .collapse-trigger {
    &::before {
      content: none;
    }

    .title {
      text-transform: capitalize;
    }

    background-color: $clr_solitude;
    border-radius: 3px;
    padding: 2px 0 2px 8px;
    border: 0;

    .title {
      color: $clr_black2;
    }

    .material-icon {
      color: $clr_blue;
      font-size: 31px;
    }

    &[aria-expanded="true"] {
      .material-icon.rotate180 {
        transform: rotate(180deg);
      }
    }
  }

  .collapse-trigger div:nth-child(1)::before {
    content: none;
  }

  .collapse-panel {
    border: 0;
    padding: 0;
  }

  &.inner-sidebar-collapser {
    .Collapsible {
      position: relative;
    }

    .custom-collapser-item {
      .trigger-block {
        padding: 10px 6px 10px 8px;
        background-color: $clr_solitude;
        border-radius: 3px;
        // background-color: red;
        border: 0;
      }

      .Collapsible__trigger {
        position: absolute;
        right: 6px;
        top: 8px;
        cursor: pointer;

        .material-icon {
          color: $clr_lightblue2;
          font-size: 21px;
          transition: all 0.3s cubic-bezier(0.215, 0.61, 0.35, 0.61);
        }

        &.is-open {
          .material-icon {
            transform: rotate(90deg);
          }
        }
      }
    }

    .collapse-trigger {
      padding: 8px 6px 8px 8px;

      .material-icon {
        color: $clr_lightblue2;
        font-size: 21px;
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.35, 0.61);
      }

      &[aria-expanded="true"] {
        .material-icon {
          transform: rotate(90deg);
        }
      }

      &::before {
        content: none;
      }
    }

    .trigger-block {
      .title {
        margin-left: 10px;
        color: $clr_lightgrey2;
        margin-right: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      input:checked {
        + .title {
          color: $clr_blue;
        }
      }
    }

    .collapse-panel-content {
      .item {
        margin: 0 7px;
        padding: 10px 0 10px 10px;

        &:not(:last-child) {
          border-bottom: 0.5px solid $clr_tableborder;
        }

        label {
          margin-left: 11px;
          color: $clr_lightgrey2;
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }

        input:checked {
          + label {
            color: $clr_blue;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.custom-collapser2 {
  .collapse,
  .Collapsible {
    // box-shadow: 0 0 25px #0b254024;
    border: 1px solid $clr_lightgrey_new;
  }

  .Collapsible__trigger {
    &.is-open {
      .collapse-icon-wrapper .add {
        display: none;
      }

      .collapse-icon-wrapper .remove {
        display: flex;
      }
    }

    &.is-closed {
      .collapse-icon-wrapper .add {
        display: flex;
      }

      .collapse-icon-wrapper .remove {
        display: none;
      }
    }
  }

  .collapse-trigger {
    background-color: $clr_white;
    padding: 16px;

    .title {
      font-family: $font-family;
    }

    .collapse-icon-wrapper {
      padding: 3px;
      border-radius: 50%;
      background-color: $clr_lightgrey4;
      color: $clr_lightgrey2;

      .icon {
        font-size: 20px;
      }
    }

    &[aria-expanded="true"] {
      .collapse-icon-wrapper .add {
        display: none;
      }

      .collapse-icon-wrapper .remove {
        display: flex;
      }
    }

    &[aria-expanded="false"] {
      .collapse-icon-wrapper .add {
        display: flex;
      }

      .collapse-icon-wrapper .remove {
        display: none;
      }
    }
  }

  .collapse-panel {
    padding: 0 16px;

    &[aria-expanded="true"] {
      padding: 0 16px 16px;
    }
  }
}

.custom-collapser2-2 {
  .collapse {
    box-shadow: none;
    // border-top: 1px solid $clr_lightgrey8;
    // border-bottom: 1px solid $clr_lightgrey8;
    // margin-top: -1px;
    margin-bottom: 8px;
  }

  .collapse-trigger {
    padding: 15px;

    .collapse-icon-wrapper {
      background-color: transparent;
      border: 1px solid $clr_lightgrey2;
      padding: 2px;

      .icon {
        color: $clr_lightgrey2;
        font-size: 18px;
      }
    }
  }

  .collapse-panel {
    padding: 0;
    font-size: 14px;
    color: $clr_black2;
  }

  .collapse-panel[aria-expanded="true"] {
    padding: 16px;
  }
}

.plus-minus-collapser {
  .collapse,
  .Collapsible {
    border: 1px solid $clr_solitude;
    border-radius: 3px;
  }

  .collapse-trigger {
    padding: 5px 12px;
    border-radius: 3.5px;

    .material-icon {
      font-size: 16px;
      display: inline-flex;
      background-color: $clr_darkblue5;
      color: $clr_white;
      padding: 2px;
      border-radius: 50%;

      &::before {
        font: 16px "Material Icons Round";
        background-color: $clr_darkblue5;
        color: $clr_white;
        border-radius: 50%;
      }
    }

    &[aria-expanded="true"],
    &.is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .material-icon {
        &::before {
          content: "\e15b";
        }
      }
    }

    &[aria-expanded="false"],
    &.is-closed {
      .material-icon {
        &::before {
          content: "\e145";
        }
      }
    }
  }

  .collapse-panel {
    padding-left: 12px;
    padding-right: 12px;

    &[aria-expanded="true"] {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom-left-radius: 3.5px;
      border-bottom-right-radius: 3.5px;
    }
  }

  .Collapsible__contentInner {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-left-radius: 3.5px;
    border-bottom-right-radius: 3.5px;
  }
}

.details-page-collapser {
  &.custom-collapser2 {
    .Collapsible {
      // box-shadow: 0 0 25px rgba(11, 37, 64, 0.14);
      border: 1px solid $clr_lightgrey_new;

      .Collapsible__trigger {
        &.is-open {
          .collapse-trigger {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .Collapsible__contentOuter {
        .Collapsible__contentInner {
          padding: 16px;
          background-color: #fff;
          // border-radius: 3px;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      .Collapsible__trigger {
        &.is-open {
          .collapse-trigger {
            padding-bottom: 16px;
          }
        }
      }
    }

    // .collapse-trigger {
    //   position: sticky;
    //   top: 198px;
    //   z-index: 103;
    // }
  }

  &:nth-child(even) {
    .collapse-trigger .title {
      background-color: $clr_lightgrey6;
      color: $clr_cornflower_blue;
    }
  }

  &:nth-child(odd) {
    .collapse-trigger .title {
      background-color: $clr_lilywhite;
      color: $clr_lightgreen2;
    }
  }

  .collapse-trigger {
    .title {
      padding: 5px 16px;
      border-radius: 4px;
    }

    .icon-wrapper {
      border-radius: 9px;
      padding: 5px;

      .icon {
        font-size: 22px;

        &::before {
          font: 22px "Material Icons";
        }
      }
    }

    // .Category {
    //   background-color: $clr_lightorange;
    //   color: $clr_orange2;
    //   .icon {
    //     &::before {
    //       content: "\e574";
    //     }
    //   }
    // }
  }

  .sub-category-content {
    padding: 0 10px;

    .text-block {
      img {
        margin-bottom: 12px;
      }
    }
    /* Media query for screen widths below 720px */
    @media (max-width: 768px) {
      .font-default {
        font-size: 1.2rem;
      }
    }
    /* Media query for schreen widths between 720px and 1920px */
    @media (min-width: 768px) and (max-width: 1920px) {
      .font-default {
        font-size: 1.5rem;
      }
    }
    /* Media query for screen widths above 1920px */
    @media (min-width: 1920px) {
      .font-default {
        font-size: 1.8rem;
      }
    }
  }

  .companyDetailsTable {
    width: 100%;

    th {
      color: $clr_black2;
      font-weight: bold;
    }
  }
}

// .no-segments-card-wrapper {
//   .details-page-collapser {
//     &.custom-collapser2 {
//       // &:first-child {
//       //   padding-top: 15px;
//       // }
//       .collapse-trigger {
//         // top: 80px;
//         &::before {
//           content: "";
//           height: 15px;
//           display: block;
//           position: absolute;
//           top: -15px;
//           left: 0;
//           transform: none !important;
//           width: 100%;
//           border: 0;
//           z-index: 103;
//           background-color: #f1f4f8;
//         }
//       }
//     }
//   }
// }
.company-details-collapser {
  .collapse-trigger {
    .Insights {
      background-color: $clr_lightorange;
      color: $clr_orange2;

      .icon {
        &::before {
          content: "\f092";
        }
      }
    }

    .Strategy {
      background-color: #f6e6ff;
      color: #9f1ee8;

      .icon {
        &::before {
          content: "\e0f0";
        }
      }
    }

    .Financial {
      background-color: $clr_lightorange;
      color: $clr_orange2;

      .icon {
        &::before {
          content: "\e8a1";
        }
      }
    }

    .References {
      background-color: #f6e6ff;
      color: #9f1ee8;

      .icon {
        &::before {
          content: "\e873";
        }
      }
    }
  }
}

.responsive-table {
  overflow-x: auto;
}

.custom-table-block {
  z-index: 9 !important;

  .responsive-table {
    // box-shadow: 0 0 25px #0b254024;
    border-radius: 3.5px;
    width: 100%;
  }

  .btn-md {
    padding: 0.3rem 0.75rem;
  }

  .scroll-table-wrapper {
    max-height: 445px;
    overflow: auto;
  }

  &.custom-table-block-2 {
    table {
      td {
        font-size: 14px;
      }
    }
  }

  table {
    box-shadow: 0 0 25px #0b254024;
    border-radius: 3.5px;
    color: $clr-black;
    background-color: $clr_white;
    width: 100%;

    tr {
      th:first-child,
      td:first-child {
        padding-left: 20px;
      }

      th:last-child,
      td:last-child {
        padding-right: 20px;
      }

      &:first-child {
        th {
          vertical-align: top;
          background-color: $clr_lightblue3;
          position: sticky;
          top: 0;
          z-index: 5;

          &:first-child {
            border-top-left-radius: 3.5px;
          }

          &:last-child {
            border-top-right-radius: 3.5px;
          }
        }
      }

      th {
        background-color: $clr_lightblue3;
        color: $clr_white;
        font-weight: normal;
        font-size: 14px;
        padding: 12px;
      }

      td {
        font-size: 13px;
        padding: 0 10px;
        border-bottom: 1px solid $clr_tableborder;
        height: 40px;
        color: $clr_black;

        .link,
        .link label {
          color: $clr_lightblue2;
          cursor: pointer;
          font-size: 14px;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  &.nowrap-table-block {
    table tr td {
      white-space: nowrap;
    }
  }

  &.th-nowrap {
    table tr th {
      white-space: nowrap;
    }
  }

  &.sort-table-block {
    &.center-th-align {
      .sort-table-th {
        align-items: center;

        .sort-icon {
          margin-top: 0;
        }
      }
    }

    th.text-center .th-content {
      justify-content: center;
      text-align: left;
    }

    table tr th {
      .sort-icon {
        margin-top: 4px;
      }

      .th-content {
        display: flex;
        align-items: flex-start;
      }

      &.default,
      &.asc,
      &.desc {
        cursor: pointer;
      }

      // &.default .th-content:after,
      // &.asc .th-content:after,
      // &.desc .th-content:after{
      //   font: 12px FontAwesome;
      //   margin-top: 5px;
      //   margin-left: 5px;
      // }
      // &.default .th-content:after {
      //   content: "\f0dc";
      // }
      // &.asc .th-content:after {
      //   content: "\f0de";
      //   vertical-align: bottom;
      // }
      // &.desc .th-content:after {
      //   content: "\f0dd";
      //   vertical-align: top;
      // }
      &.default .th-content:after,
      &.asc .th-content:after,
      &.desc .th-content:after {
        font: 18px "Material Icons";
        margin-top: 5px;
        margin-left: 5px;
        line-height: 7px;
      }

      &.default {
        .th-content:after {
          content: "\e5c7\A\e5c5";
          line-height: 7px;
          white-space: pre;
        }
      }

      &.desc {
        .th-content:after {
          content: "\e5c5";
          line-height: 16px;
          vertical-align: bottom;
        }
      }

      &.asc {
        .th-content:after {
          content: "\e5c7";
        }
      }
    }
  }

  &.detail-panel-table-block {
    table tr {
      td {
        &:first-child {
          padding: 6px 2px 6px 12px;
        }

        padding: 6px 12px;

        .MuiIconButton-root {
          padding: 3px;
        }

        .expand-collapse-icon {
          font-size: 27px;
          display: flex;
          cursor: pointer;
          color: $clr_lightgrey;
        }
      }

      &.row-expand {
        td {
          .expand-collapse-icon {
            color: $clr_blue;
          }

          background-color: $clr_alice_blue;
        }
      }
    }

    &.first-col-fixed-block {
      tr th:nth-child(2),
      tr td:nth-child(2) {
        position: sticky;
        left: 41px;
      }

      tr th:nth-child(2) {
        z-index: 0;
      }

      tr td:nth-child(2) {
        z-index: 0;
        background-color: $clr_white;
      }

      tr.row-expand td:nth-child(1),
      tr.row-expand td:nth-child(2) {
        background-color: $clr_alice_blue;
      }
    }
  }

  &.first-col-fixed-block {
    tr th:nth-child(1),
    tr td:nth-child(1) {
      position: sticky;
      left: 0;
    }

    tr th:nth-child(1) {
      z-index: 10;
    }

    tr td:nth-child(1) {
      z-index: 7;
      background-color: $clr_white;
    }
  }
}

table {
  tr {
    td {
      .icon {
        display: inline-flex;
        font-size: 14px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}

.companies-list-table-block {
  .segment-col {
    min-width: 70px;
  }

  .allowed-access,
  .allowed-access-warning,
  .not-allowed-access,
  .allowed-access-icon,
  .inactive-allowed-access-icon,
  .not-allowed-access-icon {
    display: flex;
    justify-content: center;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

.quant-data-table-block {
  table {
    tr {
      td {
        color: $clr_lightgrey;
      }
    }
  }
}

.custom-pagination-new {
  &.pagination-block {
    .item-count-block,
    .pagination {
      margin-top: 20px;
    }

    .pagination {
      flex-wrap: wrap;

      .page-item {
        margin: 4.5px;
        // box-shadow: 0 0 12px #0b254014;
        border: 1px solid $clr_lightgrey_new;
        border-radius: 5px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }

        &.active {
          .page-link {
            background-color: $clr_lightblue4;
            color: $clr_white;
          }
        }
      }

      .page-link {
        font-size: 14px;
        border: 0;
        border-radius: 5px;
        min-width: 38px;
        text-align: center;
        color: $clr_black;
      }

      .page-link[aria-label="Previous page"],
      .page-link[aria-label="Next page"] {
        padding: 6.5px 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .material-icons-outlined {
          font-size: 20px;
        }
      }
    }

    &.right-pagination {
      .pagination {
        margin-left: auto;
      }
    }

    // &.center-pagination {
    //   @media (min-width: 1200px) {
    //     position: relative;
    //   }
    //   .item-count-block {
    //     @media (min-width: 1200px) {
    //       position: absolute;
    //     }
    //   }
    //   .pagination {
    //     margin-left: auto;
    //     @media (min-width: 1200px) {
    //       margin-right: auto;
    //     }
    //   }
    // }
  }
}

.new-paging {
  // background-color: orange;
  display: flex;
  justify-content: space-between;
  // align-items: flex-end;
}

.viewing-text {
  margin-left: 15px;
  margin-top: 35px;

  p {
    font-size: 14px;
    color: #000000;
  }

  span {
    color: #046cd9;
  }
}

.custom-select-new {
  position: relative;
  display: block;
  // margin: 0 auto;
  border-radius: 3.5px;
  background-color: $clr_white;
  // box-shadow: 0 0 12px #0b254014;
  border: 1px solid $clr_lightgrey_new;
  z-index: 10;

  select {
    border: none;
    outline: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    margin: 0;
    display: block;
    width: 100%;
    padding: 6px 45px 6px 12px;
    font-size: 14px;
    color: $clr_black;
  }

  &:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: calc(100% - 12px);
    margin: 6px 0;
    line-height: 20px;
    font-family: "Material Icons";
    content: "\e5cf";
    text-align: center;
    color: $clr_black;
    font-size: 24px;
    border-left: 1px solid $clr_border;
    z-index: -1;
  }
}

.custom-select-new-2 {
  select {
    border: 0.5px solid $clr_border;
    border-radius: 5px;
  }

  &:after {
    height: 100%;
    margin: 0;
    line-height: 35px;
    color: $clr_lightblue2;
  }
}

.custom-select-dropdown {
  select {
    border: 0.5px solid $clr_border;
    border-radius: 5px;
  }

  &:after {
    height: 100%;
    margin: 0;
    line-height: 35px;
    color: $clr_lightblue2;
    border-left: 0;
  }
}

.search-box-shadow-2 {
  .search-form {
    input {
      // box-shadow: 0 5px 12px #0b254026;
      border: 1px solid $clr_lightgrey_new;
      // &:focus {
      // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      // }
    }
  }
}

.custom-combobox-list {
  font-size: $font-size-h6;
  // width: 100px;

  .rw-list-option.rw-state-focus {
    background-color: $clr_lightblue2;
    border-color: $clr_lightblue2;
    color: #fff;
  }

  &.min-width {
    .rw-widget-picker {
      width: 184px;
    }
  }

  .rw-widget-picker {
    height: 35px;
  }

  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none;
    border: 1px solid $clr_lightgrey_new;
  }

  .rw-widget-container.rw-state-focus,
  .rw-state-focus > .rw-widget-container,
  .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container:hover {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: rgba(0, 123, 255, 0.25);
  }

  .rw-btn-select {
    margin-right: 4px;
  }

  .rw-i {
    display: flex;
  }

  .rw-i-caret-down:before {
    font: 24px "Material Icons";
    content: "\e5cf";
    color: $clr_lightblue2;
    transition: all 0.2s ease;
  }

  // .rw-open{
  //   .rw-i-caret-down:before{
  //     transform: rotate(180deg);
  //   }
  // }
  .rw-list-option.rw-state-selected,
  .rw-list-option.rw-state-selected:hover {
    background-color: $clr_lightblue2 !important;
    border-color: $clr_lightblue2 !important;
    color: $clr_white !important;
  }
}

.custom-multiselect {
  font-size: $font-size-h6;

  .rw-multiselect .rw-input-reset {
    height: 100%;
  }

  .rw-widget-picker {
    height: 35px;

    > div {
      vertical-align: middle;
    }

    .rw-multiselect-taglist {
      height: 100%;
    }
  }

  .rw-widget-input,
  .rw-filter-input {
    // box-shadow: 0 5px 12px #0b254026;
    // border: 0.5px solid $clr_border;
    border: 1px solid $clr_lightgrey_new;
  }

  .rw-widget-container.rw-state-focus,
  .rw-state-focus > .rw-widget-container,
  .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container:hover {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: rgba(0, 123, 255, 0.25);
  }

  .rw-btn-select {
    margin-right: 4px;
  }

  .rw-select {
    button {
      display: none;
    }

    &:before {
      font: 24px "Material Icons";
      content: "\e5cf";
      color: $clr_lightblue2;
      transition: all 0.2s ease;
      line-height: 33px;
    }
  }
}

.custom-dropdown-checkbox-list {
  font-size: $font-size-h6;

  .filter-dropdown {
    .dd__selectControl {
      border: 0.5px solid $clr_border;
      box-shadow: 0 5px 12px #0b254026;
      color: $clr_lightgrey;
      max-height: 35px;
      height: 35px;
      font-size: $font-size-h6;
      padding: 0 30px 0 7.5px !important;

      .list-items {
        .option-label-wrapper {
          .option-label {
            background-color: $clr_lightblue2;
            border-radius: 2px;
            padding: 2px 6px;
            color: $clr_white !important;
            margin-right: 6px;
          }
        }
      }

      div {
        font-size: $font-size-h6;
        white-space: nowrap;
        // text-overflow: ellipsis;
        overflow: hidden;
      }

      .icon {
        display: flex;
        right: 6px;
        position: absolute;
      }

      .icon-down:before {
        font: 24px "Material Icons";
        content: "\e5cf";
        color: $clr_lightblue2;
      }

      .icon-up:before {
        font: 24px "Material Icons";
        content: "\e5ce";
        color: $clr_lightblue2;
      }

      .placeholder {
        margin-left: 7px;
      }
    }

    .filter-options {
      z-index: 110;
      border: 0;
      box-shadow: 0 5px 12px #0b254026;

      > div {
        padding: 8px 12px;

        &:not(:last-child) {
          border-bottom: 0.5px solid $clr_tableborder;
        }

        > .filter-options-div {
          display: flex;
          align-items: center;
          font-size: 12px;

          input[type="checkbox"] {
            -webkit-appearance: none;
            min-width: 14px;
            width: 14px;
            height: 14px;
            border: 1px solid $clr_lightgrey2;
            vertical-align: sub;
            outline: none;
            border-radius: 3px;
            position: relative;

            &:checked {
              background-color: $clr_blue;
              border-color: $clr_blue;

              &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
                background-size: 28px;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }

          label {
            margin-bottom: 0;
            margin-left: 12px;
            color: $clr_lightgrey2;
          }

          input[type="checkbox"]:checked + label {
            color: $clr_blue;
          }
        }
      }
    }
  }

  &.year-list {
    .list-items {
      @media (min-width: 768px) and (max-width: 991px) {
        .option-label-wrapper {
          &:nth-child(n + 8) {
            display: none;
          }

          &:nth-child(7) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
      @media (min-width: 992px) and (max-width: 1299px) {
        .option-label-wrapper {
          &:nth-child(n + 3) {
            display: none;
          }

          &:nth-child(2) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
      @media (min-width: 1300px) {
        .option-label-wrapper {
          &:nth-child(n + 4) {
            display: none;
          }

          &:nth-child(3) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
    }
  }

  &.quater-list {
    .list-items {
      @media (min-width: 992px) and (max-width: 1299px) {
        .option-label-wrapper {
          &:nth-child(n + 4) {
            display: none;
          }

          &:nth-child(3) {
            &::after {
              content: "...";
              font-size: 15px;
              line-height: 10px;
              color: $clr_black;
            }
          }
        }
      }
    }
  }
}

.min-width-266 {
  min-width: 266px;
}

.search-form {
  position: relative;

  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0 0 12px #0b254014;
    border: 1px solid $clr_lightgrey_new;
    border-radius: 5px;
    padding: 6px 48px 6px 14px;
    height: 35px;
    width: 100%;
    font-size: $font-size-h6;
  }

  input.form-control {
    width: 100%;
  }

  button {
    position: absolute;
    right: 0;
    top: 4px;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-left: 1px solid $clr_border;
    border-radius: 0;

    .material-icons-outlined {
      font-size: 20px;
      color: $clr_lightblue2;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.w-100-search {
  .search-form {
    max-width: 100%;
  }
}

.border-search {
  .search-form {
    input {
      border: 1px solid $clr_lightgrey_new;
    }
  }
}

.box-shadow-none-search {
  .search-form {
    input {
      box-shadow: none;
    }
  }
}

input.custom-datepicker {
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 0 0 12px #0b254014;
  border: 1px solid $clr_lightgrey_new;
  border-radius: 5px;
  padding: 2px 5px 4px 10px;
  outline: none;
  border: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../Images/date-picker-icon.svg) no-repeat;
  width: 16px;
  height: 16px;
  background-size: 15px;
  background-position-y: 4px;
  border-width: thin;
}

.w80 {
  width: 80px;
}

.br_8 {
  border-radius: 8px;
}

header,
footer {
  width: 100%;
  position: fixed;
  z-index: 106;
  background: $clr_bodycolor;
}

.header-content-wrapper {
  padding: 0 15px;
  background-color: #fff;
}

header {
  left: 0;
  right: 0;
  top: 0;
  height: 60px;

  .header-content-wrapper {
    border-bottom: 1px solid $clr_border;
  }

  .header-content {
    .site_name {
      font-size: 14px;
      letter-spacing: 0.75px;
      @media (min-width: 556px) {
        font-size: 18px;
      }
      @media (min-width: 768px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      @media (min-width: 992px) {
        font-size: 24px;
      }
      @media (max-width: 767px) {
        align-self: flex-end;
        margin-bottom: 14px;
        font-size: 14px;
      }
    }
  }
}

footer {
  height: 39px;
  bottom: 0;

  .footer-content {
    color: $clr_lightgrey;
    border-top: 1px solid $clr_border;
    font-size: 12.64px;
    margin: 0 15px;
    margin-left: 103px;
  }
}

.inner-sidebar {
  width: 100%;
  max-width: 190px;
  background: #fff;
  height: -o-calc(100% - 194px);
  height: -ms-calc(100% - 194px);
  height: calc(100% - 194px);
  // box-shadow: 0 0 25px #0b25401a;
  border: 1px solid $clr_lightgrey_new;
  position: fixed;
  top: 142px;
  // left: 140px;
  overflow-y: auto;
  padding: 5px 7px;
  border-radius: 3.5px;
  z-index: 106;
  left: 120px;

  .icon {
    color: $clr_grey2;
    cursor: pointer;
  }
}

.inner-sidebar + .inner-sidebar-right-content {
  margin-left: 225px;
  transition: all 0.5s ease;
}

.opened-innersidebar {
  .inner-sidebar {
    transition: all 0.5s ease;
    max-width: 220px;
  }

  .icon-wrapper {
    .icon {
      margin-left: auto;
    }
  }

  .opened-innersidebar-content {
    transition: all 0.5s ease;

    .add_category {
      margin-top: -25px;
      background-color: unset;
      color: $clr_black2;
      border: none;

      &:focus {
        box-shadow: none;
      }

      .icon {
        margin-right: 6px;
      }
    }
  }

  .closed-innersidebar-content {
    opacity: 0;
    transform: translateX(-50px);
  }
}

.closed-innersidebar {
  .inner-sidebar {
    max-width: 49px;
    transition: all 0.5s ease;
    overflow-y: hidden;
  }

  .inner-sidebar-right-content {
    // padding-left: 65px;
    margin-left: 50px;
    margin-right: 22px;
    transition: all 0.5s ease;
  }

  .icon-wrapper {
    justify-content: center;

    .icon {
      transform: rotate(180deg);
    }
  }

  .opened-innersidebar-content {
    width: 0;
    transform: translateX(-176px);
    transition: all 0.5s ease;
    height: 0;
  }

  .closed-innersidebar-content {
    opacity: 1;
    transition: all 0.5s ease;
    transform: translateX(0);

    .content {
      writing-mode: vertical-lr;
      margin: 6px;
      font-size: 16px;
      transform: rotate(180deg);
    }
  }
}

.breadcrumb-mb-0 {
  .new-breadcrumb .breadcrumb {
    margin-bottom: 0;
  }
}

.new-breadcrumb {
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin-bottom: 13px;
    list-style: none;
    background-color: transparent;

    .breadcrumb-item {
      color: $clr_lightgrey2;
      font-size: 16px;

      + .breadcrumb-item:not(:last-child):before {
        color: $clr_lightblue2;
      }

      &:first-child,
      &:first-child .nav-link {
        color: $clr_lightblue2;
        font-weight: bold;
        font-size: 22.78px;
        font-family: $font-family-2;
      }

      &:nth-child(2) {
        padding-left: 1rem;
      }

      .nav-link {
        display: inline;
        color: $clr_lightblue2;
        padding: 0;
        font-size: 16px;
      }
    }
  }
}

.row {
  &.xl-gutter {
    @media (min-width: 768px) {
      margin-left: -19px;
      margin-right: -19px;
    }
    @media (min-width: 1200px) {
      margin-left: -25.5px;
      margin-right: -25.5px;
    }

    .col-sm-6,
    .col-lg-4,
    .col-xl-3 {
      @media (min-width: 768px) {
        padding-left: 19px;
        padding-right: 19px;
      }
      @media (min-width: 1200px) {
        padding-left: 25.5px;
        padding-right: 25.5px;
      }
    }
  }
}

.companies-details-page-title-sec {
  // padding-bottom: 13px;
  .new-breadcrumb {
    .breadcrumb {
      margin-bottom: 0;
    }
  }
}

.fixed-title-section1 {
  position: sticky;
  top: 0;
  z-index: 106;
  background-color: $clr_white;
  padding: 16px 0;
}

.fixed-title-section {
  position: sticky;
  top: 60px;
  z-index: 106;
  background-color: $clr_white;
  padding: 16px 0;
}

.board-details-page-title-sec {
  padding-bottom: 14px;
}

.main_content_height {
  min-height: calc(100vh - 116px);
  //overflow-y: auto;
}

.main_content_height_2 {
  min-height: 610px;
}

.main_content_height_board {
  min-height: 666px;
}
.main_content {
  width: 100%;
  padding: 60px 0 15px 88px;
  border-radius: 8px;
  @include transition(all 0.5s ease-in-out);

  .title-left-content,
  .title-right-block {
    margin-bottom: 25px;
  }

  .title-section {
    margin-bottom: 16px;
    background-color: $clr_white;
  }
}

.main_content_2 {
  .inner-sidebar {
    top: 140px;
    height: calc(100% - 194px);
    overflow-y: auto;
    background-color: transparent;
    box-shadow: none;
    max-width: 230px;
  }

  .inner-sidebar + .inner-sidebar-right-content {
    margin-left: 0 !important;
  }
}

.fixed-title-main-content.main_content {
  padding-top: 60px;

  @media (max-width: 992px) {
    padding-top: 60px;
  }
}

.custom-popover-author {
  .popover {
    border: 0;
    border-radius: 3.5px;
    border: 1px solid $clr_lightgrey_new;
    z-index: 1040;

    .popover-inner {
      max-height: 450px;
      overflow-y: scroll;
      border-radius: 3.5px;
      border-top: 12px solid $clr_lightblue2;
      border-bottom: 5px solid $clr_lightblue2;
    }

    .popover-header {
      color: $clr_darkblue6;
      padding-bottom: 4px;
      border: none;
      background-color: #ffffff;
    }

    &.bs-popover-bottom .popover-header::before,
    &.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      border: none;
    }

    .arrow {
      display: none;
    }

    &.bs-popover-auto[x-placement^="bottom"] {
      margin-right: 10px;
    }

    .popover-item {
      align-items: center;
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      border-radius: 3px;
      color: $clr_lightgrey2;
      margin-bottom: 3px;

      .icon {
        display: inline-flex;
        color: $clr_lightgrey2;
        font-size: 22px;
      }

      &:hover {
        background-color: $clr_solitude;
        color: $clr_blue;

        .icon {
          color: $clr_blue;
        }
      }
    }

    .active {
      background-color: $clr_solitude;
    }
  }
}

.authors-popover {
  .popover {
    .popover-body {
      padding: 0.5rem 1.2rem;

      .authors-list {
        .author {
          padding: 10px 0;

          &:not(:last-child) {
            border-bottom: 0.5px solid $clr_tableborder;
          }

          a {
            font-size: 14px;
            text-transform: capitalize;
            line-height: 16px;
            color: $clr_lightblue2;
          }
        }
      }
    }
  }
}

.dropdown-btn {
  height: 33px;
  border: 1px solid $clr_lightblue5;
  background-color: $clr_alice_blue2;
  border-radius: 5px;
  color: $clr_lightblue2;
  font-size: 14px;

  .arrow-drown-down {
    color: $clr_lightblue2;
  }

  &:hover {
    color: $clr_lightblue2;

    .arrow-drown-down {
      color: $clr_lightblue2;
    }
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }
}

.icon-btn {
  width: 33px;
  height: 33px;
  border-radius: 5px;
  border: 0;
  outline: none;

  .icon {
    font-size: 17px;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.icon-box-shadow {
    box-shadow: 0 5px 12px #0b254026;
  }
}

.help-icon-box-shadow {
  .icon-btn {
    box-shadow: 0 5px 12px #0b254026;
  }
}

.icon-btn-primary {
  background-color: $clr_lightblue2;

  .icon {
    color: $clr_white;
  }
}

.icon-btn-white {
  background-color: $clr_white;

  .icon {
    color: $clr_lightblue2;
  }
}

.icon-text-primary-btn {
  &.btn-md {
    line-height: 1;
    padding: 8px 10px;
  }
}

nav {
  width: 100%;
  // box-shadow: 0 1px $font-size-12 0 #ddd;
  .form-inline {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    position: relative;

    .form-control {
      width: 100%;
      padding-right: 50px;
    }

    .btn {
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      background: $clr_lightblue;
      border-color: $clr_lightblue;
      color: $clr_white;
    }
  }
}

.tbr-embmelogo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 16px 0 23px;
  @media (min-width: 768px) {
    margin: 32px 0 44px;
  }

  #tbr-emblem-logo {
    max-width: 38px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    @media (min-width: 768px) {
      max-width: 48px;
    }
  }
}

.navbar-brand.header-logo {
  @media (max-width: 767px) {
    margin-right: 0;
  }

  #tbr-logo {
    // width: 60px;
    margin: 0 0 14px 0;
  }
}

.open_nav {
  .tbr-embmelogo-wrapper {
    pointer-events: none;
  }

  #tbr-emblem-logo {
    opacity: 0;
  }

  // @media (min-width: 768px) {

  // }
}

.custom-popover,
.custom-popover-2 {
  .popover {
    top: -8px !important;
    border: 0;
    border-radius: 3.5px;
    box-shadow: 0 0 30px #0b254017;
    z-index: 1040;

    .popover-inner {
      border-radius: 3.5px;
      border-top: 12px solid $clr_lightblue2;
      border-bottom: 5px solid $clr_lightblue2;
    }

    .popover-header {
      color: $clr_darkblue6;
      padding-bottom: 4px;
      border: none;
      background-color: #ffffff;
    }

    &.bs-popover-bottom .popover-header::before,
    &.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      border: none;
    }

    .arrow {
      display: none;
    }

    &.bs-popover-auto[x-placement^="bottom"] {
      margin-right: 10px;
    }

    .popover-item {
      align-items: center;
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      border-radius: 3px;
      color: $clr_lightgrey2;
      margin-bottom: 3px;

      .icon {
        display: inline-flex;
        color: $clr_lightgrey2;
        font-size: 22px;
      }

      &:hover {
        background-color: $clr_solitude;
        color: $clr_blue;

        .icon {
          color: $clr_blue;
        }
      }
    }

    .active {
      background-color: $clr_solitude;
    }
  }

  &.chart-type-popover {
    .popover {
      &.bs-popover-auto[x-placement^="bottom"] {
        margin-top: 0;
      }
    }
  }
}

.custom-popover-2 {
  .popover {
    top: 10px !important;
    border: 0;
    border-radius: 3.5px;
    box-shadow: 0 0 30px #0b254017;
    z-index: 1040;
  }
}

.company_details_more_options {
  > span {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    border-radius: 3px;
    color: $clr_lightgrey2;

    &.inactive {
      cursor: default;
      background: none;
      pointer-events: none;
      color: grey;
      opacity: 0.65;
    }

    .icon {
      display: inline-flex;
      color: $clr_lightgrey2;
      font-size: 22px;
    }

    &:hover {
      background-color: $clr_solitude;
      color: $clr_blue;

      .icon {
        color: $clr_blue;
      }
    }
  }
}

.menu_lines {
  display: block;
  cursor: pointer;

  @media only screen and (max-width: 992px) {
    top: 5px;
  }
}

.bar1 {
  width: 30px;
  height: 2px;
  background-color: $clr_lightgrey;
  margin: 5px 0;
  display: block;
  @include transition(all 0.4s ease-in-out);
}

.bar2 {
  width: 30px;
  height: 2px;
  background-color: $clr_lightgrey;
  margin: 5px 0;
  display: block;
  @include transition(all 0.4s ease-in-out);
}

.bar3 {
  width: 30px;
  height: 2px;
  background-color: $clr_lightgrey;
  margin: 5px 0;
  display: block;
  @include transition(all 0.4s ease-in-out);
}

.title-right-block {
  .icon-group {
    .icon-btn {
      margin-left: 14px;
    }

    .icon-btn:first-child {
      margin-left: 17px;
    }
  }
}

.open_nav {
  // .bar1 {
  //   -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  //   transform: rotate(-45deg) translate(-5px, 2px);
  // }
  // .bar2 {
  //   opacity: 0;
  // }
  // .bar3 {
  //   -webkit-transform: rotate(45deg) translate(-5px, -3px);
  //   transform: rotate(45deg) translate(-5px, -3px);
  // }
  //   test
  // .sidebar_nav_labels {
  //   .navbar-brand #tbr-logo {
  //     width: 0;
  //   }
  // }
  // .sidebar_nav_labels .nav-link {
  //   font-size: 0;
  // }
  .nav_sidebar {
    // width: 3%;
    // max-width: 76px;
    // @media (min-width: 1366px){
    //   max-width: 88px;
    // }
    max-width: 250px;
    @media only screen and (max-width: 1199px) {
      // width: 5%;
      left: 0;
    }
    // @media (max-width: 991px) {
    //   max-width: 76px;
    // }
    ul {
      li {
        text-align: center;
        white-space: nowrap;

        label {
          padding-left: 0;
          // transform: translateX(-100em);
          // text-indent: -9999px;
        }

        i {
          // left: 0%;
          // right: 0;
          margin: 0 auto;
          opacity: 1;
          @include transition(all 0.5s ease-in-out);
        }
      }
    }

    + .main_content {
      // width: 97%;
      width: 100%;
      // padding-left: 88px;
      @media only screen and (max-width: 767px) {
        padding-left: 15px;
      }
    }
  }

  .nav_sidebar2 {
    // left: 105px;
    @media only screen and (max-width: 1199px) {
      // left: 5%;
    }

    + .main_content {
      // width: 100%;
      // padding-left: 400px;
      @media only screen and (max-width: 767px) {
        // padding-left: 0;
      }
      // @media only screen and (max-width: 1199px) {
      //   width: 100%;
      //   padding-left: 260px;
      // }
      //   @media only screen and (max-width:767px){
      // 	width: 75%;
      //   }
    }

    @media only screen and (max-width: 1366px) {
      // width: 12%;
    }
    @media only screen and (max-width: 1199px) {
      // width: 20%;
    }
    @media only screen and (max-width: 767px) {
      // left: 5%;
    }
  }

  // test end
}

@media only screen and (min-width: 1200px) {
  // .bar1 {
  //   -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  //   transform: rotate(-45deg) translate(-5px, 2px);
  // }
  // .bar2 {
  //   opacity: 0;
  // }
  // .bar3 {
  //   -webkit-transform: rotate(45deg) translate(-5px, -3px);
  //   transform: rotate(45deg) translate(-5px, -3px);
  // }
  .open_nav {
    .bar1,
    .bar2,
    .bar3 {
      transform: none;
    }

    .bar2 {
      opacity: 1;
    }
  }
}

.navbar-brand {
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: center;
  padding: 0;
  // padding-left: 40px;
}

.navbar-expand-lg {
  padding-left: 10px;

  .logo {
    padding-left: 0;
  }
}

.profile_circle {
  color: $clr_white !important;
  width: 36px;
  height: 36px;
  padding: 5px;
  border-radius: 50%;
  background-color: $clr_blue_tooltip;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.login_sec {
  ul {
    li {
      position: relative;

      &.user-details {
        cursor: pointer;
        margin-right: 15px;

        .user-details-block {
          font-size: 14px;
          margin: 0 6px 0 8px;
        }

        .arrow-drown-down {
          color: $clr_blue;
          font-size: 27px;
        }
      }

      span {
        color: $clr_lightgrey;

        &.preferences {
          padding: 0;
        }

        &.user-role {
          font-size: $font-size-12;
          text-align: right;
          display: block;
          text-transform: capitalize;
        }
      }

      a {
        padding: 0 5px;

        i {
          color: $clr_lightblue;
          font-size: 22px;
        }
      }

      span {
        // padding: 0 5px;
        i {
          color: $clr_lightblue;
          font-size: 22px;
        }

        cursor: pointer;
      }

      ul {
        display: block;
        width: 300px;
        padding: 15px;
        background: $clr_white;
        box-shadow: 0 0 4px #b5b5b5;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        @include transition(all 0.5s ease-in-out);

        li {
          width: 100%;
          float: left;
          position: relative;
          padding: 0 30px;
          margin-bottom: 15px;

          &:hover {
            a,
            i,
            span {
              color: $clr_darkblue;
            }
          }

          a,
          span {
            color: $clr_lightblue;
            font-size: $font-size-12;
            font-weight: 600;
            display: block;
            position: relative;
            padding-left: 25px;

            i {
              font-size: 18px;
              padding-right: 5px;
              position: absolute;
              left: 0;
              top: 1px;
            }

            span {
              display: block;
              font-size: 11px;
              color: #b0b5bd;
              cursor: pointer;
            }
          }
        }
      }

      &:nth-last-child(2) {
        @media only screen and (min-width: 768px) {
          padding-right: 0;
        }
      }
    }

    li.active {
      ul {
        top: 50px;
        visibility: visible;
        opacity: 1;
        @media only screen and (max-width: 992px) {
          top: 44px;
        }
      }
    }
  }
}

.login_sec.user-popover {
  ul {
    display: block;
    width: 280px;
    padding: 15px;
    background: #fff;
    box-shadow: 0 0 30px #0b254017;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    z-index: 1040;
    right: 15px;
    border-radius: 3.5px;
    border-top: 12px solid $clr_lightblue2;
    border-bottom: 5px solid $clr_lightblue2;
  }

  li {
    width: 100%;
    float: left;
    position: relative;
    padding: 0 18px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 10px;
    }

    a,
    span {
      color: #1085c6;
      font-size: 0.75rem;
      font-weight: 600;
      display: block;
      position: relative;
      padding-left: 25px;

      i {
        font-size: 18px;
        padding-right: 5px;
        position: absolute;
        left: 0;
        top: 1px;
      }
    }

    .userProfile p {
      margin-bottom: 5px;
    }

    .user-profile-role {
      font-size: 11px;
      display: block;
      padding: 0;
      color: #0b2540;
      text-transform: capitalize;
      font-weight: normal;
      cursor: text;

      &:hover {
        color: #0b2540;
      }
    }
  }

  li:nth-last-child(2) {
    padding-right: 0;
  }
}

.login_sec.user-popover.active {
  ul {
    top: 50px;
    visibility: visible;
    opacity: 1;
  }
}

.userProfile {
  position: relative;
  padding-left: 50px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: $clr_grey;
    position: absolute;
    top: 0;
    left: -10px;
  }

  span {
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 5px;
    color: #b0b5bd;
    display: block;
  }

  h4 {
    font-size: $font-size-12;
    color: #212529;
    margin-bottom: 5px;
  }

  p {
    font-size: 11px;
    color: #0b2540;
  }
}

.body-overlay {
  height: 100%;
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  background: #ffffff87 0% 0% no-repeat padding-box;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  transition: all 0.5s ease-in-out;
  width: 100%;
  opacity: 1;
}

.nav_sidebar {
  // width: 11%;
  width: 100%;
  z-index: 111;
  // background: $clr_darkblue;
  // padding: 10px 8px;
  box-shadow: 0 0 25px #0b254030;
  height: 100%;
  position: fixed;
  top: 60px;
  left: 0;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-color: white;
  @include transition(all 0.5s ease-in-out);
  max-width: 88px;
  // @media (min-width: 1366px) {
  //   max-width: 88px;
  // }
  // @media only screen and (max-width: 991px) {
  //   top: 0;
  //   left: -100%;
  // }
  ul {
    li {
      a {
        display: block;
        position: relative;
        overflow: hidden;
        // &:before {
        //   content: "";
        //   border-left: 6px solid $clr_lightblue;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   opacity: 0;
        //   left: 0;
        // }
      }
    }
  }

  figure {
    a,
    div {
      display: block;
      position: relative;
      text-align: center;

      p {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        color: $clr_white;

        span {
          width: 100%;
          display: block;
          font-size: 7px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
    }

    position: absolute;
    bottom: 50px;
  }

  svg#tbr-logo {
    path {
      fill: $clr_darkblue;
    }

    rect {
      fill: $clr_green;
    }
  }

  /* &.closedSidebar {
    width: 4%;
    text-align: center;
    // left: -150px;
    .sidebar_nav {
      ul {
        li {
          a {
            padding: 15px 5px;
            label {
              display: none;
            }
            i {
              //   display: block;
              // test
            }
          }
        }
      }
    }
    + .main_content {
      width: 96%;
    }
  }
  */
}

.sidebar_nav_wrapper {
  min-height: 100%;
}

.sidebar_nav {
  width: 100%;
  // overflow-y: auto;
  padding-top: 0;

  .nav-link {
    display: flex;
  }

  li {
    width: 100%;
    display: block;
    padding-left: 0;

    &.active {
      i,
      i svg line,
      i svg path,
      i svg rect,
      i svg polyline {
        stroke: #1e81e8;
        color: #1e81e8;
      }
    }

    a i svg {
      @media only screen and (max-width: 1199px) {
        width: 20px;
        height: 20px;
        // position: absolute;
        left: 18px;
        top: 3px;
      }
    }
  }

  // Hiding the checkbox of sidebar
  input[type="checkbox"] {
    display: none;
  }

  label {
    margin-bottom: 0;
    cursor: pointer;
  }

  .nav-link {
    position: relative;
    color: $clr_lightgrey2;
    font-size: $font-size-13;
    cursor: pointer;
    overflow: hidden;
    margin: 8px 0;
    @include transition(all 0.5s ease-in-out);
  }

  i {
    font-size: 16px;
    color: $clr_white;
    width: 21px;
    height: 21px;
    // position: absolute;
    // top: 12px;
    // left: 20px;
    // @media only screen and(max-width:1199px) {
    //   left: 0;
    //   right: 0;
    // }
    svg {
      width: 21px;
      height: 21px;

      .a,
      .b,
      .c,
      .d {
        stroke: $clr_white;
        fill: none;
      }
    }

    // display: none;
  }

  [type="checkbox"] {
    &:checked {
      + {
        label {
          &:after {
            opacity: 1;
            visibility: visible;
            border-radius: 5px;
            left: 10px;
          }
        }
      }
    }
  }
}

.sidebar_nav_icons_wrapper {
  padding: 10px 0 10px 8px;
  background-color: white;
  position: relative;
  z-index: 2;
}

.sidebar_nav_icons {
  width: 60px;
  background: transparent linear-gradient(180deg, #0b2540 0%, #003770 100%) 0%
    0% no-repeat padding-box;
  border-radius: 9px;
  transition: all 0.5s ease-in-out;
  @media (min-width: 1366px) {
    width: 72px;
  }

  .material-icons-outlined {
    font-size: 21px;
  }

  .nav-item {
    border-radius: 4px;

    &:hover,
    &.active {
      a.nav-link {
        background-color: $clr_darkblue4;
        border-radius: 4px;

        .material-icons-outlined {
          color: $clr_lightblue4;
        }
      }
    }
  }

  // a.nav-link {
  //   &:hover,
  //   &.active {
  //     background-color: $clr_darkblue4;
  //     border-radius: 4px;
  //     .material-icons-outlined {
  //       color: $clr_lightblue4;
  //     }
  //   }
  // }
  .navbar-nav {
    padding: 0 8px 18px;
    @media (min-width: 1366px) {
      padding: 0 18px 18px;
    }
  }

  .nav-link {
    padding: 7px;
  }

  li {
    display: flex;
    justify-content: center;
  }
}

.sidebar_nav_labels_wrapper {
  padding: 10px 8px 10px 0;
  transform: translateX(-160px);
  transition: all 0.5s ease-in-out;
  position: relative;
  z-index: 1;
  // @media (max-width: 991px) {
  //   display: none;
  // }
}

.open_nav {
  .sidebar_nav_labels_wrapper {
    transform: translateX(0);
  }

  .sidebar_nav_icons {
    box-shadow: 0 4px 25px #0b25403d;
  }
}

.sidebar_nav_labels {
  margin-left: 9px;
  width: auto;

  .nav-link {
    padding: 8px 15px;
  }

  ul li {
    a {
      padding: 0;
    }

    &.active {
      a {
        background: $clr_solitude;
        color: $clr_white;
        border-radius: 4px;

        &:before {
          background: $clr_lightblue2;
          opacity: 0;
        }

        label {
          color: $clr_lightblue3;
          font-weight: 600;

          &:after {
            opacity: 0;
            visibility: visible;
            border-radius: 5px;
            left: 10px;
          }
        }
      }
    }

    &:hover {
      a {
        background: $clr_solitude;
        border-radius: 4px;

        &:before {
          background: $clr_solitude;
          opacity: 1;
        }
      }
    }
  }
}

.welcome_title {
  padding-bottom: 30px;
}

.left_sec {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  padding: 20px 30px;
  border-radius: 10px;
  margin-top: 35px;
  margin-bottom: 25px;

  h4 {
    margin-bottom: $font-size-18;
  }
}

.companies_content {
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;

  h5 {
    color: $clr_darkgrey;
    margin: 0;
    text-transform: uppercase;

    span {
      width: 20px;
      height: 20px;
      background: #d9dee5;
      color: $clr_white;
      font-size: $font-size-12;
      display: inline-block;
      border-radius: 100%;
      text-align: center;
      line-height: 20px;
      position: absolute;
      left: 0;
    }
  }

  p {
    font-size: $font-size-12;
  }
}

.companies_list {
  ul {
    li {
      width: 100%;
      position: relative;

      a {
        border: solid 1px #ebebeb;
        padding: 7px 10px;
        color: $clr_black;
        display: block;
        background: $clr_white;
        margin-bottom: 15px;
      }
    }

    ul {
      padding-left: 25px;
      position: relative;

      &:before {
        content: "";
        height: 100%;
        border-left: solid 1px #e7e9ed;
        display: block;
        position: absolute;
        top: -15px;
        left: 10px;
      }

      li {
        &:before {
          content: "";
          width: 15px;
          height: auto;
          border-bottom: solid 1px #e7e9ed;
          display: block;
          position: absolute;
          top: 19px;
          left: -15px;
        }
      }
    }
  }
}

.main_content_arrow {
  height: 100%;
  @include flexbox;

  img {
    width: 100px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  span {
    position: relative;
    width: 100%;
    display: block;
    height: auto;
    align-self: center;

    &:before {
      width: 60px;
      height: 50px;
      background: #f5f5f5;
      position: absolute;
      top: -5px;
      content: "";
      transform: none;
      left: 15px;
      border: solid 2px $clr_white;
    }

    &:after {
      content: "";
      border: solid 30px #f5f5f5;
      border-left: solid 30px transparent;
      border-bottom: solid 30px transparent;
      width: 45px;
      height: 45px;
      display: block;
      transform: rotate(45deg);
      right: 10px;
      position: absolute;
      top: -10px;
    }
  }

  i {
    font-size: 65px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    text-align: center;
    animation: linear infinite alternate;
    -webkit-animation: linear infinite alternate;
    animation-name: moveleft;
    -webkit-animation-name: moveleft;
    animation-duration: 5s;
    -webkit-animation-duration: 5s;
  }
}

.left_sec.companies_last {
  margin-top: 0;
}

.watchlist {
  a {
    width: 100%;
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    padding-left: 20px;

    i {
      padding: 0 5px;
    }
  }
}

.companies_last {
  .companies_list {
    height: 50%;
    margin-top: 0;

    .companies_content {
      padding-left: 0;
    }
  }

  ul {
    text-align: center;

    li {
      a {
        background: $clr_darkblue;
        color: $clr_white;
        border-radius: 6px;
        background: $clr_blue_tooltip;
      }
    }
  }
}

.checkbox_style {
  label {
    display: block;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  input[type="checkbox"] {
    display: none;
  }

  span {
    &:before {
      content: "";
      width: 18px;
      height: 18px;
      background: $clr_white;
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 5px;
      border: solid 1px #ddd;
    }

    &:after {
      content: "\f00d";
      width: 18px;
      height: 18px;
      background: $clr_lightblue;
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      font-family: fontawesome;
      border-radius: 100%;
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.5s ease-in-out);
      color: $clr_white;
      line-height: 18px;
    }
  }

  [type="checkbox"] {
    &:checked {
      + {
        span {
          &:after {
            opacity: 1;
            visibility: visible;
            border-radius: 5px;
            right: 10px;
          }
        }
      }
    }
  }
}

.skip_btns {
  margin-bottom: 15px;

  > a {
    padding: 0 25px;
    max-width: 120px;
    line-height: 32px;
    width: 100%;
    color: $clr_lightblue;
    @include transition(all 0.4s ease-in-out);

    &:hover {
      color: $clr_blue_tooltip;
    }
  }

  > a.btn {
    height: 34px;
    background: $clr_lightblue;
    border-color: $clr_lightblue;
    color: $clr_white;

    &:hover {
      background: $clr_white;
      color: $clr_lightblue;
    }
  }
}

.comapnay_tbl {
  h1 {
    margin-bottom: 20px;
    @media only screen and (max-width: 1199px) {
      margin-bottom: 10px;
      padding-top: 15px;
    }
  }

  > .row {
    margin-bottom: 20px;
  }

  .form-control {
    width: 75px;
    display: inline-block;
    // height: calc(1.4em + .75rem + 2px);
    // height: calc(1.2em + 0.8rem + 1px);
    font-size: $font-size-12;
  }

  .btn {
    padding: 0.3rem 0.75rem;
    height: 30px;
    line-height: 0.5;
    font-size: $font-size-12;
    box-shadow: none !important;
  }

  .companies_search {
    margin-bottom: 10px;
  }
}

.green-btn-group {
  .btn {
    padding: 8px 16px;
    height: 35px;
    font-size: 14px;
    background-color: $clr_lightgrey3;
    color: $clr_lightgrey2;

    &:hover {
      background-color: $clr_lightgrey3;
      color: $clr_lightgrey2;
    }

    border: 0;
    border-radius: 3px;

    & + .btn {
      margin-left: 8px;
    }

    &.btn-active {
      background-color: $clr_lightgreen;
      color: $clr_green3;
      font-weight: 600;

      &:hover {
        background-color: $clr_lightgreen;
        color: $clr_green3;
      }
    }
  }
}

.table-responsive {
  // padding: 0 15px;
  overflow-x: unset !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.table-responsive2 {
  max-height: 500px;
  overflow-x: auto !important;
  @media only screen and (max-width: 1199px) {
    overflow-y: auto !important;
  }
}

.comapany_table {
  thead {
    @media only screen and (max-width: 1199px) {
      // background: $clr_lightblue;
    }

    tr {
      th {
        // hiding below changes for beta release
        // @media only screen and (max-width: 1199px) {
        //   &:not(:nth-child(1)) {
        //     &:not(:nth-child(2)) {
        //       &:not(:nth-child(3)) {
        //         border-right: solid 1px $clr_white;
        //       }
        //     }
        //   }
        // }
        @media only screen and (max-width: 1199px) {
          &:not(:nth-child(1)) {
            border-right: solid 1px $clr_white;
          }
        }

        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          // white-space: nowrap;
          min-width: 150px;
          max-width: 150px;
          width: 150px;
          white-space: normal !important;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          @media only screen and (min-width: 769px) {
            // left: 0;
            // hiding below changes for beta release
            // position: sticky !important;
            // z-index: 8;
            // top: 0;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        width: 150px;

        &.w80 {
          min-width: 100px;
          white-space: normal !important;
          word-wrap: normal;
        }

        i {
          &.allowed-access {
            color: $clr_white;
            background-color: #5cb85c;
            font-size: 9px;
            padding: 3px;
            border-radius: 50%;
          }

          &.not-allowed-access {
            // color: #ffc107;
            svg {
              width: 16px;
              height: 16px;

              .a {
                stroke: none;
                fill: #99a0aa;
              }
            }
          }

          cursor: pointer;
        }

        text-align: center;
        // hiding below changes for beta release
        // &:not(:nth-child(1)) {
        //   &:not(:nth-child(2)) {
        //     &:not(:nth-child(3)) {
        //       border-right: solid 1px #dee2e8;
        //     }
        //   }
        // }
        &:not(:nth-child(1)) {
          border-right: solid 1px #dee2e8;
        }

        &:nth-child(2) {
          text-align: left;
        }

        .nav-link {
          color: $clr_lightblue;
          text-align: left;
          padding: 0;
          font-size: $font-size-12;
          cursor: pointer;

          label {
            margin: 0;
            cursor: pointer;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          white-space: nowrap;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          @media only screen and (min-width: 769px) {
            left: 0;
            // hiding below changes for beta release
            // position: sticky !important;
            // z-index: 7;
            label {
              font-weight: 600;
            }
          }
        }
      }

      &:first-child {
        td {
          &:not(:nth-child(1)) {
            &:not(:nth-child(2)) {
              &:not(:nth-child(3)) {
                &:not(:nth-child(4)) {
                  border-top: solid 1px #eef0f3;
                }
              }
            }
          }
        }
      }

      &:last-child {
        td {
          &:not(:nth-child(1)) {
            &:not(:nth-child(2)) {
              &:not(:nth-child(3)) {
                &:not(:nth-child(4)) {
                  border-bottom: solid 1px #eef0f3;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dataTable {
  display: inline-table;
  @media only screen and (max-width: 1199px) {
    display: block;
    width: 1020px;
  }

  thead {
    @media only screen and (max-width: 1199px) {
      // background: $clr_lightblue;
    }

    tr {
      th {
        color: $clr_grey;
        font-size: $font-size-12;
        font-weight: 700;
        padding: 8px 5px;
        text-align: center;
        position: sticky !important;
        left: 0;
        z-index: 2;
        background-color: $clr_white;
        top: 0;
        white-space: nowrap;
        @media only screen and (max-width: 1199px) {
          font-size: 12px;
          color: $clr_grey;
        }

        &:nth-child(1) {
          left: 0;
          position: sticky !important;
          z-index: 8;
          top: 0;
        }

        &.default:after {
          font: 12px FontAwesome;
          content: "\2003\f0dc";
        }

        &.asc:after {
          font: 12px FontAwesome;
          content: "\2003\f0de";
          vertical-align: bottom;
        }

        &.desc:after {
          font: 12px FontAwesome;
          content: "\2003\f0dd";
          vertical-align: top;
        }
      }
    }
  }

  tbody {
    tr {
      td:first-child {
      }

      td {
        font-size: $font-size-12;

        .nav-link {
          label {
            cursor: pointer;
          }
        }

        &.company_name {
          min-width: 150px;
          white-space: normal;
          word-wrap: normal;
          text-transform: capitalize;
        }

        @media only screen and (max-width: 1199px) {
          font-size: 12px;
        }
        padding: 8px 5px;

        &:nth-child(1) {
          color: $clr_lightblue;
          text-align: left;
        }

        &:nth-child(1) {
          left: 0;
          position: sticky !important;
          z-index: 7;

          label {
            font-weight: 500;
          }
        }
      }

      &:nth-child(odd) {
        td {
          background: #f7f7f7;
        }
      }

      &:nth-child(even) {
        td {
          background: $clr_white;
        }
      }
    }
  }

  thead {
    tr {
      th {
        &.w80 {
          min-width: 100px;
          white-space: normal;
          word-wrap: normal;
        }

        &.company_name {
          min-width: 150px;
          white-space: normal !important;
          word-wrap: normal;
        }
      }
    }
  }
}

.pagenations {
  .page-link {
    padding: 0.3rem 0.45rem;
    color: #2da5c8;
    font-size: $font-size-12;
    cursor: pointer;
  }
}

.companies_search {
  @media only screen and (max-width: 992px) {
    margin-bottom: 15px;
  }

  input[type="text"] {
    height: 30px;
  }

  .input-group {
    &::-webkit-input-placeholder {
      color: #efefef;
      opacity: 0.75;
    }

    &:-ms-input-placeholder {
      color: #efefef;
      opacity: 0.75;
    }

    &::placeholder {
      color: #efefef;
      opacity: 0.75;
    }

    input::placeholder {
      color: $clr_grey;
      // opacity: 0.35 !important;
    }
  }
}

.watchlist_search {
  width: 100%;
  max-width: 400px;
  @include flexbox;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  @media only screen and (max-width: 992px) {
    justify-content: flex-end;
  }

  .input-group {
    width: auto;
    color: $clr_lightblue;

    input {
      width: 100%;
      max-width: 140px;
    }

    &::-webkit-input-placeholder {
      color: $clr_lightblue;
    }

    &:-ms-input-placeholder {
      color: $clr_lightblue;
    }

    &::placeholder {
      color: $clr_lightblue;
    }

    .btn-default {
      line-height: 13px;
      text-align: left;
    }
  }

  .manage_cog {
    width: auto;
    color: $clr_lightblue;
  }

  .btn-default {
    // width: 150px;
    max-height: 30px;
    text-align: left;
  }

  button {
    border-radius: 0;
    color: $clr_white;
    background-color: #6c757d;
    outline: 0;
    border: solid 1px #ddd;
    // box-shadow: 0 0 4px #ddd;
  }

  .btn-active {
    background: $clr_white;
    color: $clr_lightblue;
    font-size: $font-size-12;
  }

  .btn {
    &:hover {
      color: $clr_white;
      //   background: $clr_white;
      //   color: $clr_lightblue;
      //   border-color: #6c757d;
    }
  }
}

.manage_cog {
  h6 {
    a {
      color: $clr_lightblue;
      margin: 0;
      @media only screen and (max-width: 480px) {
        text-align: center;
        display: block;
      }

      i {
        font-size: $font-size-18;
        padding-right: 3px;
      }
    }
  }
}

.sorttable {
  @include flexbox;
  justify-content: flex-end;
  align-items: center;

  a {
    padding-right: 15px;
    font-size: $font-size-18;
    color: $clr_lightblue;
  }

  // span {
  //   padding-right: 15px;
  //   font-size: $font-size-18;
  //   color: $clr_lightblue;
  //   cursor: pointer;
  // }
}

.company_sorting {
  position: relative;

  label {
    padding-right: 10px;
    color: $clr_grey;
  }

  select {
    padding: 6px 8px;
  }

  &:after {
    content: "";
    // height: calc(100% - 4px);
    // height: -webkit-calc(100% - 4px);
    // height: -moz-calc(100% - 4px);
    // height: -ms-calc(100% - 4px);
    // height: -o-calc(100% - 4px);

    @include calc(height, "100% - 4px");
    position: absolute;
    top: 0;
    right: 20px;
    border-left: solid 1px #ced4da;
    display: block;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      right: 30px;
    }
    @supports (-ms-ime-align: auto) {
      right: 30px;
    }
  }
}

.pagination {
  .page-item.active {
    .page-link {
      background: #9ea2a2;
      color: $clr_white;
      border-color: #9ea2a2;
    }
  }
}

.dashboard_main {
  background: #f4f8fd;
}

.financial_data {
  width: 100%;
  display: block;
  padding: 0;
  margin: 0;

  .watchlist_search {
    max-width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.financial_data_left {
  width: 100%;
  display: block;
  padding: $font-size-18;
  box-shadow: 0 0 4px #f7f7f7;
  background: $clr_white;

  .companies_search {
    a {
      display: block;
      width: 100%;
      color: $clr_lightblue;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.dashboard_items {
  position: relative;
  padding-right: 0;
  @include flexbox;
  justify-content: flex-start;

  article {
    position: relative;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-top: 20px;

    figure {
      + {
        .dashboard_details {
          padding-right: 150px;
        }
      }
    }

    .dashboard_details {
      padding-right: 30px;
    }
  }

  time {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    line-height: normal;
    text-align: center;
    position: relative;
    z-index: 1;
    right: 0;
    left: 30px;
    margin: 0 auto;

    small {
      background: $clr_white;
      padding: 0 20px;
      color: #ccc;
      margin: 0 auto;
      margin-right: 40px;
    }

    &:after {
      content: "";
      width: 100%;
      border-bottom: dashed 1px #cccccc;
      position: absolute;
      top: $font-size-12;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: -1;
    }
  }
}

.quaters_list {
  width: 50px;
  flex: 0 0 8%;
  max-width: 8%;
  position: absolute;
  left: 0;
  //   height: calc(100% - 10px);
  @include calc(height, "100% - 10px");
  z-index: 1;
  overflow-y: auto;

  li {
    font-size: $font-size-12;
    color: #ccc;
    text-align: center;

    a {
      font-size: 12px;
      padding-bottom: 8px;
      display: block;
      color: $clr_lightblue;
      font-weight: 500;
    }

    &:last-child {
      border-bottom: solid 2px #c1bfbf;
    }
  }

  .mCSB_inside {
    > .mCSB_container {
      margin-right: 15px;
    }
  }
}

.dashboard_main_content {
  flex: 0 0 92%;
  max-width: 92%;
  overflow: hidden;
  padding-left: 40px;
}

.dashboard_content {
  &:nth-of-type(3n + 0) {
    &:after {
      display: none;
    }
  }

  &:after {
    content: "";
    // width: calc(100% - 30px);
    @include calc(width, "100% - 30px");
    height: 1px;
    background: #ccc;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  em {
    font-size: 10px;
    margin-bottom: 3px;
    display: block;
    color: #ccc;

    span {
      background: green;
      padding: 2px 5px;
      color: $clr_white;
      display: inline-block;
    }

    span.status_insight {
      background: #00aa5d;
    }

    span.status_financial {
      background: #03653e;
    }
  }

  figure {
    width: 90px;
    height: 70px;
    position: absolute;
    top: 40px;
    right: 40px;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .three_dots {
    position: absolute;
    right: 0;
    font-size: 12px;
    top: 35px;
  }

  h5 {
    color: $clr_lightblue;
    text-transform: uppercase;
    margin: 0;
    font-size: 15px;
    padding-bottom: 3px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p {
    text-align: justify;
    padding: 0;
    margin: 0;
    line-height: 17px;
    font-size: $font-size-12;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.webinors_slider {
  margin-bottom: 30px;

  figure {
    position: relative;
    padding-bottom: 42%;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
    }
  }

  figcaption {
    width: 100%;
    max-width: 320px;
    position: absolute;
    left: 10px;
    top: 20px;
    line-height: 18px;
    color: $clr_white;
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    @include transition(all 0.4s ease-in-out);
    transform: rotate(-45deg) scaleX(-1);
    -webkit-transform: rotate(-45deg) scaleX(-1);
    -moz-transform: rotate(-45deg) scaleX(-1);
    -ms-transform: rotate(-45deg) scaleX(-1);
    -o-transform: rotate(-45deg) scaleX(-1);
    opacity: 0;
  }

  ul {
    li.active {
      figcaption {
        transform: none;
        opacity: 1;
        @include transition(all 0.4s ease-in-out);
      }
    }
  }

  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      opacity: 1;
    }

    li.active {
      opacity: 0.5;
    }
  }
}

.mywatch_listbox {
  background: $clr_white;
  width: 100%;
  display: block;
  box-shadow: 0 0 4px #f7f7f7;
  padding: 30px 0;
}

.mylist_edit {
  ul {
    @include flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 25px;

    li {
      color: $clr_grey;
      font-size: 16px;
      font-weight: 600;

      &:first-child {
        flex: 0 0 40%;
        max-width: 40%;
      }

      &:not(:first-child) {
        flex: 0 0 60%;
        max-width: 60%;
        color: #ccc;
        font-size: $font-size-12;
        font-style: italic;
        padding-right: 20px;
        font-weight: normal;
      }

      &:last-child {
        position: absolute;
        right: 20px;
      }

      a {
        color: $clr_lightblue;
        font-weight: 500;
      }
    }
  }
}

.com_seg {
  @include flexbox;
  flex-wrap: wrap;
  justify-content: space-between;

  h6 {
    margin-bottom: 15px;
    font-weight: normal;
  }

  aside {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 30px;

    &:first-child {
      border-right: solid 1px #ddd;
    }
  }

  ul {
    li {
      a {
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 10px;
        display: block;
        color: $clr_lightblue;
      }

      &:hover {
        a {
          color: $clr_darkblue;
        }
      }
    }
  }
}

.mywatch_listbox2 {
  .com_seg {
    aside {
      flex: 0 0 47%;
      max-width: 47%;
      padding: 25px;
      background: $clr_white;

      h6 {
        text-transform: uppercase;
      }

      &:first-child {
        border: 0;
      }
    }

    ul {
      li {
        a {
          font-size: 12px;

          span {
            font-size: 10px;
            color: #ccc;
          }
        }
      }
    }
  }
}

.nav_sidebar2 {
  // width: 10%;
  width: 100%;
  max-width: 240px;
  background: $clr_white;
  //   height: calc(100% - 50px);
  @include calc(height, "100% - 50px");
  position: fixed;
  top: 56px;
  left: 105px;
  overflow-y: auto;
  padding: 0 5px;
  padding-top: 40px;
  border-right: solid 7px #e6ebf2;
  padding-bottom: 40px;
  @include transition(all 0.8s ease-in-out);

  + {
    .main_content {
      width: 100%;
      padding-left: 400px;
      @media only screen and (max-width: 1199px) {
        padding-left: 400px;
      }
      @media only screen and (max-width: 991px) {
        padding-left: 280px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0;
      }
    }

    .board_details_main_content {
      padding-left: 350px;
      @media only screen and (max-width: 1199px) {
        padding-left: 150px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    // width: 20%;
    top: 50px;
    left: 92px;
    z-index: 1;
  }
  @media only screen and (max-width: 991px) {
    left: 15px;
  }
  @media only screen and (max-width: 767px) {
    left: -100%;
  }
}

.nav_sidebar3 {
  // width: 10%;
  width: 100%;
  max-width: 240px;
  background: $clr_white;
  //   height: calc(100% - 50px);
  @include calc(height, "100% - 150px");
  position: fixed;
  top: 170px;
  left: 240px;
  overflow-y: auto;
  padding: 0;
  padding-top: 15px;
  border-right: solid 7px #e6ebf2;
  padding-bottom: 40px;
  @include transition(all 0.8s ease-in-out);

  + {
    .main_content {
      width: 100%;
      padding-left: 400px;
      @media only screen and (max-width: 1199px) {
        padding-left: 400px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0;
      }
    }

    .board_details_main_content {
      padding-left: 350px;
      @media only screen and (max-width: 1199px) {
        padding-left: 150px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    // width: 20%;
    top: 150px;
    left: 92px;
    z-index: 1;
  }
  @media only screen and (max-width: 767px) {
    left: -100%;
  }
}

.sidebar_list2 {
  > ul {
    margin-bottom: 15px;

    > li {
      padding: 8px 15px;
      // position: relative;
      // padding-left: 5px;
      color: #1286c6;
      font-size: 12px;
      text-transform: capitalize;
      cursor: pointer;
      margin-bottom: 0;
      line-height: 12px;
      // cursor: pointer;
      &:before {
        top: 0;
        opacity: 1;
      }
    }

    > li.active {
      // background-color: #074a6e;
      font-weight: 900;
      font-size: 14px;
      // color: white;
      &:before {
        top: 0;
        opacity: 1;
      }
    }
  }
}

.sidebar_list {
  > ul {
    margin-bottom: 15px;

    > li {
      &:before {
        content: "";
        // width: 5px;
        // height: 30px;
        // position: absolute;
        // left: -5px;
        // top: -10px;
        // background: $clr_grey;
        // opacity: 0;
        // transition: all 0.3s ease-in-out;
      }

      &:hover {
        &:before {
          top: 0;
          opacity: 1;
        }
      }
    }

    > li.active {
      &:before {
        top: 0;
        opacity: 1;
      }
    }
  }
}

.checkbox_list {
  input[type="checkbox"] {
    // display: none;
    &:checked {
      + {
        label {
          &:after {
            opacity: 1;
            height: 8px;
          }
        }
      }
    }
  }

  span.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 125px;
    display: inline-block;
  }

  // input[type="checkbox"]::after {
  //   background-color: red;
  // }

  label {
    position: relative;
    padding-left: 5px;
    color: #1286c6;
    font-size: 12px;
    text-transform: capitalize;
    // display: block;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 12px;
    // &:before {
    //   content: "";
    //   width: $font-size-12;
    //   height: $font-size-12;
    //   background: $clr_white;
    //   border: solid 1px #babfc6;
    //   display: block;
    //   position: absolute;
    //   left: 5px;
    //   top: 4px;
    // }
    // &:after {
    //   content: "";
    //   display: block;
    //   width: 5px;
    //   height: 0;
    //   border: solid #babfc6;
    //   border-width: 0 1.5px 1.5px 0;
    //   //   transform: rotate(45deg);
    //   @include transform(rotate(#{45}deg));
    //   position: absolute;
    //   top: 5px;
    //   left: 9px;
    //   opacity: 0;
    //   //   transition: height 0.3s ease-in-out;
    //   @include transition(height 0.5s ease-in-out);
    // }
  }

  li {
    ul {
      padding-left: 10px;
    }
  }

  > li {
    position: relative;
    @include transition(all 0.3s ease-in-out);
  }
}

.company_grph {
  margin-bottom: 10px;
}

.company_details {
  > aside {
    &:not(:last-child) {
      border-right: solid 7px #e6ebf2;
    }
  }
}

.dummy {
  background: red;
  width: 100%;
  height: 100%;
}

.list_style {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: decimal;
  padding-left: 20px;
  color: #d9dee5;

  li {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    display: list-item;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      width: 100%;
      display: block;
      font-size: $font-size-12;
      color: #d9dee5;
    }
  }
}

.watch_follow_anchor {
  a {
    color: $clr_lightblue;
    font-weight: 600;
    font-size: $font-size-12;
  }
}

.author_sec {
  ul {
    li {
      width: 100%;
      position: relative;
      padding-left: 26px;
      line-height: 1;

      .icon {
        position: absolute;
        left: 0;
        top: 7px;
        padding: 0;
        border-radius: 100%;
        color: $clr_grey;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
      }

      em {
        font-style: normal;
        color: $clr_grey;
        font-size: $font-size-12;
        line-height: normal;
        display: block;
      }

      p {
        width: 100%;
        display: inline;
        // @include flexbox;
        // flex-wrap: wrap;
        // justify-content: space-between;
        color: $clr_lightblue;
        cursor: pointer;

        a {
          font-size: 14px;
          text-transform: capitalize;
          line-height: 16px;
          color: $clr_lightblue2;
        }

        margin: 0;

        &:hover {
          .copy-icon {
            visibility: visible;
          }
        }

        .copy-icon {
          margin-left: 3px;
          font-size: 14px;
          visibility: hidden;
          margin-right: 6px;
          vertical-align: bottom;
        }
      }
    }
  }
}

.company_ttl {
  @media only screen and (max-width: 992px) {
    // margin-top: 15px;
  }

  h2 {
    // margin-bottom: 15px;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;

    strong {
      font-size: 15px;
    }
  }
}

.follow_links {
  @include flexbox;
  flex-wrap: wrap;
  //   justify-content: space-around;
  li {
    text-align: center;
    border: solid 1px $clr_grey;
    margin-right: 10px;

    span {
      text-transform: uppercase;
      font-size: 11px;
      display: block;
      padding: 3px 5px;
      color: $clr_grey;
      position: relative;
      font-weight: bold;

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: $clr_grey;
        position: absolute;
        left: 0;
        top: 0;
        transform: skew(10deg, 10deg);
        opacity: 0;
        z-index: -1;
        // @include transition(all 0.5s ease-in-out);
      }
    }

    &:hover {
      border-color: $clr_grey;

      span.not-allowed-access,
      span.allowed-access,
      span {
        &:after {
          opacity: 1;
          transform: none;
        }

        cursor: pointer;
        color: $clr_white;
      }
    }

    &.allowed-access {
      border-color: #5cb85c;

      span {
        color: #5cb85c;

        &:after {
          background-color: #5cb85c;
        }
      }
    }

    &.not-allowed-access {
      border-color: #ffc107;

      span {
        color: #ffc107;

        &:after {
          background-color: #ffc107;
        }
      }
    }

    i {
      &.allowed-access {
        color: $clr_white;
        background-color: #b4bcca;
        font-size: 9px;
        padding: 3px;
        border-radius: 50%;
      }

      &.not-allowed-access {
        // color: #ffc107;
        svg {
          width: 15px;
          height: 15px;

          .a {
            stroke: none;
            fill: #99a0aa;
          }

          .b {
            stroke-width: 2.25px;
          }
        }
      }

      cursor: pointer;
    }
  }

  li.active {
    span {
      &:after {
        opacity: 1;
        transform: none;
      }

      color: $clr_white;
    }
  }
}

.company_graph_box {
  width: 100%;
  height: 100%;
  background: none;
  -webkit-box-shadow: 0 0 4px #bbb6b6;
  -moz-box-shadow: 0 0 4px #bbb6b6;
  -ms-box-shadow: 0 0 4px #bbb6b6;
  -o-box-shadow: 0 0 4px #bbb6b6;
  box-shadow: 0 0 4px #bbb6b6;
  text-align: center;

  h6 {
    text-align: left;
    padding: 15px 0 0 15px;
  }
}

.radarChart {
  svg {
    width: auto;
    height: 200px;
  }

  display: inline-block;
  width: 100%;
}

.radar_bx {
  @include flexbox;
  flex-wrap: wrap;

  ul {
    display: block;
    align-self: center;
    width: 100%;
    margin-bottom: 15px;
    @include flexbox;
    justify-content: space-between;

    li {
      width: 100%;
      display: block;
      padding: 0 5px;

      a {
        width: 100%;
        display: block;
        font-size: 11px;
        color: $clr_white;
        padding: 3px 8px;
        margin-bottom: 3px;
        border-radius: 6px;
      }
    }
  }
}

.quarterly_final {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-box-shadow: 0 0 4px #bbb6b6;
  -moz-box-shadow: 0 0 4px #bbb6b6;
  -ms-box-shadow: 0 0 4px #bbb6b6;
  -o-box-shadow: 0 0 4px #bbb6b6;
  box-shadow: 0 0 4px #bbb6b6;
  padding: 15px;

  h4 {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 15px;

    span {
      font-size: $font-size-12;
    }
  }

  table {
    tr {
      td {
        padding: 0 0 5px;

        &:first-child {
          width: 50%;
        }
      }
    }
  }
}

.tbr_position {
  width: 100%;
  float: left;

  .sub_category_heading {
    background-color: $clr_solitude2;
    padding: 7px 10px;
    border-radius: 3px;
    color: $clr_black2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .heading {
      font-size: $font-size-18;
      font-weight: 600;
      color: $clr_black2;
      text-transform: capitalize;
    }

    .published-date {
      text-transform: uppercase;
      margin-right: 30px;
    }

    .add_to_board_box {
      padding: 2px;
      border-radius: 4px;
      background-color: $clr_lightblue2;
      color: $clr_white;
      cursor: pointer;
      height: 26px;
      width: 26px;

      .material-icons-outlined {
        font-size: 20px;
      }
    }
  }

  .sub_category_heading_title {
    font-size: $font-size-18;
    font-weight: 600;
  }

  h3 {
    // font-size: 16px;
    span {
      font-size: 10px;
    }

    font-weight: 600;
  }

  h4 {
    width: 100%;
    position: relative;
    margin-bottom: 5px;
    font-size: $font-size-14;
  }

  h5 {
    text-transform: uppercase;
    font-weight: 600;
  }

  h3,
  h5 {
    &.text_icon {
      @include flexbox;
      align-content: center;
      align-items: center;
      font-weight: normal;

      i {
        svg {
          width: 30px;
          height: 30px;
        }
      }

      p {
        margin-right: 12px;
        font-weight: 600;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .list_style {
    list-style: none;
    padding-left: 0;

    li {
      background: #d9dee5;
    }
  }

  img.content_image {
    width: 100%;
    @media only screen and (max-width: 992px) {
      width: 100% !important;
    }
  }

  .row {
    font-size: $font-size-12;
    margin-bottom: 20px;
  }
}

.desipte_sec {
  position: relative;

  h4 {
    color: $clr_lightblue2;
    border: none;
    font-weight: 600;
    font-size: $font-size-h4;
  }
}

.add_to_board {
  float: right;

  > span.open_Onboard {
    width: 30px;
    text-align: right;
    height: 100%;
    font-size: 14px;
    color: $clr_black;
    float: right;
    cursor: pointer;
  }

  .open_Onboard {
    i.dots-icon,
    .icon {
      @include transition(all 0.5s ease-in-out);
    }
  }
}

.add_to_board.active {
  // .open_Onboard {
  //   i.dots-icon {
  //     transform: rotate(90deg);
  //     -webkit-transform: rotate(90deg);
  //     -moz-transform: rotate(90deg);
  //     -ms-transform: rotate(90deg);
  //     -o-transform: rotate(90deg);
  //   }
  // }
  &.boards-add-to-board {
    .open_Onboard {
      i.dots-icon,
      .icon {
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
      }
    }

    .add_to_board_box {
      top: 38px;
      right: 20px;
      opacity: 1;
      visibility: visible;
      display: block;
      width: 150px;
      height: auto;
      background-color: $clr_white;
      padding: 10px 12px 4px;
      @include transition(all 0.3s ease-in-out);
      z-index: 1;

      .action {
        margin-bottom: 6px;
      }
    }
  }

  .add_to_board_box {
    top: 20px;
    right: 0;
    opacity: 1;
    visibility: visible;
    display: block;
    @include transition(all 0.3s ease-in-out);
    z-index: 1;
  }
}

.add_to_board_box {
  // width: 130px;
  // background: $clr_white;
  // position: absolute;
  z-index: 1;
  // padding: 10px 15px;
  // box-shadow: 0 0 4px #ccc;
  // opacity: 0;
  @include transition(all 0.3s ease-in-out);
  // visibility: hidden;
  // display: none;
  // > span.action {
  //   display: block;
  //   width: 100%;
  //   margin-bottom: 2px;
  //   color: $clr_lightblue;
  //   cursor: pointer;
  //   font-size: $font-size-11;
  //   text-align: left;
  // }
}

.boards-add-to-board {
  .add_to_board_box {
    width: 130px;
    background: $clr_white;
    position: absolute;
    z-index: 1;
    padding: 10px 15px;
    box-shadow: 0 0 4px #ccc;
    opacity: 0;
    @include transition(all 0.3s ease-in-out);
    visibility: hidden;
    display: none;

    > span.action {
      display: block;
      width: 100%;
      margin-bottom: 2px;
      color: $clr_lightblue;
      cursor: pointer;
      font-size: $font-size-11;
      text-align: left;
    }
  }
}

.downloads_file {
  display: block;
  margin-top: 25px;

  p {
    display: block;
    margin: 0;
    padding: 0;
  }

  a {
    i.fa-file-pdf-o {
      color: red;
    }

    i.fa-file-excel-o {
      color: green;
    }

    i.fa-sticky-note {
      color: green;
    }

    margin: 0 5px;
    float: left;
  }
}

.position-graph {
  width: 100%;
  height: 100%;
}

#positionChart {
  height: 300px;
}

#chartdiv {
  width: 100%;
  height: 100%;
}

.amcharts-g2 {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

.amcharts-graph-column-front {
  @include transition(all 0.3s ease-in-out);

  &:hover {
    fill: #496375;
    stroke: #496375;
    @include transition(all 0.3s ease-in-out);
  }
}

.amcharts-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.login_page {
  height: 100%;

  main {
    height: 100%;
  }

  > main {
    > .container-fluid {
      height: 100%;

      > .row {
        height: 100%;
      }
    }
  }
}

.login_left {
  width: 100%;
  height: 100%;

  figure {
    height: 100%;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      position: static;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: rgba(255, 255, 255, 0.4);
      left: 0;
    }
  }

  a {
    position: absolute;
    top: 50px;
    left: 50px;
    display: block;
    text-align: center;

    span {
      display: block;
      color: #003366;
      text-transform: uppercase;
      font-size: 10px;
      font-family: $font-family;
      font-weight: 600;
    }
  }
}

.login_right {
  figure {
    height: 100%;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      position: static;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  width: 100%;
  height: 100%;
  background: #0a2e53;
  text-align: center;

  form {
    width: 100%;
    max-width: 360px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    .login_text_span {
      color: var(--color-gray-10, #fff);
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 25px;
    }

    .invalid_email_div {
      display: flex;
      flex-direction: column;
      gap: 25px;
      align-items: center;
      .span_1 {
        color: var(--color-gray-10, #fff);
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .span_2 {
        color: #cbcbcb;
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.217px;
      }
      .btn_1 {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        background: #007bff;
        color: var(--color-gray-10, #fff);
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
      }
      .btn_1:focus {
        border: transparent !important;
        box-shadow: none !important;
        background-color: rgb(7, 84, 179);
      }
      .btn_2:focus {
        border: transparent !important;
        box-shadow: none !important;
      }
      .btn_2 {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        background: var(--color-gray-10, #fff);
        color: #000;
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
      }
    }

    .sso_div {
      display: flex;
      flex-direction: column;
      gap: 20px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
      span {
        color: var(--color-gray-10, #fff);
        text-align: center;
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      img {
        border-radius: 100px;
        background: var(--color-gray-10, #fff);
        width: 44px;
        height: 44px;
        padding: 5px;
        cursor: pointer;
      }
      img:hover {
        background: #3f6596;
      }
    }

    a {
      &:hover {
        color: $clr_white;
      }

      img {
        width: 100%;
        max-width: 300px;
        display: block;
        margin: 0 auto 20px;
      }
    }

    label {
      display: none;
    }

    .form-group {
      margin-bottom: 50px;

      button {
        width: 100%;
        max-width: 150px;
        margin-bottom: 30px;
      }

      a {
        display: inline-block;
        font-size: 12px;
      }
    }
  }
}

.navbar-brand {
  #tbr-logo {
    width: 80px;
    // height: 46px;
    transition: all 0.5s ease-in-out;
    fill: $clr_white;
  }
}

svg#tbr-logo {
  path {
    // fill: #003366;
    fill: $clr_darkblue;
  }

  rect {
    fill: $clr_green;
    // -webkit-animation: colorchange 5s infinite;
    // -moz-animation:    colorchange 5s infinite;
    // -o-animation:      colorchange 5s infinite;
    // animation:         colorchange 5s infinite;
  }

  @-webkit-keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
  @-moz-keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
  @-o-keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
  @keyframes colorchange {
    0% {
      fill: $clr_darkblue;
    }
    50% {
      fill: $clr_lightblue;
    }
    100% {
      fill: $clr_darkblue;
    }
  }
}

.login_formlogos {
  margin-bottom: 26px;
  display: block;

  .img-fluid {
    max-width: 80%;
  }
}

@media only screen and (max-width: 1199px) {
  // h3 {
  //   font-size: 16px;
  // }
}

@media only screen and (max-width: 1199px) {
  //   .nav_sidebar {
  //     width: 20%;
  //     left: -100%;
  //     z-index: 100;
  //     top: 57px;
  //     @include transition(all 0.4s ease-in-out);
  //   }
  //   .open_nav {
  //     .nav_sidebar {
  //       left: 0;
  //     }
  //     .nav_sidebar2 {
  //       left: 20%;
  //       width: 20%;
  //     }
  //   }
  header {
    // .navbar-brand {
    //   padding-left: 25px;
    // }
    form {
      .form-control {
        height: 33px;
      }

      .btn-primary-success {
        height: 33px;
        padding: 0;
      }
    }
  }
  .menu_lines {
    display: block;

    span {
      @media only screen and (max-width: 767px) {
        width: 20px;
      }
    }
  }
  .main_content {
    width: 100%;
  }
  //   .nav_sidebar2 {
  //     width: 20%;
  //     left: 0%;
  //     z-index: 1;

  //   }
  .radarChart {
    display: inline-block;
    // width: calc(100% - 90px);
    @include calc(width, "100% - 90px");
  }
  .radar_bx {
    ul {
      width: 100%;
      max-width: 90px;
      display: block;
      align-self: center;
    }
  }
  #chartdiv {
    height: 310px;
  }
  .position-grapgh {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .login_sec {
    ul {
      flex-direction: row;

      li {
        a {
          padding: 0 4px;
          font-size: 12px;
        }

        span {
          color: $clr_grey;
        }
      }
    }
  }
  .main_content {
    width: 100%;
    // padding-left: 0;
    // padding-top: 75px;
  }
  .company_details {
    > aside {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  nav {
    form.form-inline {
      position: absolute;
      top: 100%;
      right: 0;
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.4s ease-in-out);
    }
  }
  nav.open_search {
    form.form-inline {
      opacity: 1;
      visibility: visible;
      top: 40px;
    }
  }
  .watchlist_search {
    .input-group {
      margin-bottom: 15px;
      width: 100%;
      justify-content: center;
      @media only screen and (max-width: 767px) {
        justify-content: flex-end;
      }
      @media only screen and (max-width: 480px) {
        justify-content: center;
      }
    }

    .manage_cog {
      width: 100%;
      justify-content: center;
    }
  }
  .company_sorting {
    label {
      font-size: $font-size-12;
    }

    .form-control {
      width: 52px;
      padding: 0.375em 0;
    }
  }
  .sorttable {
    a {
      padding-right: 5px;
      font-size: 16px;
    }

    span {
      @media only screen and (max-width: 992px) {
        padding-right: 15px;
      }
    }
  }
  .hide-in-mobille {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .login_sec {
    ul {
      li {
        a {
          i {
            font-size: 18px;
          }
        }

        ul {
          width: 260px;
          right: -13px;
        }

        span {
          i {
            font-size: 18px;
          }

          cursor: pointer;
        }
      }
    }
  }
  .comapnay_tbl {
    .col-6 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .com_seg {
    aside {
      flex: 0 0 100%;
      max-width: 100%;
      border-right: 0 !important;
    }
  }
  .mywatch_listbox2 {
    .com_seg {
      aside {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  .login_right {
    .login_formlogos {
      margin: 0 auto 50px;
    }

    form {
      max-width: 280px;

      .form-group {
        margin-bottom: 30px;
      }
    }
  }
}

// @media only screen and (min-width:1500px) {
// 	.main_content {
// 		.col-sm-offset-right-1 {
// 			// padding-right: 8%;
// 		}
// 	}
// }
@media only screen and (max-width: 576px) {
  .company_segment_row {
    aside.d-sm-none {
      display: none;
    }
  }
  .left_sec {
    margin-top: 0;
    height: auto;
  }
  .financial_data_left {
    h1 {
      font-size: 16px;
    }
  }
  .companies_search {
    margin-bottom: 20px;
  }
  .financial_data {
    .watchlist_search {
      justify-content: center;
      align-items: center;
    }
  }
  .dashboard_content {
    figure {
      width: auto;
      height: auto;
      position: static;
      margin-bottom: 5px;
    }

    .three_dots {
      top: 15px;
    }

    &:after {
      width: calc(100% - 20px);
      @include calc(width, "100% - 20px");
    }
  }
  .dashboard_items {
    article {
      padding-right: 0;
      padding-left: 20px;

      figure {
        + {
          .dashboard_details {
            padding-right: 0;
          }
        }
      }

      .dashboard_details {
        padding-right: 0;
      }
    }

    time {
      left: 20px;
    }
  }
  .quaters_list {
    flex: 0 0 12%;
    max-width: 12%;

    .mCSB_inside {
      > .mCSB_container {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .mylist_edit {
    ul {
      li {
        font-size: 12px;

        &:not(:first-child) {
          font-size: 12px;
          flex: 0 0 100%;
          max-width: 100%;
        }

        a {
          font-size: 12px;
        }
      }
    }
  }
  .com_seg {
    ul {
      li {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .company_details {
    .col-11 {
      flex: 90%;
      max-width: 90%;
    }

    .col-1 {
      flex: 10%;
      max-width: 10%;
    }
  }
  .company_grph {
    aside {
      &:nth-child(2) {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 1399px) {
  .follow_links {
    width: 100%;
    max-width: 600px;
  }
}

@media only screen and (min-width: 1600px) {
  .radarChart {
    svg {
      height: 130px;
    }

    display: inline-block;
    // width: calc(100% - 70px);
    @include calc(width, "100% - 70px");
  }
  .radar_bx {
    ul {
      width: 100%;
      max-width: 70px;
      display: block;
      align-self: center;
    }
  }
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .bar1 {
    width: 22px;
  }
  .bar2 {
    width: 22px;
  }
  .bar3 {
    width: 22px;
  }
  .company_sorting label {
    padding-right: 5px;
    font-size: $font-size-12;
  }
  .company_sorting {
    .form-control {
      height: 30px;
    }
  }
  .comapnay_tbl h1 {
    padding-top: 15px;
  }
  .companies_search {
    .form-control {
      font-size: $font-size-12;
    }

    input[type="text"] {
      height: 30px;
    }

    .input-group {
      &::-webkit-input-placeholder {
        color: #efefef;
      }

      &:-ms-input-placeholder {
        color: #efefef;
      }

      &::placeholder {
        color: #efefef;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: 320px) {
  .companies_search {
    .btn-primary {
      height: 30px;
      line-height: 0.5;
    }
  }
}

@media only screen and (max-width: 767px) {
  // .nav_sidebar {
  //   top: 57px;
  // }
}

.disabled .page-link {
  color: grey;
  pointer-events: none;
}

.page-item.disabled {
  opacity: 0.55;
}

.page-item.disabled:hover {
  cursor: not-allowed;
}

.dataTable th {
  cursor: pointer;
}

.dataTable .no-records {
  text-align: center !important;
  color: $clr_grey !important;
}

.loading-indicator:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1053;
  opacity: 0.5;
}

.loading-indicator:after {
  content: "";
  position: fixed;
  left: 52.5%;
  top: 50%;
  z-index: 1200;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border-top: 8px solid rgba(155, 224, 240, 0.2);
  border-right: 8px solid rgba(155, 224, 240, 0.2);
  border-bottom: 8px solid rgba(155, 224, 240, 0.2);
  border-left: 8px solid $clr_lightblue;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

// .second-loading-indicator:before {
//   content: "";
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1000;
//   opacity: 0.5;
// }

// .second-loading-indicator:after {
//   content: "";
//   position: fixed;
//   left: 50%;
//   top: 50%;
//   z-index: 1200;
//   width: 150px;
//   height: 150px;
//   margin: -75px 0 0 -75px;
//   border-radius: 50%;
//   border-top: 8px solid rgba(155, 224, 240, 0.2);
//   border-right: 8px solid rgba(155, 224, 240, 0.2);
//   border-bottom: 8px solid rgba(155, 224, 240, 0.2);
//   border-left: 8px solid $clr_lightblue;
//   width: 80px;
//   height: 80px;
//   -webkit-animation: spin 1s linear infinite;
//   animation: spin 1s linear infinite;
// }

.hidden-download {
  display: none;
}

input::placeholder {
  color: $clr_grey;
  // opacity: 0.35 !important;
}

.disable {
  color: $clr_grey !important;
  cursor: not-allowed;
}

.company_desc {
  color: #212529;
}

.stock_ticker {
  font-size: $font-size-14;
}

.company_name .nav-link label {
  cursor: pointer;
}

.green {
  color: green;
}

.growth {
  font-weight: 600;
}

.growth_indicator {
  margin-left: 3px;
}

.taxonomy-page-block {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
}

.taxonomy_page {
  height: calc(100% - 50px);

  > .d-flex {
    height: 100%;
  }

  // table {
  //   tr{
  //     th:first-child, td:first-child {
  //       min-width: 402px;
  //     }
  //   }
  // }
  table {
    margin: 0;
    min-width: auto !important;
    // &:nth-of-type(2){
    //   margin-top: 49px;
    // }
    &.dx-g-bs4-table-sticky {
      z-index: 6;
      // position: fixed;
      // top: 125px;
      // left: 14px;
      // width: calc(100% - 28px);
      // @media(min-width: 992px){
      //   left: 90px;
      //   width: calc(100% - 105px);
      // }
      // @media(min-width: 1366px){
      //   left: 103px;
      //   width: calc(100% - 118px);
      // }
      .oi-arrow-thick-bottom:before {
        font-family: "Material Icons";
        content: "\e5cf";
        font-size: 20px;
      }

      .oi-arrow-thick-top:before {
        font-family: "Material Icons";
        content: "\e5ce";
        font-size: 20px;
      }
    }

    colgroup {
      col:nth-child(1) {
        width: 40% !important;
      }

      col:nth-child(2) {
        width: 60% !important;
      }
    }
  }

  .table-responsive {
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0 5px 25px #0b254024;

    border-radius: 4px;

    .oi-chevron-right:before {
      font-family: "Material Icons Round";
      content: "\e5cc";
      font-size: 20px;
    }

    .oi-chevron-bottom:before {
      font-family: "Material Icons Round";
      content: "\e5cf";
      font-size: 20px;
    }

    // .dx-g-bs4-table{
    //   table-layout: auto;
    // }
    // .list-table{
    //   tbody {
    //     tr {
    //       td {
    //         &:first-child{
    //           position: sticky;
    //           left: 0;
    //           background-color: $clr_white;
    //           z-index: 2;
    //           border-right: 0.5px solid $clr_tableborder;
    //         }
    //       }
    //     }
    //   }
    // }
    tbody {
      tr {
        td {
          font-size: 14px;
          border: none;
          position: relative;

          &:first-child {
            padding: 0.5rem 0.75rem;

            .d-inline-block ~ .dx-g-bs4-table-tree-content {
              overflow: visible;
            }
          }

          &:first-child {
            border-right: 0.5px solid $clr_tableborder;
            color: $clr_lightgrey2;
          }

          &:nth-child(2) {
            padding: 13px 16px 11px;
            color: $clr_black2;
            // border-bottom: 0.5px solid $clr_tableborder;
            // white-space: nowrap;
            // vertical-align: middle;
          }

          // > .d-flex {
          //   position: relative;
          //   z-index: 2;
          // }
          .dx-g-bs4-toggle-button {
            order: 2;
            position: absolute;
            margin-right: 0 !important;
            right: 18px;
            top: 15px;
            z-index: 4;
          }

          // span ~ .dx-g-bs4-table-tree-content {
          //   &::before {
          //     position: absolute;
          //     content: "";
          //     width: 15px;
          //     height: 1px;
          //     background: #d9d9d9;
          //     top: 50%;
          //     margin-top: -1px;
          //     left: -15px;
          //   }
          // }
          .dx-g-bs4-table-tree-content {
            order: 1;
            padding: 6px 40px 6px 6px;
            z-index: 3;
            position: relative;
            overflow: visible;
            // &::after {
            //   position: absolute;
            //   content: "";
            //   width: 1px;
            //   height: 16px;
            //   background: white;
            //   bottom: 33px;
            //   margin-top: -1px;
            //   left: 8px;
            // }
          }

          &.cell-expanded {
            .dx-g-bs4-table-tree-content {
              order: 1;
              padding: 6px 40px 6px 6px;
              // background-color: $clr_alice_blue2;
              background-color: #e4eef5;
              border-radius: 4px;
              color: $clr_darkblue5;
              z-index: 3;
              position: relative;
            }
          }

          .oi-chevron-bottom:before {
            color: $clr_darkblue5;
          }

          // &.cell-expanded {
          //   span {
          //     width: 10px;
          //     height: 10px;
          //     z-index: 2;
          //     &::before {
          //       position: absolute;
          //       content: "";
          //       width: 1px;
          //       height: 100%;
          //       background: #d9d9d9;
          //       left: 32px;
          //       bottom: 0;
          //       z-index: -1;
          //     }
          //     &:only-child {
          //     }
          //   }
          // }

          span {
            width: 0;
            height: 10px;
            z-index: 2;

            &::before {
              position: absolute;
              content: "";
              width: 1px;
              height: 100%;
              background: $clr_tableborder;
              left: 32px;
              bottom: 0;
              z-index: -1;
            }

            &:first-of-type {
              &::before {
                left: 20px;
              }
            }

            &:nth-of-type(2) {
              &::before {
                left: 44px;
              }
            }

            &:nth-of-type(3) {
              &::before {
                left: 68px;
              }
            }
          }
        }
      }
    }
  }

  .card-header {
    border: none !important;
    background: none !important;
    padding: 0 !important;
    min-height: auto;
    margin-bottom: 15px;
    position: absolute !important;
    top: 0;
    right: 63px;

    input {
      background: #ffffff 0% 0% no-repeat padding-box;
      // box-shadow: 0 0 12px #0b254014;
      border: 1px solid $clr_lightgrey_new;
      border-radius: 5px;
      padding: 6px 48px 6px 14px;
      height: 35px;
      font-size: 0.79rem;
      position: relative;
      z-index: 15;
      width: 266px !important;
    }

    .d-flex {
      display: none !important;
    }

    &::after {
      position: absolute;
      left: 224px;
      top: 0;
      margin: 4px 0;
      height: 27px;
      line-height: 27px;
      font-family: "Material Icons";
      content: "\e8b6";
      color: $clr_lightblue2;
      padding-left: 8px;
      font-size: 21px;
      border-left: 1px solid $clr_border;
      z-index: 11;
      cursor: pointer;
    }
  }

  thead {
    tr {
      th {
        background-color: $clr_lightblue3;
        color: $clr_white;
        font-size: 14px;
        font-weight: 600;
        border: none;
        padding: 14px 16px;

        .dx-g-bs4-toggle-button-hidden {
          display: none;
        }

        .text-primary {
          color: $clr_white !important;
        }

        &:first-child {
          border-top-left-radius: 3.5px;
        }

        &:nth-child(2) {
          border-top-right-radius: 3.5px;
        }
      }

      .input-group {
        .form-control {
          font-size: $font-size-12;
        }
      }
    }

    tr:nth-child(2) {
      th:nth-child(2) {
        .input-group {
          display: none;
        }
      }
    }
  }

  .btn-outline-secondary {
    background-color: #1085c6;
    border: none;
    color: white;
  }

  .list-table {
    tr:nth-of-type(odd) {
      background-color: #f9f9f9;
      // .dx-g-bs4-table-tree-content{
      //   background-color: #f7faff;
      //   border-radius: 4px;
      // }
      // td:nth-child(2){
      //   background-color: #f7faff;
      // }
    }
  }
}

.dx-g-bs4-filter-selector-item.active {
  background-color: #1085c6;
}

.dx-g-bs4-filter-selector-item {
  .dx-g-bs4-filter-selector-icon {
    background-color: #1085c6;
    color: white;
  }
}

.content-section {
  font-size: 14px;

  p {
    color: $clr_black2;
    font-size: 14px;

    img {
      width: auto !important;
    }
  }
}

.company_details {
  .content-section {
    overflow: auto;
    margin-bottom: 20px;

    ul {
      padding-inline-start: 40px;
      list-style: initial;
    }

    table {
      td,
      th {
        color: unset;
        font-size: unset;
        padding: 8px 16px;
        vertical-align: top;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    h1 {
      color: unset;
      font-weight: initial;
    }

    h2 {
      font-size: 2em;
      color: black;
    }

    h3 {
      text-transform: none;
      font-size: 1.17em;
      color: black;
    }

    h4 {
      border: none;
      background: none;
      width: auto;
      color: black;
      padding: 0;
      font-size: unset;
      font-weight: bold;
      margin: inherit;
    }

    h5 {
      color: black;
      font-size: 0.83em;
      text-transform: none;
    }

    h6 {
      font-size: 0.67em;
      color: black;
    }

    .row {
      margin: 0 !important;
      padding: 0 !important;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .news {
    .tbr_position {
      // background-color: #08417b;
      padding-top: 15px;
      padding-bottom: 15px;

      .sub_category_heading {
        // border-color: white;
        .remove-news {
          i {
            font-size: $font-size-14;
          }
        }
      }

      .desipte_sec h4 {
        background: none;
        // color: white;
        text-transform: none;
        padding-left: 0;
      }
    }
  }
}

.dynamicTableActionButton:nth-child(n + 2) {
  margin-left: 3px;
}

form.form-wrapper {
  margin: auto;

  label {
    color: #99a0aa;
    font-size: $font-size-14;

    .mandatory {
      color: #bd2130;
    }
  }

  .mandatory_red {
    color: #bd2130;
  }

  .form-control {
    font-size: $font-size-12;
  }

  .valid-tooltip,
  .invalid-tooltip {
    font-size: $font-size-12;
    float: right;
    position: relative;
    top: unset;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      top: auto;
    }
    @supports (-ms-ime-align: auto) {
      top: auto;
    }
  }

  .btn {
    font-size: $font-size-12;
  }

  .rw-widget {
    font-size: $font-size-12;

    .rw-widget-input,
    .rw-filter-input {
      box-shadow: none;
    }

    .rw-widget-container {
      border: 1px solid #ced4da;
    }
  }

  .card-header {
    padding: 0.45rem 1.25rem;
    font-size: $font-size-14;
  }

  .segment-tag {
    padding: 3px;
    margin: 3px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-flex;

    i {
      margin-left: 5px;
      cursor: pointer;
      line-height: $font-size-18;
    }
  }

  .rw-widget .rw-widget-container {
    &.invalid-tooltip-dropdown {
      border: 1px solid rgba(220, 53, 69, 0.9);
    }
  }
}

.alert {
  font-size: $font-size-12;
  padding: 0.55rem 1.15rem;
}

.modal-confirm {
  color: #636363;
  // width: 400px;
  .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
    text-align: center;
    font-size: $font-size-14;
  }

  .modal-header {
    border-bottom: none;
    position: relative;
  }

  h5 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -10px;
    width: 100%;
    color: black;
  }

  .close {
    position: absolute;
    top: -5px;
    right: -2px;
  }

  .modal-body {
    color: #000;
  }

  .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    padding: 10px 15px 25px;
    display: block;

    a {
      color: #000;
    }
  }

  .icon-box {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 9;
    text-align: center;
    border: 3px solid #f15e5e;

    &.not-allowed-access {
      border: none;
    }

    i {
      color: #f15e5e;
      font-size: 46px;
      display: inline-block;
      margin-top: 13px;

      &.not-allowed-access {
        margin-top: 0;
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;

          .a {
            stroke: none;
            fill: #99a0aa;
          }
        }
      }
    }

    &.danger {
      border-color: #ee3535;

      i {
        color: #ee3535;
      }
    }

    &.warning {
      border-color: orange;

      i {
        color: orange;
      }
    }

    &.success {
      border-color: green;

      i {
        color: green;
      }
    }
  }

  // .btn {
  //   color: #fff;
  //   border-radius: 4px;
  //   background: #60c7c1;
  //   text-decoration: none;
  //   transition: all 0.4s;
  //   line-height: normal;
  //   min-width: 120px;
  //   border: none;
  //   min-height: 40px;
  //   border-radius: 3px;
  //   margin: 0 5px;
  //   outline: none !important;
  // }
  .btn-info {
    background: #c1c1c1;

    &:hover {
      background: #a8a8a8;
    }

    &:focus {
      background: #a8a8a8;
    }
  }

  .btn-danger {
    background: $clr_red2;
    border-color: $clr_red2;

    &:hover {
      background: #ee3535;
    }

    &:focus {
      background: #ee3535;
    }
  }
}

.danger-modal {
  .modal-right-btn {
    background: $clr_red2;
    border-color: $clr_red2;

    &:hover {
      background: $clr_red3;
      border-color: $clr_red3;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: $clr_red3;
      border-color: $clr_red3;
      box-shadow: 0 0 0 0.2rem rgba(255, 49, 43, 0.5);
    }
  }
}

.success-modal {
  .modal-right-btn {
    background: $clr_green;
    border-color: $clr_green;

    &:hover {
      background: $clr_lightgreen2;
      border-color: $clr_lightgreen2;
    }

    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: $clr_lightgreen2;
      border-color: $clr_lightgreen2;
      box-shadow: 0 0 0 0.2rem rgba(38, 81, 40, 0.5);
    }
  }
}

.primary-modal {
  .modal-right-btn {
    background: $clr_lightblue2;
    border-color: $clr_lightblue2;
    color: $clr_white;

    &:hover {
      background: $clr_darkblue5;
      border-color: $clr_darkblue5;
      color: $clr_white;
    }

    &:focus {
      background: $clr_darkblue5;
      border-color: $clr_darkblue5;
      color: $clr_white;
    }
  }

  .modal-footer {
    justify-content: flex-end;
  }
}

.verify_email_success {
  color: white;
  white-space: pre-line;
}

.reset-password {
  input {
    font-size: 1rem !important;
  }

  .btn {
    font-size: 1rem !important;
  }
}

.dynamicTableActionButton {
  i {
    color: white;
  }
}

.reset_password_header {
  color: $clr_white;
}

.project_details {
  span.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 125px;
    display: inline-block;
  }

  .sub_category_heading .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .checkbox_list label {
    margin-bottom: 0;
  }
}

.tbri-custom-breadcumb {
  box-shadow: none;

  .breadcrumb {
    background: none;
    color: white;
    padding: 0.5rem 0.5rem 0.5rem 15px;

    li {
      &.breadcrumb-item {
        color: #052240;
        font-size: 0.75rem;

        a {
          color: #052240;
          text-transform: capitalize;

          &.nav-link {
            padding: 0;
            display: inline;
          }
        }

        &::before {
          color: #052240;
        }
      }

      &.active {
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}

.mb5 {
  margin-bottom: 5px !important;
}

.my-boards {
  .no-boards {
    p,
    i {
      color: #99a0aa;
      font-size: $font-size-18;
    }
  }

  .card {
    cursor: pointer;

    .actions-toggler {
      cursor: pointer;
      width: 30px;
      text-align: center;
    }
  }

  .card.active {
    .actions_board {
      opacity: 1;
      visibility: visible;
      z-index: 1;
      display: block;
    }
  }

  .actions_board {
    width: 130px;
    background: $clr_white;
    position: absolute;
    right: 0;
    z-index: 1;
    padding: 10px 10px;
    box-shadow: 0 0 10px #ccc;
    opacity: 0;
    @include transition(all 0.3s ease-in-out);
    visibility: hidden;
    display: none;

    > a {
      display: block;
      width: 100%;
      margin-bottom: 5px;
      color: $clr_lightblue;
      font-size: $font-size-11;

      i {
        width: $font-size-11;
      }
    }

    > ul {
      color: $clr_lightblue;

      li {
        font-size: $font-size-11;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }

  .downloads_file {
    display: block;
    margin-top: 25px;

    p {
      display: block;
      margin: 0;
      padding: 0;
    }

    a {
      i.fa-file-pdf-o {
        color: red;
      }

      i.fa-file-excel-o {
        color: green;
      }

      i.fa-sticky-note {
        color: green;
      }

      margin: 0 5px;
      float: left;
    }
  }

  .card {
    // background-color: #2487bc;
    // border-color: #2487bc;
    // background-color: #2a2a72;
    // border: none;
    // background-image: linear-gradient(to top, #2a2a72 0%, #009ffd 74%);
    // border-radius: 0;
    // background: linear-gradient(to bottom, #1C7CE0, #150051);
    &.shared-board {
      background-color: #76a0b6;
      border-color: #76a0b6;
    }

    &.template-board {
      border-radius: 0.2rem;
      // border-left: 2px solid rgb(16, 133, 198);
      // color: #fff;
    }

    &.template-board:hover {
      background: $clr_bodycolor;
      // border-left: 2px solid rgb(16, 133, 198);
    }
  }

  // .icon {
  //   height: 20px;
  //   width: 20px;
  //   font-size: 14px;
  //   display: flex;
  //   -webkit-box-pack: center;
  //   justify-content: center;
  //   -webkit-box-align: center;
  //   align-items: center;
  //   border-radius: 50%;
  //   cursor: pointer;
  // }
  // .icon:hover {
  //   border: 1px solid rgba(255, 255, 255, 0);
  //   background: rgba(255, 255, 255, 0.4);
  // }
  .card-body {
    padding: 0.5rem;
    // min-height: 150px;
  }

  .template-body {
    padding: 0.7rem;
    min-height: 70px;
  }

  .card-footer {
    padding: 0.5rem;
    // color: white;
    background: none;
    border: none;

    p {
      margin-bottom: 0;
    }

    .items-count {
      color: white;
      font-size: $font-size-18;
      font-weight: bold;
    }

    .items-label {
      font-size: $font-size-13;
      margin-left: 2px;
    }

    .last-modified-at {
      font-size: $font-size-12;
      float: right;
      line-height: 30px;
      text-transform: capitalize;
    }

    .items-label,
    .last-modified-at {
      color: white;
    }
  }

  .card-title {
    // color: white;
    // text-transform: uppercase;
    font-weight: 600;
    overflow-wrap: anywhere;
  }

  .template-title {
    display: flex;
    align-items: center;
  }

  .share-info {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.my-boards-wrapper-card {
  .my-borads-card-body {
    @media (min-width: 768px) {
      padding: 25px 38px;
    }
    @media (min-width: 1200px) {
      padding: 39px 51px;
    }

    .my-boards-card {
      min-height: 180px;
      box-shadow: none;
      border: 0.5px solid $clr_border;
      border-radius: 5px;

      &.shared-board,
      &.shared-board .action-icon:hover,
      &.shared-board .card-footer .actions_board,
      &.shared-board .my-borads-card-body:hover {
        background-color: #ebf3ff;
      }

      .my-borads-card-body {
        padding: 11px 18px 17px 17px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .card-title {
        overflow-wrap: anywhere;
      }

      .tooltip-custom-theme {
        max-width: 100px;
        text-wrap: normal;
        white-space: pre-wrap;
      }
    }

    .my-templates-card {
      .card-footer {
        .actions_board {
          padding: 10px 0;
        }
      }

      .my-templates-card-body {
        padding: 18px 20px;
      }

      .action-icon {
        .icon {
          @media (min-width: 1200px) {
            padding: 10px 34px;
          }
        }
      }
    }

    .row {
      &.xl-gutter {
        .col-sm-6,
        .col-lg-4,
        .col-xl-3 {
          margin-bottom: 15px;
          @media (min-width: 768px) {
            margin-bottom: 20px;
          }
          @media (min-width: 1200px) {
            margin-bottom: 42px;
          }
        }
      }
    }

    .pin-items-block {
      padding: 13px 17px 10px;
      background-color: $clr_lightblue3;
      border-radius: 7px;
    }

    .card-footer {
      padding: 0;
      background: none;
      border-top: 1px solid $clr_border;

      .actions_board {
        background-color: $clr_bodycolor;
        padding: 12px 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .action-icon {
      // border-left: 1px solid $clr_border;
      border-bottom-right-radius: 5px;

      .icon {
        padding: 12px 24px;
        color: $clr_darkblue5;
        font-size: 22px;
      }

      &:hover {
        background-color: $clr_bodycolor;
      }
    }
  }
}

.modal-w-header {
  .modal-content {
    border: none;
  }

  .modal-header {
    background-color: #1085c6;

    .modal-title {
      text-transform: uppercase;
      color: white;
    }
  }
}

.rw-dropdown-list-input,
.rw-list-option {
  text-transform: capitalize;
}

.board-details {
  &.nav_sidebar2 {
    max-width: 150px;
  }

  .watchlist_search {
    float: right;
    width: auto;
  }

  .board-name {
    padding: 6px 10px;
    // background-color: $clr_lightgrey3;
    border-radius: 3px;
    text-transform: capitalize;
    color: $clr_blue;

    &.active {
      background-color: $clr_solitude;
      font-weight: 600;
    }
  }

  .content-wrapper {
    // padding-right: 33px;
    margin-left: 10px;

    .content-date-group {
      width: 100%;
      text-align: center;
      border-bottom: 2px dashed #ccc;
      line-height: 0.1em;
      margin: 10px 0 30px;
      font-size: $font-size-12;

      span {
        background: #fff;
        padding: 0 10px;
        text-transform: uppercase;
      }
    }
  }

  .comments-group {
    width: 100%;
    // text-align: left;
    border-bottom: 1px solid #ccc;
    line-height: 0.1em;
    margin: 10px 0 30px;
    font-size: $font-size-12;

    span {
      cursor: pointer;
      background: #fff;
      padding: 0 10px 0 0;
      text-transform: capitalize;
      color: $tbr_color;

      i,
      .add-icon {
        line-height: 0;
        font-size: 17px;
        text-transform: none;
      }

      .top-icon {
        text-transform: none;
        line-height: 0;
        width: 20px;
      }

      &.comments-count-info {
        float: left;
        display: flex;
      }

      &.comments-add-btn {
        float: right;
        display: table;
        padding: 0 0 0 10px;
      }
    }
  }

  .general-comments-widget {
    width: 400px;
    position: fixed;
    top: 130px;
    right: 15px;
    z-index: 999;
    background-color: white;
    height: calc(100vh - 175px);
    // min-height: 450px;
    box-shadow: 0 0 40px #0b254042;
    border-radius: 15px 15px 7px 7px;
    overflow-y: auto;
  }

  .close-board-comments {
    color: #000;
    cursor: pointer;
  }

  .general-comment-chat-icon {
    right: 10px;
    bottom: 2vh;
    position: fixed;
    z-index: 999;

    .fa {
      padding: 20px;
      background-color: $clr_blue_tooltip;
      border-radius: 50%;
      font-size: $font-size-18;
      color: white;
      cursor: pointer;
    }

    .fa[data-count]:after {
      position: absolute;
      right: 0;
      top: 1%;
      content: attr(data-count);
      border: 1px solid $clr_blue_tooltip;
      font-size: $font-size-11;
      padding: 0.6em;
      border-radius: 50%;
      line-height: 0.6em;
      color: $clr_blue_tooltip;
      background: white;
      text-align: center;
      min-width: 1em;
      font-weight: bold;
    }

    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  .add-general-comment {
    text-transform: capitalize;
    color: $tbr_color;
    cursor: pointer;
    float: right;
    font-weight: normal;
  }

  .tbr_position {
    .desipte_sec {
      h4 {
        background: none;
        color: $clr_blue_tooltip;
        text-transform: none;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0;
        font-weight: 600;
      }
    }
  }

  .board-item {
    padding: 0;
    margin-top: 15px;
    // background-color: red;
    // margin-bottom: 20px;
    .category-tag {
      color: white;
      padding: 5px 10px;
      // font-size: 11px;
      // background-color: #00aa5d;
      // text-transform: uppercase;
    }

    .content-info-label-tag {
      text-transform: uppercase;
    }

    .board-item-border {
      border-bottom: 1px solid #ccc;
    }
  }

  .sidebar_nav2 {
    .sidebar_list {
      margin-top: 5px;
    }

    h3 {
      text-transform: uppercase;
      font-size: $font-size-13;
      font-weight: bold;
    }

    p {
      margin-bottom: 0.5rem;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: capitalize;
      color: #1286c6;
      font-size: 12px;
      font-weight: 700;
    }

    p::before {
      content: "\00a0\00a0";
      margin-right: 3px;
    }

    p.active::before,
    p:hover::before {
      background-color: #1286c6;
    }

    p.active {
      font-weight: bold;
    }
  }

  .add_to_board_box {
    width: 170px;
  }
}

.general-comments-widget {
  .general-comments-header {
    padding: 14px 18px;

    h2 {
      font-family: $font-family;
    }

    .lg-icon {
      font-size: 30px;
      margin-right: 11px;
    }

    .icon-wrapper {
      background-color: $clr_lightgrey4;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        font-size: 14px;
        color: $clr_lightgrey2;
      }
    }
  }

  .comment-widget {
    margin-top: 0;

    .comment-wrapper {
      .collapse {
        border: none;
        background-color: $clr_white;
        box-shadow: none;
      }
    }

    .comments-list-group {
      overflow-y: auto;
      height: calc(100vh - 388px);
      min-height: 200px;
    }

    ul.general {
      margin-top: 0 !important;
    }
  }
}

.share-board-users {
  .table {
    thead th {
      color: #99a0aa !important;
      font-size: $font-size-12;
      border-bottom-width: 0;
      padding: 0.35rem;

      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 10%;
      }
    }

    tbody td {
      color: black;
      padding: 0.35rem;
      font-size: $font-size-12;
      vertical-align: middle;

      .fa-trash {
        color: #bd2130;
        cursor: pointer;
      }

      label {
        margin-bottom: 0;
      }
    }
  }
}

.content-download-button {
  font-size: 12px;
}

.company_details_download {
  color: rgb(16, 133, 198);
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
}

.disabled {
  color: rgb(158, 212, 241);
  // font-size: 20px;
  // line-height: 30px;
  cursor: not-allowed;
}

.my-boards-filter {
  font-size: $font-size-12;
}

.dash_board_content {
  .tbri-custom-breadcumb {
    ol.breadcrumb {
      padding-left: 0;
    }
  }
}

.notify {
  .form-check-input {
    margin-left: 0;
    top: -1px;
  }

  .form-check-label {
    font-size: $font-size-12 !important;
    color: #000 !important;
    margin-left: 6px;
  }
}

.users-dropdown {
  .rw-dropdown-list-input,
  .rw-list-option {
    text-transform: none;
  }
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 112;
  background: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;

  .custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #1085c6;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;

    h1 {
      margin-top: 0;
      color: #fff;
    }

    p {
      color: #fff;
    }

    button {
      width: 160px;
      padding: 10px;
      border: 1px solid #fff;
      margin: 10px;
      cursor: pointer;
      background: none;
      color: #fff;
      font-size: 14px;
    }
  }
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.board_details_main_content {
  span.action {
    color: $tbr_color;
    margin-left: 5px;
    cursor: pointer;
  }
}

.comment-widget {
  margin-top: 10px;

  .comment-wrapper {
    font-size: $font-size-12;

    .collapse {
      // border: 1px solid $clr_tableborder;
      // border-bottom: 1px solid $clr_tableborder;
      border-radius: 7px;
      border: none;
      background-color: white;
      box-shadow: 0 0 12px #0b254014;
    }

    .send-comment-block {
      background-color: $clr_lightblue2;
      padding: 6px 14px 6px 10px;
      border-radius: 7px;

      .custom-btn-group-toggle {
        .left-btn {
          opacity: 0.5;

          &.active {
            opacity: 1;
          }
        }
      }

      .send-btn-group {
        .icon {
          // margin-left: 15px;
          font-size: 26px;
        }
      }
    }

    .panel-info {
      border-color: #bce8f1;
    }

    .panel {
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      border-color: #bce8f1;
    }

    .panel-info > .panel-heading {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
    }

    .panel-heading {
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      cursor: pointer;
    }

    .panel-body {
      max-height: 650px;
      overflow: auto;
      padding: 15px;

      .media-list {
        .media {
          a {
            padding-right: 10px;
          }
        }
      }

      textarea {
        font-size: $font-size-12;
      }
    }

    .media-list .media img {
      width: 50px;
      height: 50px;
      border: 2px solid #e5e7e8;
    }

    .media-list .media {
      border-bottom: 1px dashed #efefef;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }

    .img-circle {
      border-radius: 50%;
    }

    .dropdown-menu {
      min-width: 5rem;
      padding: 0.1rem 0;

      .dropdown-item {
        &.active,
        &:active {
          background-color: gray;
        }

        &.disabled,
        &:disabled {
          background-color: transparent;
        }
      }
    }

    .list-group-item {
      .edit-action,
      .delete-action {
        display: none;
      }

      &:hover {
        .edit-action,
        .delete-action {
          display: block;
        }
      }
    }
  }

  .profile_image {
    width: 30px;
    padding-top: 5px;
    float: left;

    img {
      width: 30px;
      height: 30px;
      padding: 3px;
      border: 1px solid #ccc;
    }
  }

  .txtComments-wrapper {
    padding: 8px;
  }

  .txtComments {
    font-size: 14px;
    border: 0;
    background-color: #f3f3f3;
    font-style: italic;
    color: $clr_lightgrey2;
  }

  .comment_content {
    width: calc(100% - 30px);
    float: right;
    padding-left: 5px;

    .edit-action,
    .delete-action,
    .cancel-edit-action,
    .save-edit-action {
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }
  }

  ul.general {
    // height: 55vh;
    overflow-y: auto;
  }

  .btn-show-more-comments {
    color: #1286c6;
    border-color: #1286c6;

    &:hover {
      color: white;
    }
  }
}

.modal {
  .comment-widget {
    margin-top: 0;
  }
}

.comment-widget .panel-body {
  padding: 0;
}

.comment-widget .list-group {
  margin-bottom: 0;
  padding-top: 5px;
}

.comment-widget .panel-title {
  display: inline;
}

.comment-widget .label-info {
  float: right;
}

.comment-widget li.list-group-item {
  border-radius: 0;
  border: 0;
}

.comment-widget li.list-group-item:hover {
  background-color: $clr_bodycolor;
}

.comment-widget .mic-info {
  color: #666666;
  font-size: 11px;
}

.comment-widget .action {
  margin-top: 5px;
}

.comment-widget .comment-text {
  font-size: $font-size-11;
}

.comment-widget .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-general-comments {
  color: #046cd9;
  border-color: transparent;
  box-shadow: none !important;

  &:hover {
    color: #046cd9;
    // background-color: #1286c6;
  }

  font-size: $font-size-12;
  padding: 0.25rem;
  padding-left: 9px;
  // @media only screen and (max-width: 768px) {
  //   display: none;
  // }
}

.btn-xs {
  padding: 1px 5px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}

.download-dropdown {
  button.download-button {
    border: none;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      background: none !important;
      border: none !important;
      box-shadow: none !important;
    }

    &.btn-outline-secondary {
      background: none !important;
      border: none !important;
    }
  }

  &.show {
    background: none !important;
    border: none !important;

    .btn-outline-secondary.dropdown-toggle {
      background: none !important;
      border: none !important;
    }
  }

  .dropdown-menu {
    font-size: 12px;
    min-width: auto;
  }
}

.category-wrapper {
  // border: 1px solid $clr_blue_tooltip;
  margin-bottom: 15px;
  border-top: none;

  .category_name {
    // text-transform: uppercase;
    position: sticky;
    top: 70px;
    // padding: 10px;
    // background-color: $clr_blue_tooltip;
    z-index: 99;
    // color: white;
    text-align: center;
    @media only screen and (max-width: 768px) {
      top: auto;
      position: relative;
    }
  }
}

// .projects-details-collapser {
//   .category-wrapper {
//     margin-bottom: 15px;
//   }
// }
.is-current-parent {
  label.category-label {
    // background-color: $clr_blue_tooltip;
    // color: white;
    color: $clr_blue_tooltip;
  }
}

.is-current-child {
  label.sub-category-label {
    // background-color: $clr_blue_tooltip;
    // color: white;
    color: $clr_blue_tooltip;
  }
}

.not-found-text {
  font-weight: 600;
  font-size: 20px;
  color: #232d42;
  margin-top: 15px;
}

.visualization-not-found {
  width: 100%;
  height: 400px;
  background-color: red;
  display: flex;
}

.board-details-right-content {
  .board-details-card {
    // box-shadow: 0 0 30px #0b254017;
    border: 1px solid $clr_lightgrey_new;
    border-radius: 4px;
    background-color: $clr_white;

    .sticky-board-details-header {
      background-color: white;
      z-index: 99;
      // top: 123px;
      top: 64px;
      margin-bottom: 20px;
      padding: 15px 17px;
      position: sticky;
      border-top: 1px solid $clr_lightgrey_new;
      border-radius: 4px;
      // @media only screen and (max-width: 768px) {
      //   top: 56px;
      // }
      // @media only screen and (min-width: 769px) {
      //   position: sticky;
      // }
      .board-name-block {
        .board-name {
          text-transform: capitalize;
          padding: 0;
          margin-left: 11px;
        }

        .icon-wrapper {
          background-color: $clr_lightgrey4;
          height: 32px;
          width: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            font-size: 14px;
            color: $clr_lightgrey2;
            transform: rotate(-45deg);
          }
        }
      }

      .share-permission-link {
        color: $clr_lightblue2;

        .icon {
          margin-right: 9px;
        }
      }
    }

    .board-details-body {
      width: 100%;
      padding: 12px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > div {
        width: 100%;
        // height: 54vh !important;
        //overflow-y: scroll;
      }

      .board-list-item,
      .board-list-item-drag-fix {
        width: 100%;
        height: 100%;
        padding: 12px 10px;
        margin-top: 15px;
        border: 1px solid rgb(226, 226, 226);
        cursor: pointer;
        border-radius: 2px;

        .category-tag {
          padding: 2px 4px;
          // border-radius: 4px;
          font-size: $font-size-h3;
          color: $clr_black2;
        }

        .desipte_sec,
        .content-section {
          img {
            max-width: 100%;
            height: auto;
          }
        }

        // &:nth-child(even) {
        //   .category-tag {
        //     background-color: $clr_lightgrey6;
        //     color: $clr_cornflower_blue;
        //   }
        // }
        // &:nth-child(odd) {
        //   .category-tag {
        //     background-color: $clr_lilywhite;
        //     color: $clr_lightgreen2;
        //   }
        // }
      }

      .board-list-item-drag-fix {
        height: 68px !important;

        .Collapsible__contentOuter {
          height: 0 !important; //Fix Lag in rendering while picking dragabble
        }
      }
    }
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 45px;
  right: 15px;
  animation: scroll-to-top-fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  z-index: 106;

  &:hover {
    opacity: 1;
  }
}

@keyframes scroll-to-top-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

.dx-g-bs4-table-sticky {
  z-index: 1;
}

.Toastify {
  .Toastify__toast {
    font-family: $font-family;
    font-size: $font-size-12;
    font-weight: bold;
    min-height: 55px;
    position: absolute;
    bottom: 20px;
    width: 300px;
  }
}

.dataTable {
  tbody {
    tr {
      td {
        i {
          &.allowed-access {
            color: $clr_white;
            background-color: #5cb85c;
            font-size: 9px;
            padding: 3px;
            border-radius: 50%;
          }

          &.allowed-access-warning {
            background-color: #ffc107;
            color: $clr_white;
          }

          &.not-allowed-access {
            // color: #ffc107;
            svg {
              width: 16px;
              height: 16px;

              .a {
                stroke: none;
                fill: #99a0aa;
              }
            }
          }

          cursor: pointer;
        }
      }
    }
  }
}

i.benchmark {
  svg {
    width: 22px;
    height: 20px;
    margin-bottom: 8px;
  }
}

.tooltip-custom-theme {
  padding: 5px !important;
  font-size: $font-size-12 !important;
  font-family: $font-family !important;
  line-height: $font-size-12 !important;
  background-color: $clr_blue_tooltip !important;
}

.__react_component_tooltip.show {
  &.place-top,
  &.place-left,
  &.place-right {
    opacity: 1;
    background-color: $clr_blue_tooltip;

    &::before {
      background-color: transparent;
      width: 0;
      height: 0;
    }

    &::after {
      background-color: transparent;
    }
  }

  &.place-left {
    &::before,
    &::after {
      right: -8px;
      margin-top: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $clr_blue_tooltip;
    }
  }

  &.place-right {
    &::before,
    &::after {
      left: -8px;
      margin-top: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $clr_blue_tooltip;
    }
  }

  &.place-top {
    &::before,
    &::after {
      bottom: -8px;
      margin-left: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $clr_blue_tooltip;
    }
  }
}

.__react_component_tooltip.place-top::after {
  // border-top-color: $clr_blue_tooltip !important;
}

.__react_component_tooltip.place-right::after {
  // border-right-color: $clr_blue_tooltip !important;
}

.__react_component_tooltip.place-bottom::after {
  border-bottom-color: $clr_blue_tooltip !important;
}

.__react_component_tooltip.place-left::after {
  // border-color: transparent !important;
  // background-color: transparent !important;
}

.datagrid_tooltip {
  i {
    color: #99a0aa !important;
    font-size: $font-size-14 !important;
    cursor: pointer;
  }

  .icon {
    color: #99a0aa !important;
    font-size: 16px !important;
    cursor: pointer;
    margin-left: 4px;
  }
}

.years_dropdown {
  line-height: 1.75rem;
}

.inactive_message {
  color: #ffc107;
  border: 2px solid #ffc107;
  padding: 3px;
  font-weight: bold;
}

i {
  &.allowed-access {
    color: $clr_white;
    background-color: #5cb85c;
    font-size: 9px;
    padding: 3px;
    border-radius: 50%;
  }

  &.not-allowed-access {
    // color: #ffc107;
    svg {
      width: 16px;
      height: 16px;

      .a {
        stroke: none;
        fill: #99a0aa;
      }
    }
  }

  &.allowed-access-warning {
    background-color: #ffc107;
    color: $clr_white;
  }

  cursor: pointer;
}

.companies-list-legends {
  font-size: $font-size-12;
  border: 1px solid #ccc;
  padding: 4px 0;

  h3 {
    display: inline;
    font-size: $font-size-12;
    margin-left: 3px;
  }

  i {
    font-size: 9px;

    svg {
      // width: ;
      width: 14px;
      height: 14px;
    }
  }
}

.companies-list-legends1 {
  // font-size: $font-size-11;
  padding: 4px 0;

  h3 {
    display: inline;
    font-size: $font-size-12;
    margin-left: 3px;
  }

  i {
    // font-size: 12px;
    svg {
      // width: ;
      width: 14px;
      height: 14px;
    }
  }
}

.companies-list-legends2 {
  // font-size: $font-size-11;
  text-align: right;
  padding-bottom: 15px;

  h3 {
    display: inline;
    font-size: $font-size-12;
    margin-left: 3px;
  }

  i {
    // font-size: 12px;
    svg {
      // width: ;
      width: 14px;
      height: 14px;
    }
  }
}

.table-list-legends {
  text-align: right;
  padding-bottom: 12px;

  h3 {
    display: inline;
    font-size: $font-size-12;
    margin-left: 3px;
  }

  .allowed-access-icon,
  .inactive-allowed-access-icon,
  .not-allowed-access-icon {
    opacity: 1;
  }
}

.success-toggle {
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    // border-color: #dc3545;
    // background-color: #dc3545;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }

  // .custom-control-label::before {
  //   color: #fff;
  // 	border-color: #dc3545;
  // 	background-color: #dc3545;
  // }
  // .custom-control-label::after {
  //   background-color: white;
  // }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(40, 167, 69, 0.5);
  }
}

.filter-dropdown {
  .dd__selectControl {
    padding: 12px 6px !important;
    font-size: 1rem;
    max-height: 26px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    div {
      font-size: 12px;
    }
  }

  .filter-options {
    z-index: 10;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .dd__option {
      font-size: 12px;
      padding: 6px;
      padding: 1px 5px;
    }
  }
}

// .quantData-filters{
//   @media (min-width: 992px)
//   {
//     .col-lg-2 {
//       flex: 0 0 13.666667% !important;
//     }
//   }
// }
.company_segment_note {
  label {
    color: #99a0aa;
    font-size: 0.75rem;
  }

  span {
    padding-right: 15px;
    font-size: 1.125rem;
    color: #1085c6;
    cursor: pointer;
  }
}

.create_chart {
  .rw-widget-picker {
    height: 35px !important;
  }

  .type {
    button {
      background: rgb(238, 238, 238);
      padding: 5px 8px;
      border-radius: 6px;
      display: inline-block;
      cursor: pointer;
      color: #1085c6;
      border: none !important;
      margin-right: 20px;
      font-size: 18px;

      &:focus,
      &:active {
        outline: none !important;
      }
    }

    .active {
      background-color: #1085c6;
      color: rgb(238, 238, 238);
    }
  }

  .rct-icons-fa4 .rct-icon-expand-close::before {
    content: "\f0d7";
  }

  .rct-icons-fa4 .rct-icon-expand-open::before {
    content: "\f0d8";
  }

  .rct-icons-fa4 .rct-icon-check::before {
    content: "\f0c8";
    color: #1085c6;
  }

  .rct-icons-fa4 .rct-icon-half-check::before {
    opacity: 0.5;
    content: "\f0c8";
  }

  .segments-tree {
    .Collapsible__trigger {
      display: block;
      font-weight: 400;
      // text-decoration: none;
      position: relative;
      border: 1px solid white;
      padding: 5px 10px;
      background-color: #fff;
      border-bottom: #ccc 1px solid;
      color: #000;
    }

    .Collapsible__contentOuter {
      overflow: scroll !important;
      max-height: 150px !important;
    }

    label {
      display: flex !important;
    }

    .is-disabled {
      color: grey;
    }

    .Collapsible__trigger:after {
      font-family: "FontAwesome";
      content: "\f055";
      position: absolute;
      right: 10px;
      top: 5px;
      display: block;
      transition: transform 300ms;
      cursor: pointer;
      color: #1085c6;
    }

    .Collapsible__trigger.is-open:after {
      content: "\f056";
    }
  }

  .chart_selection_section {
    border-left: #ccc 1px solid;
    border-bottom: #ccc 1px solid;
    padding: 20px 16px;
    height: fit-content;
  }

  // h3 {
  //   &:before {
  //     font-family: "FontAwesome";
  //     content: "\f192";
  //     padding-right: 22px;
  //     color: #1085c6;
  //     margin-left: -39px;
  //   }
  // }
  h3,
  h6 {
    color: #000;
  }

  // label {
  //   font-size: 12px;
  // }

  .sub_heading {
    font-size: 12px;
    color: #4d4848;
  }

  // .rct-text {
  //   display: flex;
  //   justify-content: space-between;
  //   flex-direction: row-reverse;
  // }
  .active-node {
    background-color: #f9f9f9;
  }

  .elements {
    border: #ccc 1px solid;
    border-radius: 6px;

    .add {
      color: #1085c6;
      font-size: 12px;
      cursor: pointer;
      // text-align: center;
    }

    .Collapsible__trigger {
      display: block;
      // font-weight: 400;
      text-decoration: none;
      position: relative;
      border: 1px solid white;
      // padding: 5px 10px;
      background-color: #fff;
      // border-bottom: #ccc 1px solid;
      // color: #1085c6;
      font-size: 12px;
    }

    .companies {
      font-size: 12px;

      &::before {
        font-family: "FontAwesome";
        content: "\f1ad";
        display: inline-block;
        color: #1085c6;
        margin-right: 2px;
      }
    }

    .Collapsible__trigger:after {
      font-family: "FontAwesome";
      content: "\f0d7";
      position: absolute;
      right: 10px;
      top: 0;
      display: block;
      transition: transform 300ms;
      cursor: pointer;
    }

    .Collapsible__trigger.is-open:after {
      content: "\f0d8";
    }

    h6 {
      color: #1085c6;
      border-bottom: #ccc 1px solid;
    }
  }
}

.chart-preview {
  .amcharts-range-selector-wrapper {
    display: flex;
    align-items: baseline;

    input {
      font-size: 12px;
      width: 90px;
      border-color: rgb(224, 224, 224);
      text-align: center;
      outline: none;
      width: 100%;
      padding: 8px 10px;
      box-sizing: border-box;
      border-radius: 2px;
      border: 1.5px solid rgb(158, 158, 158);
      overflow: visible;
    }

    .amcharts-range-selector-title {
      display: none;
    }

    .amcharts-range-selector-period-wrapper {
      button {
        background: rgb(224, 224, 224);
        border-radius: 2px;
        padding: 8px 12px;
        border: none;
        font-size: 12px;
        margin: 4px;
        color: rgb(117, 117, 117);
        cursor: pointer;

        &:active,
        &:focus {
          background: rgb(16, 133, 198);
          color: #fff;
          outline: none !important;
        }
      }
    }
  }

  .chart-button-active {
    background: #1085c6 !important;
    color: #fff !important;
    outline: none !important;
  }
}

.textSize {
  font-size: 12px;
}

.textColor {
  color: #131111 !important;
}

.faq_content {
  width: 100%;
  padding-left: 270px;
  @media only screen and (max-width: 767px) {
    padding-left: 0;
  }
}

.collapse:not(.show) {
  display: block !important;
}

.collapse {
  margin-top: 0;
}

.collapse-trigger {
  color: $clr_darkgrey;

  &::before {
    border-width: 0 0 2px 2px;
  }
}

.collapse-panel {
  color: $clr_grey;
}

.collapse-trigger[aria-expanded="true"]:before {
  transform: rotate(133deg);
  left: 0;
}

.modal-w-header .modal-header .modal-title {
  text-transform: capitalize;
}

.allowed-access-icon {
  fill: #5cb85c !important;
  color: #5cb85c !important;
}

.inactive-allowed-access-icon {
  fill: #ffc107;
  color: #ffc107;
}

.not-allowed-access-icon {
  fill: #99a0aa;
  color: #99a0aa;
}

.allowed-access-icon,
.inactive-allowed-access-icon,
.not-allowed-access-icon {
  opacity: 0.6;
  transition: all 0.5s ease;

  &:hover {
    opacity: 1;
  }
}

.companies-list-legends3 i svg {
  width: 20px;
  height: 20px;
  fill: #707070;
}

.btn-color-gray {
  background-color: #e7e7e7;
  color: black;
  border-color: #000;
  padding: 0.2rem 0.75rem;
  height: 30px;
  line-height: 0.5;
  font-size: $font-size-12;
}

.btn-color-primary {
  //background-color: #e7e7e7;
  border-radius: 2px;
  padding: 8px 12px;
  border: none;
  font-size: 12px;
  margin: 4px;
  cursor: pointer;
  border-color: $clr_lightblue;
  background: $clr_lightblue;
}

.btn-color-light-gray {
  background: #e0e0e0;
  border-radius: 2px;
  padding: 8px 12px;
  border: none;
  font-size: 12px;
  margin: 4px;
  color: #757575;
  cursor: pointer;
}

.series-align-center {
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  text-align: center;
  overflow: visible;
}

.selectelements-card {
  .normal-btn {
    min-width: 112px;
    background-color: $clr_solitude;
    color: $clr_lightblue3;
    padding: 2px 10px;
    max-height: 30px;
    border-radius: 5px;
    box-shadow: none;
  }
}

.category-btn-block {
  .normal-btn + .normal-btn {
    margin-left: 14px;
  }

  .normal-btn.active-btn {
    background: $clr_darkblue5 0% 0% no-repeat padding-box;
    box-shadow: 0 5px 12px #0b254026;
    color: $clr_white;
    font-weight: 600;
  }
}

.selectelements-chartpreview-wrapper * {
  transition: all 0.5s ease;
}

.selectelements-chartpreview-wrapper {
  .selectelements-card-wrapper {
    margin-right: 10px;
  }

  .closed-content {
    .content {
      writing-mode: vertical-lr;
      font-size: 16px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .selectelements-card-wrapper,
  .chart-preview-wrapper {
    flex-grow: 1;
  }

  .show-contents-content {
    .icon-wrapper {
      .icon {
        cursor: pointer;

        &.disabled {
          font-size: 24px;
          line-height: 24px;
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
  }

  &.opened-chartPreview {
    .selectelements-card-wrapper {
      max-width: 65px;

      .selectelements-content {
        opacity: 0;
        transform: translateX(-2000px);
        display: none !important;
        height: 0;
      }

      .show-contents-content {
        opacity: 1;
        transform: translateX(0);
        display: flex !important;
      }
    }

    .chart-preview-wrapper {
      max-width: 100%;

      .chartpreview-content {
        opacity: 1;
        transform: translateX(0);
        display: flex !important;
        flex-direction: column;

        #SalesChart {
          .amcharts-amexport-item-level-0 > .amcharts-amexport-icon {
            display: none;
          }

          .amcharts-amexport-item.amcharts-amexport-item-level-0 {
            position: absolute;
            width: 33px !important;
            height: 33px;
            top: -92px;
            right: -6px;
            cursor: pointer;
            margin: 0;

            &::before {
              font-family: "Material Icons Round";
              content: "\e2c4";
              display: block;
              height: 33px;
              width: 33px;
              position: absolute;
              left: 0;
              background: $clr_lightblue2;
              font-size: 17px;
              color: $clr_white;
              text-align: center;
              line-height: 33px;
              border-radius: 5px;
            }
          }
        }
      }

      .show-contents-content {
        opacity: 0;
        transform: translateX(2000px);
        display: none !important;
        height: 0;
      }
    }
  }

  &.closed-chartPreview {
    .selectelements-card-wrapper {
      max-width: 100%;

      .selectelements-content {
        opacity: 1;
        transform: translateX(0);
        display: flex !important;
        flex-direction: column;
      }

      .show-contents-content {
        opacity: 0;
        transform: translateX(-2000px);
        display: none !important;
        height: 0;
      }
    }

    .chart-preview-wrapper {
      max-width: 65px;

      .chartpreview-content {
        opacity: 0;
        transform: translateX(2000px);
        display: none !important;
        height: 0;
      }

      .show-contents-content {
        opacity: 1;
        transform: translateX(0);
        display: flex !important;
        flex-direction: column;
      }
    }
  }

  .selectelements-content {
    .first-section {
      border: 1px solid #dcedff;
      margin-left: -1px;
      margin-top: -1px;
      border-top-left-radius: 3.5px;
      // min-height: 496px;
    }
    .elements-block-wrapper-new {
      margin-top: 20px;
      border-radius: 3.5px;
      .block-title {
        padding: 12px 14px;
      }
      .metric-ul {
        padding: 9.5px 14px;
        display: flex;
        gap: 1rem;

        .active {
          color: $clr_blue;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 120%;
            border-bottom: 3.5px solid $clr_blue;
            position: absolute;
            bottom: -10px;
            left: -3px;
            right: 0;
            border-radius: 5px;
          }
        }

        li {
          line-height: 8px;

          a {
            transition: unset;
          }
        }
      }
      .select-companies-block {
        border: 1px solid $clr_tableborder_chart;
        margin-left: -1px;
        margin-top: -1px;
        border-top-left-radius: 3.5px;
        padding: 0px;
      }
      .add-segments-block {
        background-color: rgba(243, 243, 243, 0.5);
        border: 1px solid $clr_tableborder_chart;
        margin-left: -1px;
        margin-top: -1px;
        padding: 0px;
        .block-title-icon {
          padding: 9.5px 14px;
        }
      }
      .add-metrics-block {
        // background-color: rgba(243, 243, 243, 0.5);
        border: 1px solid $clr_tableborder_chart;
        margin-left: -1px;
        margin-top: -1px;
        padding: 0px;
      }
      .select-metric-block {
        // background-color: rgba(243, 243, 243, 0.5);
        padding: 0px;
        label {
          cursor: pointer;
        }
      }
      .selected-items-block {
        background-color: $clr_baby_blue;
        // border-left: 1px solid $clr_tableborder_chart;
        // border-bottom: 1px solid $clr_tableborder_chart;
        margin-left: 10px;
        // height: 60.5vh;
      }
      .selected-items-block {
        .plus-minus-collapser {
          .collapse-panel,
          .Collapsible__contentInner {
            display: flex;
            flex-wrap: wrap;
            padding-left: 7px;
            padding-right: 7px;
            .collapse-panel-content {
              padding-left: 5px;
              padding-right: 5px;
              margin-bottom: 10px;
            }
          }
        }
        .selected-items-list {
          .selected-item {
            border-radius: 30px;
            padding: 5px 8px 5px 12px;
            background-color: $clr_lightgrey3;
            font-size: 12px;
            .icon {
              border: 1px solid $clr_border;
              font-size: 14px;
              padding: 2px;
              border-radius: 50%;
              background-color: $clr_white;
              color: $clr_lightgrey5;
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .elements-block-wrapper {
      display: flex;
      flex-direction: row; /* Align children in a row */
      flex-wrap: wrap;
      margin-top: 20px;
      border: 1px solid $clr_tableborder_chart;
      border-radius: 3.5px;

      .btn-block {
        padding-right: 16px;

        .btn {
          min-width: 105px;
          min-height: 30px;
        }
      }
    }

    .elements-block {
      .element-block {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }

      .block-title {
        padding: 12px 14px;
      }

      .metric-ul {
        padding: 9.5px 14px;
        display: flex;
        gap: 1rem;

        .active {
          color: $clr_blue;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 120%;
            border-bottom: 3.5px solid $clr_blue;
            position: absolute;
            bottom: -10px;
            left: -3px;
            right: 0;
            border-radius: 5px;
          }
        }

        li {
          line-height: 8px;

          a {
            transition: unset;
          }
        }
      }

      .add-segments-block {
        .block-title-icon {
          padding: 9.5px 14px;
        }
      }

      @media (min-width: 992px) {
        .select-companies-block {
          width: 18%;
        }
        .add-segments-block {
          width: 28%;
        }
        .select-metric-block {
          width: 20%;
        }
        .selected-items-block {
          width: 34%;
        }
      }

      .select-companies-block,
      .select-metric-block {
        label {
          cursor: pointer;
        }
      }

      .select-companies-block {
        border: 1px solid $clr_tableborder;
        margin-left: -1px;
        margin-top: -1px;
        border-top-left-radius: 3.5px;
      }

      .select-metric-block,
      .add-segments-block {
        border: 1px solid $clr_tableborder;
        margin-left: -1px;
        margin-top: -1px;
      }

      .selected-items-block {
        border-left: 1px solid $clr_tableborder;
        border-bottom: 1px solid $clr_tableborder;
        margin-left: -1px;
      }

      // .btn-block{
      //   border: 1px solid $clr_tableborder;
      //   margin-left: -1px;
      //   margin-top: -1px;
      // }
      .selected-items-block {
        .plus-minus-collapser {
          .collapse-panel,
          .Collapsible__contentInner {
            display: flex;
            flex-wrap: wrap;
            padding-left: 7px;
            padding-right: 7px;

            .collapse-panel-content {
              padding-left: 5px;
              padding-right: 5px;
              margin-bottom: 10px;
            }
          }
        }

        .selected-items-list {
          .selected-item {
            border-radius: 30px;
            padding: 5px 8px 5px 12px;
            background-color: $clr_lightgrey3;
            font-size: 12px;

            .icon {
              border: 1px solid $clr_border;
              font-size: 14px;
              padding: 2px;
              border-radius: 50%;
              background-color: $clr_white;
              color: $clr_lightgrey5;
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .block-title {
    border-bottom: 1px solid $clr_tableborder;
  }

  .selected-items-wrapper,
  .custom-checkbox-tree {
    height: 48vh;
    overflow-y: auto;
  }

  .plain-checkbox-list-wrapper {
    height: 48vh;
    overflow-y: auto;
    flex-grow: 1;

    .checkbox-list {
      padding-left: 0;

      .checkbox-item {
        padding: 7px 10px 7px 14px;

        &:not(:last-child) {
          border-bottom: 1px solid $clr_tableborder;
        }

        .rct-title {
          padding: 0;
        }

        .text {
          margin-left: 12px;
          margin-right: 15px;
          font-size: 12px;
          color: $clr_lightgrey2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .add-icon {
          opacity: 0;
          font-size: 18px;
          color: $clr_blue;
          cursor: pointer;
        }

        .custom-checkbox-new:checked {
          + .text {
            color: $clr_blue !important;
          }

          ~ .add-icon {
            opacity: 0.5;
            margin-left: auto;
            margin-right: 20px;
          }
        }

        &.active {
          .custom-checkbox-new:checked {
            ~ .add-icon {
              opacity: 1;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.segments-tree {
  .rct-icons-fa4 .rct-icon-uncheck::before,
  .rct-icons-fa4 .rct-icon-check::before {
    content: none;
  }

  .react-checkbox-tree .rct-checkbox .rct-icon {
    -webkit-appearance: none;
    min-width: 14px;
    width: 14px;
    height: 14px;
    border: 1px solid #cfd4d9;
    vertical-align: sub;
    outline: none;
    border-radius: 3px;
    position: relative;
    cursor: pointer;

    &.rct-icon-check {
      background-color: #0461c2;
      border-color: #0461c2;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==);
        background-size: 28px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .rct-icons-fa4 .rct-icon-expand-close::before {
    font: 20px "Material Icons Round";
    content: "\e5cc";
    color: $clr_lightgrey2;
  }

  .rct-icons-fa4 .rct-icon-expand-open::before {
    font: 20px "Material Icons Round";
    content: "\e5cf";
    color: $clr_lightblue2;
  }

  .react-checkbox-tree label:hover {
    background-color: transparent;
  }

  .react-checkbox-tree > ol > li {
    &.rct-node-parent {
      &.rct-node-expanded {
        > .rct-text {
          background-color: $clr_alice_blue2;

          .rct-title {
            color: $clr_blue;
          }
        }
      }
    }
  }

  &.custom-checkbox-tree {
    padding: 5px 8px;

    .add-icon {
      opacity: 0;
      font-size: 18px;
      color: $clr_blue;
      cursor: pointer;
      margin-left: auto;
      margin-right: 20px;
    }

    .custom-checkbox-new:checked {
      + .text {
        color: $clr_blue !important;
      }

      ~ .add-icon {
        opacity: 0.5;
      }
    }

    .checkbox-item {
      &.active {
        > .rct-text {
          .custom-checkbox-new:checked {
            ~ .add-icon {
              opacity: 1;
              margin-right: 0;
            }
          }
        }
      }
    }

    .checkbox-list {
      .checkbox-item {
        padding: 2px 0;

        .icon {
          color: $clr_lightgrey2;
          font-size: 22px;
          line-height: 10px;
        }

        &.expanded-true {
          > .rct-text > .icon {
            color: $clr_lightblue2;
          }
        }

        .rct-title {
          color: $clr_lightgrey2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 6px;
          font-size: 12px;
          cursor: pointer;

          &.active {
            color: $clr_blue;
          }
        }

        .custom-checkbox-new:checked {
          ~ .rct-title {
            color: $clr_blue;
          }
        }

        .rct-text {
          padding: 4px 5px 3px;

          .icon {
            cursor: pointer;
          }

          .custom-checkbox-new {
            margin-left: 10px;
          }

          .icon ~ .custom-checkbox-new {
            margin-left: 0;
          }
        }
      }
    }

    .visualisation {
      > .checkbox-list {
        > .checkbox-item {
          &.expanded-true {
            > .rct-text {
              background-color: $clr_alice_blue2;
              border-radius: 3px;
            }
          }
        }
      }
    }

    ol {
      margin: 0;
      padding-left: 0;
      list-style-type: none;
    }

    ol ol {
      padding-left: 24px;
    }

    li {
      list-style-type: none;
      margin: 0;
      position: relative;
    }

    li::before {
      border-left: 1px solid $clr_tableborder;
      bottom: 50px;
      height: 100%;
      top: -3px;
      width: 1px;
    }

    li::after {
      border-top: 1px solid $clr_tableborder;
      height: 20px;
      top: 16px;
      width: 18px;
    }

    li:last-child::before {
      height: 20px;
    }

    li::before,
    li::after {
      content: "";
      left: -8px;
      position: absolute;
      right: auto;
      z-index: -1;
    }

    .visualisation {
      > ol > li::after,
      > ol > li::before {
        border: 0;
      }
    }
  }
}

.custom-datecontainer-input {
  padding: 0;

  input {
    background-color: $clr_black2;
    box-shadow: 0 5px 12px #0b254026;
    border-radius: 5px;
    color: $clr_white;
    font-size: $font-size-h6;
    width: auto;
    min-height: 35px;
    max-width: 125px;
    margin-left: 0;
    margin-top: 0;
  }
}

.chart-duration-dropdown {
  margin-left: 10px;
  margin-right: 23px;
}

.radio-button-label-alignment {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.modalError {
  .invalid-tooltip {
    right: 0 !important;
  }
}

.calendar {
  z-index: 15;
}

.visibility-hidden {
  visibility: hidden;
}

.custom-control.custom-switch {
  // display: inline-flex;
  // align-items: center;
  min-height: 20px;
  padding-left: 30px;

  input:disabled {
    + .custom-control-label {
      opacity: 0.4;
      cursor: no-drop;
    }
  }

  .custom-control-label {
    line-height: 20px;
    cursor: pointer;

    &::before {
      left: -27px;
      width: 20px;
      height: 12px;
      pointer-events: all;
      border-radius: 0.5rem;
      top: 5px;
      border: 2px solid #84909e !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    &::after {
      top: 8px;
      left: -23px;
      width: 6px;
      height: 6px;
      background-color: #84909e !important;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      border: 2px solid #046cd9 !important;
      background-color: transparent;
    }

    &::after {
      background-color: #046cd9 !important;
      left: -29px;
    }
  }

  &.success-toggle {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border: 2px solid $clr_green2 !important;
      }

      &::after {
        background-color: $clr_green2 !important;
      }
    }
  }

  &.custom-switch-lg {
    padding-left: 30px;

    .custom-control-label {
      &::before {
        left: -29px;
        width: 28px;
        height: 16px;
        top: 3px;
      }

      &::after {
        top: 7px;
        left: -25px;
        width: 8px;
        height: 8px;
      }
    }

    .custom-control-input:checked {
      ~ .custom-control-label::after {
        left: -26px;
      }
    }
  }
}

.justify-between {
  justify-content: space-between;
}

.custom-switch-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 12px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:disabled + .slider {
    opacity: 0.65;
    cursor: no-drop;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 2px solid #84909e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 6px;
    width: 6px;
    left: 2px;
    bottom: 1px;
    background-color: #84909e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    border: 2px solid #046cd9;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(6px);
    -ms-transform: translateX(6px);
    transform: translateX(6px);
    background-color: #046cd9;
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  &.success-custom-switch-checkbox {
    input:checked + .slider {
      border: 2px solid $clr_green2;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(6px);
      -ms-transform: translateX(6px);
      transform: translateX(6px);
      background-color: $clr_green2;
    }
  }

  &.custom-switch-checkbox-2 {
    .slider {
      // background-color: $clr_lightblue2;
      border: 2px solid $clr_lightblue2;

      &:before {
        background-color: $clr_lightblue2;
      }
    }

    input:checked + .slider {
      border: 2px solid $clr_lightblue2;
      background: $clr_lightblue2;

      &:before {
        background-color: white;
      }
    }
  }
}

.faq-page {
  // padding-right: 5px;
  .inner-sidebar {
    padding: 0;
    max-width: 245px;
    top: 144px;
    height: calc(100% - 198px);
  }

  .inner-sidebar + .inner-sidebar-right-content {
    padding-left: 265px;
  }

  .faq-page-title-sec {
    padding-top: 7px;
    padding-bottom: 22px;

    .chat-icon {
      font-size: 40px;
      margin-right: 17.5px;
    }
  }

  .faq-inner-sidebar-content {
    padding: 10px 8px;

    ul {
      padding-bottom: 4px;

      .faq-sideitem:not(:last-child) {
        li {
          margin-bottom: 15px;
        }
      }

      li {
        padding: 11px 13px;
        // box-shadow: 0 0 30px #0b254017;
        border: 1px solid $clr_lightgrey_new;
        border-radius: 3.5px;
        background-color: white;
        cursor: pointer;

        &.active {
          border: 1px solid $clr_lightblue2;
        }
      }
    }
  }

  .faq-right-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0 0 25px #0b254024;
    border-radius: 3.5px;
    // padding: 4px 5px;
    .faq-topic-card {
      padding-top: 25px;
    }

    .faqs-card {
      background: inherit;
      box-shadow: none;
      border-radius: 0;
      height: calc(100vh - 204px);
      overflow: auto;

      .card-body {
        padding: 0 40px 26px;
      }
    }

    .faq-title {
      font-family: $font-family;
    }
  }

  .faqs-collapse-wrapper {
    padding-top: 9px;
    padding-bottom: 25px;
  }
}

.add-user-card {
  form.form-wrapper {
    .card {
      .card-header {
        padding: 19px 17px;
        padding-bottom: 0;
        background-color: transparent;
        border-bottom: none;
      }

      .card-body {
        padding: 12px 17px 27px;

        .card-body-header {
          background-color: $clr_alice_blue3;
          padding: 10px;
          color: $clr_darkgrey2;

          h5 {
            color: $clr_darkgrey2;
          }
        }

        .form-field-wrapper {
          margin-top: 29px;
          padding-bottom: 11px;
          border-bottom: 1px solid $clr_tableborder;

          .form-control {
            flex-grow: 1;
            border: 1px solid #e1e1e1;
            border-radius: 2px;
          }

          .form-group {
            margin-bottom: 23px;

            &.align-top {
              align-items: flex-start;

              label {
                margin-top: 2px;
              }
            }

            label {
              margin-right: 10px;
              min-width: 60px;
              font-size: 12px;
              text-align: left;
              justify-content: flex-start;
              color: $clr_lightgrey;
            }

            .invalid-tooltip {
              margin-left: 70px;
            }
          }
        }

        .form-buttons {
          margin-top: 24px;
        }
      }
    }
  }
}

.modal-footer .modal-btn {
  margin: 0;
}

.modal-btn,
.modal-footer .modal-btn {
  min-width: 85px;
  max-height: 33px;
  min-height: 33px;
  line-height: 33px;
  text-align: center;
  padding: 0 16px;
  font-size: $font-size-h6;

  + .modal-btn {
    margin-left: 18px;
  }
}

.modal-sm {
  &.modal-dialog {
    @media (min-width: 576px) {
      max-width: 383px;
      margin-top: 50px;
    }
  }
}

.modal-backdrop.show {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.88;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.modal-dialog {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0 12px #0b254024;
  border-radius: 4px;
  opacity: 1;
}

.custom-modal {
  &.noaccess-modal {
    &.modal-dialog {
      @media (min-width: 576px) {
        max-width: 450px;
        margin: 1.75rem auto;
      }
    }
  }

  .modal-content {
    border-radius: 3.5px;
    border-top: 17px solid $clr_lightblue3;
    border-bottom: 7px solid $clr_lightblue3;
    padding: 16px 25px 25px;
  }

  .modal-header {
    padding: 0;
    position: relative;
    border: 0;
    display: flex;
    align-items: center;

    h5.modal-title {
      margin: 0;
      text-align: left;

      h3 {
        margin: 0;
      }
    }

    .close {
      padding: 2px;
      display: inline-flex;
      outline: none;
      position: static;
      margin: 0;
      width: 30px;
      height: 30px;

      span {
        visibility: hidden;
        width: 28px;
        height: 28px;
        display: inline-flex;

        &:before {
          font: 28px "Material Icons Round";
          content: "\e5cd";
          visibility: visible;
          color: $clr_darkblue6;
        }
      }
    }
  }

  .modal-body {
    padding: 15px 0 30px;

    .remove-body,
    .activate-body,
    .noaccess-body,
    .inactive-body {
      text-align: center;
      padding: 26px 30px;
      border-radius: 8px;

      h2 {
        font-size: 15px !important;
        font-family: $font-family;
      }
    }

    .remove-body {
      background-color: #fff3f2;

      h2 {
        color: $clr_red2;
      }
    }

    .info-body {
      background-color: #fff;

      h2,
      h3 {
        color: #000;
      }
    }

    .activate-body {
      background-color: $clr_lightgreen;

      h2 {
        color: $clr_green;
      }
    }

    .noaccess-body {
      background-color: $clr_lightgrey9;

      h2 {
        color: $clr_grey3;
      }
    }

    .inactive-body {
      background-color: #fdf5dd;

      h2 {
        color: #87702f;
      }
    }
  }

  .modal-footer {
    padding: 0;
    font-size: $font-size-h6;
  }

  &.modal-w-header {
    .modal-header {
      background-color: transparent;
      border: 0;

      .modal-title {
        color: $clr_darkblue6;
        font-size: $font-size-h3 !important;
        font-weight: bold;
        line-height: 1.2;
        width: 100%;

        .show-event-modal-header {
          width: 100%;
        }
      }
    }

    .modal-body {
      padding-top: 20px;

      .title-label {
        margin-bottom: 4px;
      }

      .row-item {
        margin-bottom: 16px;
      }
    }

    .modal-footer {
      border: 0;
    }
  }

  &.modal-dialog-scrollable {
    .modal-header {
      padding: 0 35px 10px 0;
    }
  }

  &.comments-modal {
    .modal-body {
      padding: 5px 0 30px;
    }

    .comment-widget {
      li.list-group-item {
        padding-left: 15px;
        border-radius: 5px;
      }
    }
  }

  &.company-segments-modal {
    .company-segments-files-table-block {
      .responsive-table {
        max-height: 400px;
      }
    }
  }
}

.custom-calendar-modal {
  &.noaccess-modal {
    &.modal-dialog {
      @media (min-width: 576px) {
        max-width: 450px;
        margin: 1.75rem auto;
      }
    }
  }

  .modal-content {
    height: 100%;
    border-radius: 4px;
  }

  .modal-header {
    padding: 0;
    position: relative;
    border: 0;
    display: flex;
    align-items: center;

    h5.modal-title {
      margin: 0;
      text-align: left;

      h3 {
        margin: 0;
      }
    }

    .close {
      padding: 2px;
      display: inline-flex;
      outline: none;
      position: static;
      margin: 0;
      width: 30px;
      height: 30px;

      span {
        visibility: hidden;
        width: 28px;
        height: 28px;
        display: inline-flex;

        &:before {
          font: 28px "Material Icons Round";
          content: "\e5cd";
          visibility: visible;
          color: $clr_darkblue6;
        }
      }
    }
  }

  .modal-body {
    padding: 25px;

    .remove-body,
    .activate-body,
    .noaccess-body,
    .inactive-body {
      text-align: center;
      padding: 26px 30px;
      border-radius: 8px;

      h2 {
        font-size: 15px !important;
        font-family: $font-family;
      }
    }

    .remove-body {
      background-color: #fff3f2;

      h2 {
        color: $clr_red2;
      }
    }

    .info-body {
      background-color: #fff;

      h2,
      h3 {
        color: #000;
      }
    }

    .activate-body {
      background-color: $clr_lightgreen;

      h2 {
        color: $clr_green;
      }
    }

    .noaccess-body {
      background-color: $clr_lightgrey9;

      h2 {
        color: $clr_grey3;
      }
    }

    .inactive-body {
      background-color: #fdf5dd;

      h2 {
        color: #87702f;
      }
    }
  }

  .modal-footer {
    padding: 0;
    font-size: $font-size-h6;
  }

  &.modal-w-header {
    .modal-header {
      background-color: transparent;
      border: 0;

      .modal-title {
        color: $clr_darkblue6;
        font-size: $font-size-h3 !important;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    .modal-body {
      padding-top: 20px;

      .title-label {
        margin-bottom: 4px;
      }

      .row-item {
        margin-bottom: 16px;
      }
    }

    .modal-footer {
      border: 0;
    }
  }

  &.modal-dialog-scrollable {
    .modal-header {
      padding: 0 35px 10px 0;
    }
  }

  &.comments-modal {
    .modal-body {
      padding: 5px 0 30px;
    }

    .comment-widget {
      li.list-group-item {
        padding-left: 15px;
        border-radius: 5px;
      }
    }
  }

  &.company-segments-modal {
    .company-segments-files-table-block {
      .responsive-table {
        max-height: 400px;
      }
    }
  }
}

.cmp-seg-files-text {
  font-weight: 400;
  color: #9aa0a9;
  margin-left: 4px;
  font-size: 16.5px;
}

.comp-seg-modal-files-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // height: 40px;
  // background-color: red;
}

.comp-files-modal-para {
  color: #555555;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 5px;
}

.visibility-hidden {
  visibility: hidden;
}

.boardcard-hide {
  display: none;
}

.boardcard-hide {
  display: none;
}

.faqactive {
  color: #00aa5d;
}

#tooltip-expand-all,
#tooltip-collapse-all {
  margin-top: 0 !important;
}

.no-cursor {
  cursor: default;
}

.sessionPopup {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  background-color: white;
  cursor: move;
  min-width: 300px;
  // @media(min-width: 576px){
  //   min-width: 300px;
  // }
  @media (min-width: 992px) {
    left: calc(50% - 76px);
    transform: translateX(calc(112px - 50%));
  }
  @media (min-width: 1366px) {
    left: calc(50% - 88px);
    transform: translateX(calc(126px - 50%));
  }

  .modal-header {
    justify-content: center;
  }

  .modal-content {
    padding: 10px 14px 12px;
  }

  .modal-btn,
  .modal-footer .modal-btn {
    min-width: 70px;
    max-height: 28px;
    min-height: 28px;
    line-height: 28px;
  }
}

.drag-box {
  cursor: move;
}

.input_search {
  width: 168px;
  height: 31px;
  padding: 4px;
  background: #fefefe;
  border: 1px solid #e4e4e4;
  line-height: 35px;
  border-radius: 5px;

  i {
    font-size: 14px !important;
  }

  .search_text {
    margin: 0 8px;
    font-size: 12px;
  }

  .slash_btn {
    width: 20px;
    height: 20px;
    padding: 3px;
    border: 1px solid #e9ebee;

    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      font-size: 11px;
      font-weight: 700;
    }
  }
}

.globalSearch-div {
  background-color: #fff;
  height: calc(100vh - 100px);
  overflow-y: auto;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;

  .globalSearch-section {
    width: 90%;
    margin: 0 auto;
    padding: 16px 50px;
    height: calc(100vh - 180px);
    overflow-y: auto;

    .search-content-section {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      padding-top: 15px;
      background: #fff;

      .back_arrow {
        position: absolute;
        top: 6px;
        left: -50px;
        cursor: pointer;
        z-index: 999;
      }

      .input_div {
        padding-bottom: 15px;
        width: 100%;

        &::after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 1px solid #e9ebee;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        input {
          background: #fefefe;
          border: 1px solid #e4e4e4;
          border-radius: 5px;
          padding: 8px 65px 8px 20px;
          outline: none;
        }

        .search_icon {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          cursor: pointer;
          color: $clr_lightgrey2;
          // pointer-events: none;
        }

        .close_icon {
          position: absolute;
          top: 10px;
          right: 40px;
          font-size: 20px;
          cursor: pointer;
          color: $clr_blue_tooltip;
        }

        .search_results {
          background: #fefefe;
          border: 1px solid #e4e4e4;
          border-radius: 0 0 5px 5px;
          border-top: 0;
          padding-bottom: 0;
          position: absolute;
          top: 36px;
          left: 0;
          right: 0;
          z-index: 999999;

          &::before {
            content: "";
            display: block;
            width: 97%;
            height: 1px;
            background-color: $clr_lightgrey4;
            position: absolute;
            left: 20px;
            top: 0;
            bottom: 0;
          }

          ul {
            max-height: 370px;
            overflow-y: auto;
          }

          ul li {
            position: relative;
            padding: 8px 65px 8px 20px;
            cursor: pointer;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
              background-color: $clr_lightgrey3;

              &::before {
                content: "";
                display: block;
                width: 3px;
                height: 100%;
                background-color: $clr_blue;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
              }
            }
          }

          ul li .search_icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 20px;
            cursor: pointer;
            color: $clr_lightgrey2;
            pointer-events: none;
          }
        }
      }
    }

    .search-content-box {
      margin-top: 15px;
      border: 1px solid #cfd4d9;
      border-radius: 5px;
      padding: 14px 20px;
      cursor: pointer;

      &:hover {
        background-color: #f1f4fb;
      }

      .breadcrumbs {
        color: #5f5f5f;
        font-size: 12px;
      }

      .text-heading {
        font-size: 18px;
        color: #000;
        margin: 4px 0;
        font-weight: 600;
      }

      .text-description {
        font-size: 13px;
        color: #000;
        font-weight: 600;
      }
    }
  }
}

.sidebar_section {
  border-radius: 9px;
  height: calc(100vh - 70px);
  margin: 6px 0 6px 9px;
  z-index: 99999;
  position: relative;
  white-space: nowrap;
  padding-top: 50px;

  .togggle_circle {
    width: 26px;
    height: 26px;
    background-color: $clr_white;
    box-shadow: 0 3px 6px #00000033;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 16px;
    transition: all 0.5s ease-in-out;
    padding: 3px;
    cursor: pointer;

    .blue_circle {
      background-color: #0079f5;
      border-radius: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .double_arrow {
        color: $clr_white;
        font-size: 16px;
      }
    }
  }

  &::before {
    background: transparent linear-gradient(180deg, #0b2540, #003770) 0 0
      no-repeat padding-box;
    height: 100%;
    width: 70px;
    content: "";
    display: block;
    border-radius: 9px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .parent_ul {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.5s ease-in-out;

    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }

    .material-icons-outlined {
      vertical-align: middle;
    }

    li.nav-item {
      .label_name {
        font-weight: 600;
        font-size: 14px;
      }

      .not-dropdown {
        &:hover {
          .material-icons-outlined {
            transition: all 0.5s ease-in-out;
            color: #1e81e8;
            background-color: #08396c;
            border-radius: 4px;
          }
        }
      }
    }

    .material-icons-outlined {
      font-size: 20px;
      vertical-align: middle;
      transition: all 0.5s ease-in-out;
    }

    li.nav-item {
      padding: 0 6px;

      &:hover {
        .material-icons-outlined {
          transition: all 0.5s ease-in-out;
          color: #1e81e8;
          background-color: #08396c;
          border-radius: 4px;
        }
      }

      &.active {
        .material-icons-outlined {
          color: #1e81e8;
          background-color: #08396c;
          border-radius: 4px;
        }
      }
    }

    input {
      display: none;
    }
  }

  ul li {
    cursor: pointer;
    text-align: left !important;

    &.nav-item {
      padding: 0 8px;

      .label_name {
        width: 150px;
        padding: 6px;
        display: inline-block;
        border-radius: 4px;
        margin-left: 30px;
        vertical-align: middle;
        color: #000;
      }

      .material-icons-outlined,
      .material-icons-round {
        color: $clr_lightgrey2;
      }

      .material-icons-outlined {
        padding: 6px;
      }

      &.active {
        a .label_name {
          background: $clr_solitude;
          border-radius: 4px;
          color: $clr_lightblue3;
          font-weight: 600;

          &:before {
            background: $clr_lightblue2;
            opacity: 0;
          }
        }
      }

      &:hover {
        a .label_name {
          transition: all 0.5s ease-in-out;
          background: $clr_solitude;

          &:before {
            background: $clr_solitude;
            opacity: 1;
          }
        }
      }
    }
  }
}

.sticky-section-search {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $clr_white;

  .select_companies_search {
    position: relative;

    input {
      border: 0 !important;
      box-shadow: none !important;
      font-size: 14px;
    }

    span {
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 20px;
    }
  }
}

.global-search-pagination {
  position: sticky;
  bottom: 0;
  padding-left: 25px;
  background: #fff;
  padding-bottom: 10px;
}

.tbri-text-highlighter {
  background: yellow;
  //z-index: 100;
}

.terms-conditions {
  color: #99a0aa;
  bottom: 2%;
  left: 0%;
  right: 0%;
  position: absolute;

  .links {
    margin-right: 10px;
    font-size: 14px;
    margin-left: 10px;
  }

  .links:hover {
    color: white;
  }
}

// DASHBOARD
.dashboard_header_section {
  min-height: 276px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  .dashboard_img {
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 0%;
    height: 256px;
    object-fit: cover;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .heading-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: -80px;

    .header {
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      color: #ffffff;
      max-width: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 556px) {
        font-size: 24px;
      }
    }

    .search-bar,
    .search-bar-without-border {
      top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60%;
      height: 45px;
      // margin-top: 20px;
      background-color: #fff;
      border-radius: 8px;
      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        width: 80%;
        height: 40px;
      }
      @media (max-width: 556px) {
        font-size: 16px;
      }
    }

    .search-bar-without-border {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .search-bar-input {
      width: 92%;
      border: none;
      outline: none;
      height: 100%;
      background-color: transparent;
      padding-left: 25px;
      border-radius: 8px;
      // background-color: red;
    }

    .dashboard-close-icon {
      font-size: 16px;
      cursor: pointer;
      margin-top: 5px;
    }

    .line {
      height: 60%;
      width: 1px;
      background-color: #cfd4d9;
      margin-left: 15px;
    }

    .icon-color {
      color: #046cd9;
    }

    .icon-div {
      width: 40px;
      margin-top: 5px;
      margin-left: 15px;
      cursor: pointer;
    }

    .export-btn {
      padding: 8px 4px;
      height: 40px;
      background: #4d88c9;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      color: white;
      outline: none;
      border: none;
      cursor: pointer;
    }

    .search-bar-option {
      overflow: hidden;
      width: 60%;
      z-index: 1;
      position: absolute;
      top: 168px;
      height: fit-content;
      border-color: #e4e4e4;
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-radius: 0 0 10px 10px;
      background-color: #fff;

      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        width: 80%;
      }

      .search-element {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0 10px 25px;
        cursor: pointer;
        // background-color: green;
      }

      .search-element:hover {
        background-color: #e9eefa;
      }

      .icon-color {
        color: rgb(158, 158, 158);
        margin-right: 20px;
      }
    }
  }
}

.selected-search-element {
  background-color: #e9eefa;
}

.legend-text {
  margin-top: 14px;
  margin-left: 10px;
  font-size: 14.5px;
  color: #5e5e5e;
  text-align: left;
  font-weight: 500;
}

.pie_chart_container {
  // background-color: #00aa5d;
  display: flex;
  align-items: center;
}

.pie-legend-dots {
  width: 13px;
  height: 13px;
  border-radius: 2px;
}

.pie-chart-not-found {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
  font-size: 13px;
  margin-top: 25px;
}

.pie_chart_legend .legend-item {
  font-size: 0.9rem;
}

.pie_chart_legend .legend-item .legend-label {
  color: initial;
}

.pie_chart_legend .legend-item .legend-value {
  font-size: 1.2rem;
}

.pie_chart_legend .legend-item .legend-marker {
  width: 1rem;
  height: 1rem;
  border-radius: 0.75rem;
  margin-right: 0.75rem;
}

.dashboard-middle-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: -15px;

  .dashboard-left {
    padding-right: 15px;
    width: 70%;
    height: 100%;
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      width: 100%;
      padding-right: 0;
    }
  }

  .dashboard-right {
    padding-left: 10px;
    width: 30%;

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      width: 100%;
      display: flex;
      padding-left: 0;
      justify-content: space-between;
    }
  }
}

// UNCOMMENT THIS CODE WHEN NEW CARD DESIGN COME

// .dashboard-card {
//   position: relative;
//   // box-shadow: 0 10px 30px rgba(17, 38, 146, 0.05);
//   border-radius: 8px;
//   height: 150px;
//   width: 140px;
//   display: flex;
//   flex-direction: column;
//   padding-top: 12px;
//   align-items: center;
//   text-align: center;
//   cursor: pointer;
//   transition: all 0.3 ease-in;
//   @media only screen and (min-width: 768px) and (max-width: 1200px) {
//     height: 70px;
//   }
// }

// .dashboard-card:hover {
//   border: #2e6ad2 1.5px solid;
//   box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
// }

// .radialProgressBar {
//   text-align: center;
//   margin-left: 10px;
//   border-radius: 50%;
//   width: 85px;
//   height: 85px;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   background: #ddd;
//   @media (max-width: 556px) {
//     width: 20px;
//     height: 20px;
//   }
//   /* Small Devices, Tablets */
//   @media only screen and (min-width: 768px) and (max-width: 1200px) {
//     width: 20px;
//     height: 20px;
//   }
// }
// .radialProgressBar .overlay {
//   border-radius: 50%;
//   width: 75px;
//   height: 75px;
//   margin: auto;
//   background: #fff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   @media (max-width: 556px) {
//     width: 15px;
//     height: 15px;
//   }
//   /* Small Devices, Tablets */
//   @media only screen and (min-width: 768px) and (max-width: 1200px) {
//     width: 15px;
//     height: 15px;
//   }
// }
// .progress-90 {
//   background-image: linear-gradient(63deg, #3f7fe0 50%, transparent 50%),
//     linear-gradient(270deg, #3f7fe0 50%, #ddd 50%);
// }
// .overlay-icon {
//   font-size: 25px;
//   color: #bdbdbd;
//   @media (max-width: 556px) {
//     font-size: 18px;
//   }
//   /* Small Devices, Tablets */
//   @media only screen and (min-width: 768px) and (max-width: 1200px) {
//     font-size: 14px;
//   }
// }
// .top-card-value {
//   font-size: 18px;
//   color: #3377ed;
//   font-weight: 600;
// }
// .company-name-section {
//   margin-left: 10px;
//   @media (max-width: 556px) {
//     margin-left: 10px;
//   }

// New Hero card design
.dashboard-card {
  position: relative;
  text-align: center;
  background: linear-gradient(180deg, #3f83ce 0%, #215b9a 57.29%);
  border-radius: 12px;
  // width: 175px;
  width: 100%;
  min-width: 160px;
  font-size: 16px;
  color: #fff;
  padding: 4px;
  cursor: pointer;
  transition: all 0.3 ease-in;
}

.dashboard-card:hover {
  box-shadow: rgba(255, 255, 255, 0.1) 0 1px 1px 0 inset,
    rgba(50, 50, 93, 0.25) 10px -5px 19px 0,
    rgb(255, 255, 255) 0 -10px 60px -30px;
}

.card-top-icon {
  padding: 5px;
  position: absolute;
  background-color: #fff;
  color: #28598f;
  border: solid 1px #3f83ce;
  background-color: #fff;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
}

.name-tiles {
  font-size: 18px;
  padding: 16px 0 0 0;
  font-weight: 600;
}

.linearProgressBar {
  width: auto;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  overflow: hidden;
  margin: 8px 4px;
}

.currentProgress {
  height: 100%;
  width: 0%;
  background-color: #fff;
  border-radius: 5px;
}

.board-arrow {
  position: absolute;
  bottom: 0;
  right: 13px;
}

.current-total {
  font-size: 20px;
  font-weight: 600;
}

// End of new hero card design
.board-container {
  // background-color: red;
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    width: 73.5vh;
  }
}

.dashboard-content,
.dashboard-content-board {
  box-shadow: 0 10px 13px rgba(17, 38, 146, 0.05);
  border-radius: 8px;

  .dashboard-company-line {
    // margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    height: 1px;
    background-color: #e9ecef;
  }
}

.dashboard-content-board {
  width: 100%;
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    width: 100%;
  }
}

.status-of-week,
.status-para {
  font-weight: 600;
  font-size: 17px;
  line-height: 175%;
  color: #232d42;
  margin-left: 10px;
  margin-right: 10px;
}

.status-para {
  font-weight: 400;
}

.dashboard-content-header,
.dashboard-company-header,
.dashboard-board-header {
  border-bottom: 1px solid #e9ecef;
  margin: 0 10px 0 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .dashboard-title,
  .company-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: #232d42;
  }

  .view-all-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    color: #3f7fe0;
    cursor: pointer;
  }

  .company-title {
    cursor: pointer;
    margin-right: 30px;
    padding-bottom: 10px;
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      font-size: 14px;
    }
  }
}

.GenAIHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 18px;
  background-image: url("../../src/Images/GenAi_bg.png");
  background-size: cover;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 45px;
  .header-content {
    align-items: center;
  }
  .header-title {
    font-size: 17px;
    font-weight: 600;
    font-family: "Montserrat";
  }
}

.dashboard-board-header {
  margin: 0 0 0 0;
}

.view-all-text-table {
  font-weight: 600;
  font-size: 16px;
  line-height: 175%;
  color: #3f7fe0;
  margin-top: 10px;
  cursor: pointer;
}

.dashboard-company-header {
  min-height: 22px;
  border-bottom: 1px solid #e9ecef;
  margin-right: 20px;
  justify-content: flex-start;

  &.module-table {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px 0 0;

    .company-title {
      white-space: nowrap;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    min-height: 15px;
  }

  .selected-tab {
    padding-bottom: 8px;
    color: #3377ed;
    border-bottom: 2px solid #3377ed;
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      padding-bottom: 8px;
    }
  }
}

.dashboard-board-card,
.dashboard-report-card,
.dashboard-recent-card,
.dashboard-recent-report-card,
.no-board {
  background: #f4f5f8;
  border-radius: 4px;
  padding: 10px 15px;
  height: 110px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;

  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 175%;
    border-bottom: 1px solid #dfdfdf;
    color: #3f7fe0;
    padding-bottom: 5px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    color: #000000;
  }
}

.no-board {
  height: 40px;
  width: 100%;
}

.dashboard-report-card-container {
  width: 100vw;
  margin-top: 10px;
  display: flex;
}

.dashboard-report-card,
.dashboard-recent-card,
.dashboard-recent-report-card {
  background: #f4f5f8;
  height: 120px;
  width: auto;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 10px;
  box-shadow: 1px 2px 5px 0 rgba(187, 197, 199, 1);

  .report-heading {
    font-weight: 600;
    font-size: 18px;
    color: #3f7fe0;
  }
}

.GenAI-report-card {
  background: #f4f5f8;
  height: 90px;
  width: auto;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 10px;
  box-shadow: 1px 2px 5px 0 rgba(187, 197, 199, 1);
  padding: 20px;
  .report-heading {
    font-weight: 600;
    font-size: 18px;
    color: #3f7fe0;
  }
}

.GenAI-carousel-button-group {
  position: absolute;
  top: -48%;
  right: 20px;

  .arrow-icon {
    width: 30px;
    height: 30px;
    background: #3477ed;
    border-radius: 50%;
    outline: none;
    border: none;
    color: white;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(17, 38, 146, 0.09);
    text-align: center;
    padding-top: 5px;
  }

  .disable {
    background: #adb5bd !important;
    color: white !important;
    cursor: default;
  }
}

.dashboard-recent-card {
  height: 92px !important;
  padding: 15px 15px !important;
}

.report-text-container {
  display: flex;
}

.report-line {
  width: 100%;
  height: 1px;
  background: #dfdfdf;
  margin-top: -10px;
  margin-bottom: 8px;
}

.visualization-line {
  margin-top: 4px;
}

.report-text-container p {
  color: #000;
  font-size: 15px;
  font-family: "Source Code Pro", monospace;
  font-weight: 500;
}

.report-para {
  color: #000;
  font-weight: 600;
}

.dashboard-company-content {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  max-height: 300px;
}

.dashboard-company-content1 {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
}

.table-height {
  max-height: 300px !important;
}

// want to add padding when new design card arrived
.dashboard-herocard-section {
  display: grid;
  margin: 20px 0;
  gap: 20px;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  /* Small Devices, Tablets */
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
}

@media (max-width: 1342px) {
  .dashboard-herocard-section {
    margin: 0 0 12px 0 !important;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-x: scroll;
  }
}

@media (max-width: 676px) {
  .dashboard-herocard-section {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    row-gap: 36px;
    overflow: visible !important;
  }
}

.carousel-main-container {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  width: 100vw;
}

.GenAI-carousel-main-container {
  // position: relative;
  // margin-left: 10px;
  // margin-right: 10px;
  // width: 100vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
  max-width: 100%;
}
.GenAI-carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 100px; /* Adjust item height as needed */
  width: calc(
    50% - 10px
  ); /* Adjust width to fit two items per row, considering the gap */
  margin-bottom: 10px; /* Adjust spacing between rows */
}

.css-tooltip,
.css-tooltip-2 {
  position: relative;
}

.css-tooltip:before,
.css-tooltip-2:before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 15px; /* and add a small left margin */
  width: fit-content;
  min-height: 35px;
  padding: 10px 15px;
  border-radius: 2.5px;
  background: rgb(41, 41, 41);
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  display: none; /* hide by default */
}

.css-tooltip.bottom:before {
  left: initial;
  margin: initial;
  top: 100%;
  margin-top: 25px;
}

.css-tooltip-2.bottom:before {
  left: initial;
  margin: initial;
  bottom: 0;
  margin-top: 25px;
  height: fit-content;
  min-height: 30px;
  width: fit-content;
  padding: 4px 8px;
}

.pie-info,
.adv-pref-info {
  width: 60px;
  padding: 2px 10px;
  background-color: #e5e8e7;
  border-radius: 3px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.adv-pref-info {
  width: 40px;
  margin-left: 0;
}

.dashboard-back-btn {
  cursor: not-allowed;
  padding: 2px 10px;
  width: 60px;
  border-radius: 2px;
  border: none;
  outline: none;
  background-color: #e5e8e7;
  color: #191a19;
  font-weight: 600;
}

.pie-chart-report-card {
  width: 350px;
  height: fit-content;
  padding: 20px;
  background-color: #eff2f1;
  border-radius: 4px;
  margin-top: 50px;
  margin-left: 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.pie-chart-head,
.pie-chart-head-2,
.pie-chart-head-3 {
  font-size: 20px;
  color: rgb(16, 133, 198);
  font-weight: 600;
  margin-bottom: 20px;
}

.pie-chart-head-2 {
  font-size: 18.5px;
  color: #1678cf;
}

.pie-chart-head-3 {
  font-size: 17.5px;
  color: #09579e;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 320px;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 110px;
  // background-color: green;
  margin-top: 20px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 305px;
  height: 305px;
  margin: 10px;
  box-sizing: border-box;
  border: 150px solid #09579e;
  border-color: #09579e transparent #003870 transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.35, 0.475, 0.15);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.65, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.css-tooltip:hover:before,
.css-tooltip:hover:after,
.css-tooltip-2:hover:before,
.css-tooltip-2:hover:after {
  display: block;
}

.data-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 380px;
  // background-color: green;
}

.data-not-found p {
  margin-top: 30px;
  font-size: 15px;
  font-family: "Source Code Pro", monospace;
  color: rgb(87, 86, 86);
}

.board-main-container {
  padding-top: 20px;
  overflow-x: auto;
}

.dashboard-past-days {
  margin-top: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;

  .dashboard-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 175%;
    color: #232d42;
  }

  .view-all-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 175%;
    color: #3f7fe0;
    margin-right: 20px;
  }
}

.recent-view-collapse-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
}

.recent-viewed-container {
  margin-top: 18px;
  width: 100%;
  border-radius: 8px;
  background-color: #5b87c4;
  padding: 15px 15px 15px 15px;
}

.upcoming-event {
  height: 70px;
  margin-top: 18px !important;
  width: 100%;
  border-radius: 8px;
  padding: 15px 15px 15px 15px !important;
}
.upcoming-event-header {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.webinar-upcoming-event {
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  background-color: #d6eeff;
  border-left: 4.5px solid #046cd9 !important;
}
.publish-calendar-event {
  display: block !important;
}
.recent-view-header {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.carousel-button-group {
  position: absolute;
  top: -38%;
  right: 20px;

  .arrow-icon {
    width: 30px;
    height: 30px;
    background: #3477ed;
    border-radius: 50%;
    outline: none;
    border: none;
    color: white;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(17, 38, 146, 0.09);
    text-align: center;
    padding-top: 5px;
  }

  .disable {
    background: #adb5bd !important;
    color: white !important;
    cursor: default;
  }
}

.back-forth {
  width: 200px;
  height: 50px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-select {
  color: #046cd9;
}

.toggle-unselect {
  color: #86909d;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider,
.status-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e81e8;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before,
.status-slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2.8px;
  bottom: 2.5px;
  border-radius: 50%;
  background-color: #0c2641;
  transition: 0.4s;
}

.slider:before {
  left: 18px;
  background-color: #ffffff;
}

.status-slider:before {
  background-color: #f8fafc;
}

input:checked + .slider {
  background-color: #8e98a8;
}

.custom-switch-checkbox input:checked + .slider {
  background-color: #ffffff;
}

input:checked + .status-slider {
  background-color: #1e81e8;
}

input:checked + .slider:before {
  transform: translateX(-15px);
}

input:checked + .status-slider:before {
  transform: translateX(15px);
}

.client-admin-popup {
  background-color: #55926c;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-cancle-btn {
  width: 30px;
  height: 30px;
  background-color: #88e1a9;
  border-radius: 5px;
  margin-right: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company-icons {
  display: flex;
  align-items: center;
  justify-content: center;

  .star-icon {
    font-size: 10px;
  }
}

.preference-container {
  display: flex;
  margin-top: 60px;
}

.preference-sidebar {
  width: 300px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.preference-sidebar-div {
  height: 70vh;
  overflow-y: scroll;
}

.preference-head {
  font-size: 19px;
  font-weight: 600;
  color: black;
  margin-left: 8px;
}

.collapser-container {
  border-left: 1px solid #9aa0a9;
  margin-top: 10px;
  margin-left: 10px;
  // overflow-y: scroll;
}

.pref-collapser-item {
  margin-top: 15px;
  margin-left: 30px;
}

.pref-collapser-sub-item {
  margin-left: 10px;
}

.preference-collapse,
.preference-collapse-2,
.sub-preference-collapse {
  width: 220px;
  height: 37px;
  border-radius: 6px;
  background-color: #2e6ad2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preference-collapse-2 {
  background-color: #f1f7fe;
  border: 1px solid #2e6ad2;
}

.sub-preference-collapse {
  width: 198px;
  height: 34px;
  background-color: #f1f7fe;
  border: 1px solid #2e6ad2;
  border-radius: 2px;
}

.pref-collapse-header,
.pref-collapse-header-2 {
  color: white;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 10px;
}

.pref-collapse-header-2 {
  color: #555555;
}

.pref-collapse-list {
  cursor: pointer;
  max-height: 70vh;
  overflow-y: scroll;
}

.list-height {
  height: 145px !important;
  overflow-y: hidden;
}

.sub-pref-list-item,
.sub-pref-list-item-selected {
  margin-left: 10px;
  padding: 0 18px;
  height: 47px;
  border-left: 1px solid #9aa0a9;
  display: flex;
  align-items: center;
}

.sub-pref-list-item-selected {
  border-left: 1px solid #2e6ad2;
}

.pref-list-name,
.pref-list-name-selected {
  font-size: 14px;
  color: #555555;
  margin-top: 15px;
  margin-left: 10px;
  font-weight: 600;
}

.pref-list-name-selected {
  color: #2e6ad2;
}

.preference-content {
  position: fixed;
  margin-top: 12px;
  border-radius: 8px;
  margin-left: 315px;
  height: 85%;
  width: calc(100% - 330px);
  // background-color: #ffffff;
  // box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  // padding: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pref-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 8px;
  padding: 20px;

  // padding: 5px;
  // border: 0.5px solid #adb5bd;
  // border-radius: 3px;
}

.pref-content-main-part {
  // position: fixed;

  border-radius: 8px;
  // margin-left: 315px;
  height: 85%;
  // width: calc(100% - 330px);
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  padding: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pref-email-head {
  color: #2c3957;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  margin: 0px !important;
  align-items: center;
}

.pref-email-para {
  color: #555555;
  margin-top: -7px;
  font-size: 14px;
}

.digest-btn,
.immediate-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 162px;
  height: 34px;
  border: 1px solid #e9ebed;
  border-radius: 2px;
  margin-bottom: 4px;
  margin-top: -10px;
}

.immediate-btn {
  margin-bottom: 0;
  margin-top: 0;
}

.freq-text {
  font-size: 13px;
  color: #2e6ad2;
  font-weight: 600;
  border-bottom: 1px solid #2e6ad2;
  margin-bottom: 0;
  cursor: pointer;
}

.select-digest,
.select-immediate {
  color: #ffffff;
  font-weight: 600;
  background: #2e6ad2;
  border-radius: 2px;
  padding: 2px 12px;
  cursor: pointer;
}

.select-immediate {
  color: #2c3957;
  background: transparent;
  padding: 2px 10px;
}

.pref-content,
.pref-content-selected {
  margin-top: 20px;
  height: auto;
  border-radius: 2px;
  padding: 12px 25px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  scroll-margin: 10px;
  border: 0.5px solid #c4c2c2;
  background-color: #fcfcfc;
}

// .pref-content-selected {
//   border: 0.5px solid #eeecec;
//   background-color: #F9F9F9;
// }
.content-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #a89999;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.pref-h3 {
  font-weight: 600;
  font-size: 20px;
  color: #232d42;
}

.pref-select-all {
  color: #2e6ad2;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

.pref-input-text,
.pref-seg-text-blue,
.pref-seg-input-text,
.pref-seg-disable-text {
  margin-top: 15px;
  margin-left: 10px;
  font-size: 14px;
  color: #555555;
}

.pref-seg-input-text,
.pref-seg-disable-text {
  color: #8c94a0;
  font-size: 13px;
  font-weight: 600;
}

.pref-seg-disable-text {
  color: #adb7c4;
}

.pref-seg-text-blue {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 600;
  color: #2e6ad2;
}

input[type="checkbox"] {
  // accent-color: #5B87C4;
  accent-color: #2e6ad2;
  width: 14px;
  height: 14px;
  font-weight: lighter;
  font-size: 12px;
  cursor: pointer;
}

.pref-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pref-modal-content h3 {
  color: #2c3957;
  font-size: 20px;
  font-weight: 600;
}

.pref-modal-content p {
  color: #9aa0a9;
  font-size: 13px;
  margin-top: -5px;
}

.pref-radio-text {
  font-size: 16px;
  margin-left: 10px;
  color: #2c3957;
  font-weight: 600;
}

.pref-scroller {
  overflow: scroll;
  height: 85%;
  width: 100%;
}

.pref-scroller::-webkit-scrollbar {
  display: none;
}

.btn-adv-preference,
.btn-disable-adv-preference {
  padding: 7px 12px;
  background-color: #2e6ad2;
  font-size: 14px;
  border: none;
  outline: none;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.btn-disable-adv-preference {
  cursor: not-allowed;
  opacity: 0.7;
}

.modal-adv-preference {
  width: 900px;
  height: 500px;
}

.pref-header-divider {
  width: 100%;
  height: 1px;
  background-color: #cdbfbf;
  margin-top: 2px;
}

.adv-pref-head {
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.pref-cmp-notification-text {
  color: #2c3957;
  font-size: 16px;
  font-weight: 600;
  width: 190px;
  margin-right: 5px;
}

.pref-noti-container,
.pref-noti-container-disable {
  display: flex;
  margin-bottom: 15px;
  margin-top: 12px;
}

.pref-noti-container-disable {
  pointer-events: none;
  opacity: 0.7;
}

.adv-pref-arrow,
.adv-pref-arrow-move {
  color: #2e6ad2;
  opacity: 0.5;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-right: 55% !important;
}

.adv-pref-arrow-move {
  opacity: 1;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-left: 85% !important;
}

.files-no-record-found-text {
  font-size: 20px;
  color: #232d42;
  margin-top: 20px;
  font-weight: 600;
}

.files-loader,
.files-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.files-loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #ebf3ff;
  border-right: 1.1em solid #ebf3ff;
  border-bottom: 1.1em solid #ebf3ff;
  border-left: 1.1em solid #2e6ad2;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  z-index: 9;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pref-seg-comp-container {
  height: 420px;
  width: 100%;
  display: flex;

  .pref-segment-container,
  .pref-segment-container-modal {
    height: 100%;
    width: 30%;
    border: 1px solid #c2d3e9;
    border-radius: 2px 0 0 2px;

    .pref-top-header {
      height: 45px;
      border-bottom: 1px solid #c2d3e9;
      display: flex;
      align-items: center;
      padding-left: 30px;

      p {
        font-size: 15px;
        color: #9aa0a9;
        margin-top: 12px;
        font-size: 15px;
        font-weight: 600;
      }
    }

    .pref-seg-list-container {
      width: 100%;
      height: 88%;
      padding: 15px 0 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: scroll;
    }
  }

  .pref-segment-container-modal {
    width: 47%;
  }

  .pref-company-container {
    height: 100%;
    width: 70%;
    border-top: 1px solid #c2d3e9;
    border-right: 1px solid #c2d3e9;
    border-bottom: 1px solid #c2d3e9;
    border-radius: 0 2px 2px 0;

    .pref-top-header {
      height: 45px;
      border-bottom: 1px solid #c2d3e9;
      display: flex;
      align-items: center;
      padding-left: 30px;

      p {
        font-size: 15px;
        color: #9aa0a9;
        margin-top: 12px;
        font-size: 15px;
        font-weight: 600;
      }
    }

    .pref-items-list-container {
      width: 100%;
      height: 88%;
      overflow: scroll;
      padding: 0 25px 0 30px;
    }
  }
}

.pref-cmp-collapse,
.pref-cmp-collapse-disable {
  width: 100%;
  border-radius: 4px;
  background-color: #ebf3ff;
  padding: 15px 15px 15px 15px;
  margin-top: 15px;
}

.pref-cmp-collapse-disable {
  opacity: 0.6;
}

.pref-cmp-collapse-header {
  font-size: 14px;
  color: #2c3957;
  font-weight: 600;
}

.pref-cmp-collapse-icon {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #2e6ad2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pref-segment-head,
.pref-seg-head-without-border {
  border-bottom: 0.5px solid #cdbfbf;
  padding-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.pref-seg-head-without-border {
  border-bottom: none;
}

.cmp-list-container {
  height: fit-content;
  margin-top: 5px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.cmp-content {
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  background-color: #2e6ad2;
  padding: 3px 10px;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pref-items-company-container {
  width: 100%;
  height: 85%;
}

.adv-modal-search-div {
  width: 100%;
  height: 40px;
  border-bottom: 0.5px solid #9aa0a9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adv-pref-search-input {
  height: 100%;
  width: 90%;
  outline: none;
  border: none;
  background: transparent;
  padding-left: 30px;
  font-size: 13px;
  color: #555555;
}

.adv-modal-cmp-container {
  width: 100%;
  height: 90%;
  overflow: scroll;
  position: relative;

  .not-found-item {
    position: absolute;
    left: 40%;
    top: 50%;
  }
}

.adv-modal-cmp-list {
  width: 100%;
  height: 40px;
  border-bottom: 0.5px solid #c2d3e9;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

// calendar styling
.webinar-left,
.webinar-left-published {
  width: 22%;
  height: 100%;
  padding: 20px 10px 0 22px;
  // background-color: #F4F5F4;
  border-right: 0.5px solid #9aa0a9;
  margin-right: 10px;

  h2 {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 175%;
    color: #2c3957;
  }
}

.webinar-left-published {
  width: 22%;
  padding: 40px 10px 0 40px;
}

.webinar-right {
  width: 80%;
  height: 100%;
  padding-top: 10px;
  padding-right: 10px;
  // background-color: red;
}

.webinar-collapse,
.published-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h3 {
    font-size: 15px;
    color: #555555;
    font-weight: 600;
  }
}

.published-collapse {
  margin-top: 20px;
}

.webinar-checkbox-label,
.published-checkbox-label {
  font-weight: 600;
  font-size: 12.56px;
  color: #555555;
  margin-left: 20px;
  letter-spacing: 0.3px;
  margin-top: 2px;
}

.published-calendar-icon {
  width: 20px;
  margin-left: 15px;
  margin-top: 2px;
}

.published-checkbox-label {
  margin-left: 10px;
}

.filterReports {
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 7px 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #f4f5f8;
  border: none;
  border-radius: 4px;
  min-width: 150px;
  width: auto;
  color: #84909e;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;

  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none !important;
  }
}

.filterReports:hover {
  .rw-placeholder {
    color: #00894d !important;
  }

  background-color: #b7ffe0;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subMenu-company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

.activeReports {
  .rw-placeholder {
    color: #00894d !important;
  }

  background-color: #b7ffe0;
  z-index: 0;
}

.report-type-text {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
  margin-left: 3px;
}

.dashboard-report-div {
  margin-right: 15px;
  display: flex;
  align-items: center;
  margin-top: -5px;

  input {
    cursor: pointer;
  }

  label {
    margin-left: 4px;
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 14px;
    color: #555555;
    margin-top: 8px;
  }
}

.table-report-title {
  color: #0079f5;
  cursor: pointer;
}

.table-report-title:hover {
  color: #0079f7;
}

.report-title_details {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
}

.beta-label {
  width: 45px;
  height: 24px;
  border-radius: 25px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
  margin-top: 1px;
}

.beta-badge {
  position: absolute;
  left: 30px;
  top: 2px;
  z-index: 10;
  background-color: gray;
  font-weight: 400;
}

.customer-report-name {
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
  color: #84909e;
  font-family: "Source Sans Pro";
}

.customer-detail-header {
  position: fixed;
  height: 70px;
  z-index: 9;
  width: 91%;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px;
}

.content-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.board-blur {
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  filter: blur(30px);
  position: relative;
}

.blur-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  filter: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;

  .lock-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #2e6ad2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    filter: none;
    color: black;
    font-size: 14.5px;
    text-align: center;
    margin-top: 12px;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.report-usage-toggle,
.report-usage-toggle-set {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border: 1px solid #4080e1;
}

.report-usage-toggle-set {
  background-color: #4080e1;
}

.toggle-white {
  color: #ffffff;
}

.toggle-blue {
  color: #4080e1;
}

.report-usage-table-left {
  // height: 340px;
  width: 240px;
  // background-color: red;
  border-right: 1px solid #bed4eb;
}

.selected-report-header,
.unselect-report-header {
  width: 99%;
  height: 40px;
  background-color: #dfecff;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 4px;

  p {
    font-size: 14px;
    font-weight: 600;
    margin-top: 14px;
    margin-left: 10px;
    color: rgb(0, 0, 0);
  }

  .percentage,
  .percentage-hover {
    width: 60px;
    height: 100%;
    background-color: #4080e1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #fff;
    font-size: 14px;
  }

  .percentage-hover {
    height: 80%;
    background-color: #eeeeee;
    color: rgb(0, 0, 0);
  }
}

.unselect-report-header {
  background-color: transparent;

  p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 14px;
    margin-left: 10px;
    color: rgb(0, 0, 0);
  }
}

.dropdown-report-div {
  width: 85%;
  cursor: pointer;
  height: 30px;
  // background-color: red;
  display: flex;
  margin-left: 10px;
  // margin-bottom: 5px;

  p {
    font-size: 13px;
    color: #000;
    margin-right: 10px;
  }
}

.report-usage-table-container {
  width: 70%;
  // background-color: red;
  padding-left: 20px;

  h2 {
    color: #2e6ad2;
    font-size: 18px;
    font-weight: 600;
    font-family: "Source Sans Pro";
  }
}

.per-value-text {
  margin-left: 45px;
  font-size: 13px;
  color: #000;
  margin-bottom: 0;
}

.vendor-specific-div {
  display: flex;
  // background-color: red;
  justify-content: space-between;
  align-items: center;

  .vendor-selected,
  .vendor-not-selected {
    cursor: pointer;
    font-size: 15.2px;
    font-weight: 600;
    color: #2e6ad2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -13px;
  }

  .vendor-not-selected {
    cursor: pointer;
    color: #000000;
  }

  .single-chart {
    width: 65px;
    justify-content: center;
    margin-top: -5px;
  }

  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
  }

  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 3;
    stroke-linecap: square;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.blue .circle {
    stroke: #6c95d1;
  }

  .percentage {
    fill: #000;
    font-size: 0.5em;
    text-anchor: middle;
    font-weight: 600;
  }
}

.report-usage-report-name {
  cursor: pointer;
  color: #000;
  font-size: 13.5px;
}

.report-usage-report-name:hover {
  color: #0079f5;
}

.content-save-btn {
  font-size: $font-size-12;
  float: right;
}

.collapse-icon-board {
  position: absolute;
  left: -22px;
  z-index: 999;
}

.board-collapse-panel-content {
  .board-item,
  .board-item-border {
    margin: 0 7px;
    padding: 8px 0 8px 25px;
    border-bottom: 0.5px solid $clr_tableborder;
    display: flex;
    align-items: center;
    // border-bottom: none;

    label {
      margin-left: 11px;
      color: $clr_blue;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      cursor: pointer;
    }

    input:checked {
      + label {
        color: $clr_blue;
        cursor: pointer;
      }
    }
  }

  .board-item {
    border-bottom: none;
  }
}

.add-edit-category-subcategory-popover {
  .popover {
    min-width: 255px;
    left: 230px !important;
    top: -4px !important;
  }

  .popover-body {
    padding: 10px 4px 14px;
  }

  .add-sub-category,
  .add-sub-category:not(:disabled):not(.disabled):active,
  .add-sub-category:not(:disabled):not(.disabled).active,
  .show > .add-sub-category.dropdown-toggle {
    background-color: unset;
    color: $clr_black2;
    border: none;
    margin: 6px 0 6px 12px;

    &:focus {
      box-shadow: none;
    }

    .icon {
      margin-right: 6px;
    }
  }

  .title-block {
    color: $clr_black2;
    margin-bottom: 12px;
  }

  .title-block .title {
    color: $clr_darkblue6;
  }

  input.form-control {
    font-size: 12px;
    border-radius: 5px;
    color: $clr_blue;
  }

  .title-block,
  .cat-subcat-inputs-block {
    padding-left: 10px;
    padding-right: 10px;
  }

  .cat-subcat-inputs-block {
    max-height: 250px;
    overflow: auto;
    padding-top: 3px;
    @media (min-width: 1600px) {
      max-height: 300px;
    }
  }

  .sub-category-inputs {
    margin-left: 16px;

    .input-wrapper {
      padding-bottom: 14px;

      input.form-control {
        color: $clr_green3;
      }

      &:last-child {
        padding-bottom: 3px;
      }

      position: relative;

      &::before,
      &::after {
        content: "";
        left: -16px;
        position: absolute;
        right: auto;
        z-index: -1;
      }

      &::before {
        border-left: 1px solid #cfd4d9 !important;
        bottom: 50px;
        height: 100%;
        top: -3px;
        width: 1px;
      }

      &:first-child::before {
        height: 100px;
        bottom: 0;
        top: unset;
      }

      &:last-child::before {
        height: 20px;
      }

      &:only-child::before {
        height: 90px;
        top: -74px;
      }

      &::after {
        border-top: 1px solid #cfd4d9 !important;
        height: 20px;
        top: 16px;
        width: 18px;
      }
    }
  }

  .save-btn-wrapper {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.board-popover-save-btn {
  padding: 4px 8px;
  font-size: 14px;
}

.board-collapse-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .head-container {
    padding: 7px 12px;
    border-radius: 4px;
    background-color: #e8f6ee;
    font-size: 17px;
    font-weight: 700;
    color: #2f7153;
    margin-left: 18px;
  }

  .board-collapse-add-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #e2e8f2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.bag-white {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Collapsible__trigger {
  &.is-open {
    .board-collapse-add-icon .add {
      display: none;
    }

    .board-collapse-add-icon .remove {
      display: flex;
    }
  }

  &.is-closed {
    .board-collapse-add-icon .add {
      display: flex;
    }

    .board-collapse-add-icon .remove {
      display: none;
    }
  }
}

.board-title-1 {
  width: 75%;
  margin-right: 0;
  color: #0561c1;
  margin-left: 0;
}

.collapse-item-container {
  min-height: 40px;
}

.board-map-header,
.selected-map-header {
  display: flex;
  height: 35px;
  align-items: center;
  cursor: pointer;

  span {
    color: #2e6ad2;
    margin-left: -1px;
  }

  p {
    font-size: 17px;
    color: #2e6ad2;
    margin-left: 3px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.selected-map-header {
  border-bottom: 2px solid #2e6ad2;

  p {
    margin-bottom: -2px;
  }

  span {
    margin-bottom: -2px;
  }
}

.map-category-container {
  width: 445px;
  min-height: 200px;
  max-height: 350px;
  margin-left: 18px;
  overflow-y: scroll;
  margin-top: 20px;

  .no-data-text {
    margin-top: 12px;
    font-weight: 600;
  }

  .map-category-item,
  .map-category-item-selected {
    width: 100%;
    padding: 12px 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d6d8dd;
    // margin-top: 2px;

    h3 {
      margin-bottom: 0;
      font-size: 16px;
      color: #12243e;
      font-weight: 600;
    }

    .map-design-div {
      width: 15px;
      height: 15px;
      border-left: 1.2px dashed #2e6ad2;
      border-bottom: 1.2px dashed #2e6ad2;
      margin-top: -4px;
      margin-right: 5px;
      margin-left: 10px;
    }

    .map-checkboxes-div {
      width: 50px;
      // background-color: #00894d;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }
  }

  .map-category-item-selected {
    background-color: #dcedff;
  }

  .map-item-content {
    background-color: #dcedff;
    border-bottom: 1px solid #d6d8dd;
    padding: 4px 30px 4px 30px;

    .map-single-item {
      display: flex;
      justify-content: space-between;
      // padding-right: 17px;
      padding: 5px 17px 5px 0;

      .sub-cat-head,
      .new-sub-cat-head {
        font-family: "Source Sans Pro", sans-serif;
        margin-bottom: 0;
        font-size: 14.5px;
        color: #12243e;
        font-weight: 600;
      }

      .new-sub-cat-head {
        font-size: 15.2px;
        color: #9aa0a9;
        font-weight: 500;
      }
    }
  }
}

.board-pin-modal-add-category {
  padding: 30px 15px 20px 12px;
  width: 480px;

  h5 {
    padding-left: 10px;
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .form-control {
    width: 60%;
  }

  .board-sub-cat {
    width: 58%;
  }
}

.board-modal-save-btn-wrapper {
  // background-color: red;
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
  margin-bottom: -50px;

  .save-btn,
  .cancel-btn {
    width: 80px;
    padding: 6px 10px;
    border-radius: 4px;
    background-color: #036cd9;
    color: #fff;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 13px;
    margin-left: 15px;
  }

  .cancel-btn {
    background-color: #fff;
    border: 1px solid #036cd9;
    color: #036cd9;
  }
}

.board-delete-icon {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.comment-margin {
  margin-top: -40px;
  margin-bottom: 60px;
}

.subcategory-section {
  padding-top: 10px;
}

.Collapsible__contentInner.test-aniket,
.Collapsible__contentInner.test-aniket-drag-fix {
  height: auto;
  position: static;
  display: flex;
  width: 100%;

  .board-content-wrapper {
    min-height: 200px;
    width: 100%;
  }
}

.Collapsible__contentInner.test-aniket-drag-fix {
  padding-bottom: 65px;
}

.Collapsible__drag_fix {
  height: 34px !important;

  .Collapsible__contentOuter {
    height: 0 !important; //Fix Lag in rendering while picking dragabble
  }
}

.col-gray {
  color: #84909e;
}

.col-red {
  color: red;
}

.mar-right-18 {
  margin-right: 18px;
}

.no-content-found {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-error {
  font-size: 10px;
  color: red;
}

.no-permission-content {
  height: 10px;
  display: flex;
  flex-direction: column;
}

.custom-download-loader {
  width: 16px;
  height: 16px;
  border: 3px solid $clr_lightblue2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: 24px;
}

.custom-react-tooltip-board {
  max-width: 110px;
  white-space: pre-wrap;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.show-more-span {
  text-decoration: underline;
  color: #0079f5;
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.notification-main-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .notification-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px;
    position: relative;

    .div-1 {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      width: 100%;
      div {
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: flex-start;
        width: 100%;
        .span-1 {
          color: #046cd9;
          font-family: "Source Sans Pro";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          word-break: break-all;
        }
        .span-2 {
          color: #363636;

          font-family: "Source Sans Pro";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
        }
        .span-3 {
          color: #8b98af;

          font-family: "Source Sans Pro";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          justify-content: space-between;
          position: relative;
          span {
            color: #046cd9;
            text-align: center;
            font-family: "Source Sans Pro";
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
            position: absolute;
            right: 0%;
          }
        }
      }
    }
  }
}
.mark-read-all-notification {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  text-decoration: underline;
  cursor: pointer;
  color: #046cd9;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  text-decoration-line: underline;
  margin-bottom: 8px;
}
.view-all-btn-div {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  button {
    padding: 4px 6px;
    font-size: 12px;
  }
}
.notification-list-view {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 19px;
  .notification-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .span-1 {
      color: #046cd9;
      font-family: Montserrat;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .span-2 {
      color: #046cd9;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 112.5% */
      text-decoration-line: underline;
    }
  }
}

.notification-count {
  background: red;
  border-radius: 50%;
  position: absolute;
  right: -17%;
  text-align: -webkit-center;
  top: -6%;
  color: white !important;
  align-items: center;
  display: flex;
  justify-content: center;
}
.text-left .multi-line {
  text-align: left !important;
}

.helpDescription p {
  color: #515151;
}

// .item_details_scroll {
//   height: 65vh !important;
//   overflow-y: scroll;
//   min-height: 50vh !important;
//   max-height: 100vh !important;
// }

.tooltip-link-board {
  color: #99a0aa !important;
  &:hover {
    color: #046cd9 !important;
  }
}

.modal-historical-file {
  &.modal-dialog {
    @media (min-width: 576px) {
      max-width: 640px;
    }
  }
}

.set-pwd-form {
  li {
    span {
      padding-top: 0;
    }
  }
}

.segment-collaps-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 4px 2px 4px 10px;
  background-color: #ebf5ff;
  .span_1 {
    font-size: 14px;
    font-weight: bold;
    color: #606b77;
  }
  span {
    color: #606b77;
  }
}
.downloading-file-progress {
  position: absolute;
  bottom: 0;
  background-color: #dcedff;
  right: 180px;
  width: 200px;
  height: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  padding: 6px;
  gap: 10px;
  align-items: center;
}

.segment-collaps-main-div {
  max-height: 48vh !important;
  // overflow-y: scroll !important;

  .Collapsible__contentOuter {
    max-height: 19.5vh !important;
    overflow-y: scroll !important;
  }
}

.only-segment-collaps-main-div {
  max-height: 48vh !important;
  .Collapsible__contentOuter {
    max-height: 39vh !important;
    overflow-y: scroll !important;
  }
}

.segment_group_name_span {
  font-size: 12px;
  color: #606b77;
  font-weight: 700;
  margin-left: 2px;
  text-transform: capitalize;
}

.existing-user {
  margin-left: 10px;
}

.upload-buttons {
  margin-top: 10px;
  text-align: center;

  button {
    margin-left: 10px;
    font-size: $font-size-12;
  }
}

.file-restriction-message {
  font-size: $font-size-11;
  font-style: italic;
}

.stats-group {
  margin-left: -9px;
  margin-right: -9px;
  @media (min-width: 768px) {
    margin-left: -14px;
    margin-right: -14px;
  }
  @media (min-width: 1200px) {
    margin-left: -9px;
    margin-right: -9px;
  }
  @media (min-width: 1500px) {
    margin-left: -14px;
    margin-right: -14px;
  }

  .stats-block {
    padding: 10px 9px;
    @media (min-width: 768px) {
      padding: 10px 14px;
    }
    @media (min-width: 1200px) {
      padding: 10px 9px;
    }
    @media (min-width: 1500px) {
      padding: 12px 14px;
    }

    &:not(:last-child) {
      border-right: 1px solid $clr_border;
    }

    h5 {
      margin-bottom: 2px;
    }

    label {
      margin-bottom: 0px;
      color: #606b77;
      font-size: 12px;
    }
  }
}

.uploaded_Status_table {
  max-width: 80vw !important;
}

.user-upload-table-block {
  .scroll-table-wrapper {
    max-height: calc(100vh - 320px);
    min-height: 300px;
  }
}

.no-shadow {
  table {
    box-shadow: none !important;
  }
}

.downloading-file-progress {
  position: absolute;
  bottom: 0;
  background-color: #dcedff;
  right: 180px;
  width: 200px;
  height: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  padding: 6px;
  gap: 10px;
  align-items: center;
}
.access-denied {
  .access-denied-wrapper {
    padding-top: 100px;

    i {
      font-size: 20px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      color: black;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.main_content_height_access_denied {
  min-height: calc(100vh - 116px);
  overflow-y: auto;
}

.sso-back-link {
  cursor: pointer;
  text-decoration: none;
}
.sso-back-link:hover {
  text-decoration: underline;
}

.resend-code {
  display: flex;
  gap: 10px;
  align-items: center;
}

.redirecting {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  align-items: center;
  animation: fadeText 1.5s infinite;
}

@keyframes fadeText {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Existing Dots Animation */
.dots-loader {
  display: flex;
  gap: 5px;
  margin-left: 8px;
  margin-top: 14px;
}

.dots-loader span {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: blink 1.5s infinite;
  opacity: 0;
}

.dots-loader span:nth-child(1) {
  animation-delay: 0s;
}
.dots-loader span:nth-child(2) {
  animation-delay: 0.3s;
}
.dots-loader span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.ssosaml-modal-size {
  max-width: 900px !important;
  max-height: 600px !important;
  margin-top: 50px !important;

  .modal-right-btn {
    background: $clr_blue;
    border-color: $clr_blue;
  }

  .ssosaml-modal-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 12px;

    p {
      font-family: "Source Sans Pro";
      font-style: normal;
      font-weight: 600;
      font-size: 12.64px;
      color: #555555;
    }
  }
}

.copy-icon {
  font-size: 14px;
  background-color: transparent;
  vertical-align: bottom;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  color: #9aa0a9;
}

.saml-config-error {
  color: red;
  font-size: 12px;
}
.title-btn {
  padding: 4px 12px;
  font-size: 0.79rem;
  height: 33px;
}

.exp-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  min-width: 36px !important;
}

.exp-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.exp-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1e81e8;
  transition: 0.3s;
  border-radius: 20px;
}

.exp-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.3s;
  border-radius: 50%;
}

input:checked + .exp-slider {
  background-color: #1e81e8;
}

input:disabled + .exp-slider {
  background-color: #dde0e1;
}

input:checked + .exp-slider:before {
  transform: translateX(16px);
}

.saml-configuration-player {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

@media (min-width: 992px) {
  .saml-configuration-player {
    max-width: 1085px;
    height: 500px;
  }
}
