.magic-link {
  .badge {
    display: inline-block;
    border-radius: 20px;
    font-weight: 500;
    font-size: 13px;
    padding: 3px 8px;
    text-align: center;
    min-width: 70px;
  }

  .expired-badge {
    border: 1px solid #d63864;
    color: #d63864;
  }

  .active-badge {
    border: 1px solid #3c78d8;
    color: #3c78d8;
  }
}
